import React from 'react';
import { Logo } from '@getro/rombo';
import { Text, Flex } from 'rebass/styled-components';

const NetworkError = () => (
  <Flex flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
    <Flex flexDirection="column" sx={{ gap: '40px' }}>
      <Flex>
        <Logo
          sx={{
            svg: {
              height: '20px',
            },
          }}
        />
      </Flex>
      <Flex width="356px" flexDirection="column" sx={{ gap: '12px' }}>
        <Text fontSize="22px" fontWeight="600" color="text.dark">
          Oops! We can’t find that page
        </Text>
        <Flex fontSize="15px" lineHeight="1.5" color="text.main" flexDirection="column" sx={{ gap: '12px' }}>
          <Text>
            It looks like something went wrong. We have tracked the issue and will fix it as soon as possible.
          </Text>
          <Text>
            Please try reloading the page or{' '}
            <Text as="a" href="mailto:help@getro.com" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
              contact us
            </Text>
            .
          </Text>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);

export default NetworkError;
