import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass/styled-components';
import { DataTable } from '@getro/rombo';
import { useAdminData } from './hooks/useAdminData';

export const AdminDataTable = ({
  admins,
  onRemoveAdmin,
  onUpdateLinkedinSync,
  currentUserId,
  canRemoveCompanyAdmins,
  onResendInvitation,
  onUpdateRole,
}) => {
  const { columns, rows } = useAdminData({
    admins,
    onRemoveAdmin,
    onUpdateLinkedinSync,
    currentUserId,
    canRemoveCompanyAdmins,
    onResendInvitation,
    onUpdateRole,
  });

  return (
    <Flex data-testid="admin-list">
      <DataTable
        sx={{
          pb: 3,
          minWidth: '980px',
          height: ['600px', '500px', '100%'],
          width: ['100%!important', '100%!important', '1600px!important'],
          maxWidth: '1600px!important',
          flexGrow: 1,

          '[role="columnheader"]': {
            alignSelf: 'flex-end',
            justifyContent: 'flex-start',
          },
        }}
        virtuosoProps={{
          useWindowScroll: true,
        }}
        items={rows}
        columnDefinition={columns}
        isInitialized
        rowProps={({ original }) => ({
          as: 'div',
          'data-testid': `admin-${original.id}`,

          sx: {
            '& > div > div': {
              display: 'flex',
              alignItems: 'center',
            },
            ':hover': {
              backgroundColor: 'neutral.20',
            },
          },
        })}
      />
    </Flex>
  );
};

AdminDataTable.propTypes = {
  admins: PropTypes.array,
  onRemoveAdmin: PropTypes.func.isRequired,
  onUpdateLinkedinSync: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  onUpdateRole: PropTypes.func.isRequired,
  canRemoveCompanyAdmins: PropTypes.bool.isRequired,
};

AdminDataTable.defaultProps = {
  admins: [],
};
