import { createAction } from 'redux-actions';
import { get, post, put } from 'api';
import { destroyCookie, setCookie } from 'nookies';

import { identify as analyticsIdentify, trackEvent, reset as analyticsReset } from 'helpers/analytics';
import { identifyUser, restartChat } from 'helpers/supportChat';
import { trackException } from 'helpers/errorTracker';
import { storeItem, removeItem } from 'helpers/store';

import { getDomainName } from '../helpers/urlHelpers';
import { setNetworks, setNetworksReady } from './networksActions';

export const SET_USER = 'SET_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const SET_USER_READY = 'SET_USER_READY';

export const setUser = (user) => ({
  type: SET_USER,
  payload: {
    ...user,
    isOnlyAlly: Boolean(
      user.allyingCollections?.length && !user.managingOrganizations?.length && !user.managingCollections?.length,
    ),
    isOnlyCompanyAdmin: Boolean(
      user.managingOrganizations?.length && !user.allyingCollections?.length && !user.managingCollections?.length,
    ),
  },
});

export const setUserReady = (ready) => ({
  type: SET_USER_READY,
  payload: ready,
});

export const getAndsetInitialUser = async () => {
  const response = await get('/users/me');
  const user = response.data;

  analyticsIdentify(user);
  identifyUser(user);
  return setUser(user);
};

/* eslint-disable consistent-return */
export const setOnboardingComplete = async (values) => {
  try {
    const { avatarUrl, firstName, lastName, linkedinUrl, password } = values;

    const options = {
      avatarUrl,
      firstName,
      lastName,
      linkedinUrl,
      password,
      passwordConfirmation: password,
      onboardingCompleted: true,
      termsPolicyVersion: '1.1',
      privacyPolicyVersion: '1.1',
    };
    await put('/users/me', options);
    return setUser({
      avatarUrl,
      firstName,
      lastName,
      onboardingCompleted: true,
    });
  } catch (error) {
    trackException(error);
  }

  return false;
};
/* eslint-enable consistent-return */

export const removeUser = createAction(REMOVE_USER);

// TODO fix the return value, and set validated state to false in/after catch
export const login = async (email, password) => {
  try {
    const response = await post('/login', {
      email,
      password,
    });

    if (response && response.status === 200) {
      const { token, user } = response.data;

      storeItem('authToken', token);
      setCookie(null, 'authToken', token, { path: '/', domain: getDomainName(), maxAge: 365 * 24 * 60 * 60 });

      analyticsIdentify(user);
      identifyUser(user);
      trackEvent('user_logged_in');
      return [setUserReady(false), setNetworksReady(false), setUser(user), setNetworks(user)];
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      trackEvent('log_in_invalid');
    }

    throw error;
  }

  return null;
};

export const logOut = () => {
  removeItem('authToken');
  destroyCookie(null, 'authToken', { path: '/' });
  trackEvent('user_logged_out');
  analyticsReset();
  restartChat();
  window.CommandBar?.shutdown();
};
