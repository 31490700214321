import { Button } from '@getro/rombo';
import { ChevronRight, PauseCircle, PlayCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import React, { useMemo, useRef } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import AdminDataTableActionItem from 'components/organisms/adminTable/AdminDataTableActionItem';
import { Image } from 'semantic-ui-react';
import LinkedinLogo from '../../../../assets/linkedin.png';

export const useIntegrationData = ({ integrations = [], onUpdateLinkedinSync, getLink }) => {
  const menuRef = useRef();
  const columns = [
    {
      Header: 'Integration',
      accessor: 'name',
      width: '35%',
      disableSortBy: true,
      show: true,
    },
    {
      Header: 'Status',
      accessor: 'linkedinSyncStatus',
      width: '15%',
      disableSortBy: true,
      show: true,
    },
    {
      Header: 'Last sync',
      accessor: 'lastSyncedAt',
      width: '10%',
      disableSortBy: true,
      show: true,
      sx: {
        alignItems: 'baseline',
      },
    },
    {
      Header: 'contacts',
      accessor: 'contactsSynced',
      width: '10%',
      disableSortBy: true,
      show: true,
    },
    {
      Header: '',
      accessor: 'actions',
      width: '10%',
      disableSortBy: true,
      show: true,
    },
  ];

  const actions = useMemo(
    () => [
      {
        title: 'Pause LinkedIn sync',
        onClick: (admin) => onUpdateLinkedinSync(admin, 'pause'),
        show: (e) => e.linkedinSyncStatus === 'active',
        icon: PauseCircle,
      },
      {
        onClick: (admin) => onUpdateLinkedinSync(admin, 'resume'),
        onlyNetworkAdmin: false,
        title: 'Resume LinkedIn sync',
        icon: PlayCircle,
        show: (e) => e.linkedinSyncStatus === 'paused',
      },
      {
        onClick: (admin) => onUpdateLinkedinSync(admin, 'reconnect'),
        onlyNetworkAdmin: false,
        title: 'Reconnect',
        icon: PlayCircle,
        show: (e) => e.linkedinSyncStatus === 'reconnection_needed',
      },
    ],
    [onUpdateLinkedinSync],
  );

  const statusMap = useMemo(
    () => ({
      active: {
        color: 'icon.success',
        text: 'Active',
      },
      paused: {
        color: 'neutral.300',
        text: 'Paused',
      },
      reconnection_needed: {
        color: 'orange.600',
        text: 'Reconnection needed',
      },
      not_connected: {
        color: 'neutral.300',
        text: 'Not connected',
      },
      not_sharing: {
        color: 'neutral.300',
        text: 'Not sharing',
      },
    }),
    [],
  );

  const rows = useMemo(
    () =>
      integrations.map((integration) => ({
        id: integration.id,
        name: (
          <Flex
            sx={{
              gap: '8px',
              height: '100px',
              alignItems: 'baseline',
              text: {
                fontSize: '28px',
              },
            }}
            alignItems="center"
            justifyContent="flex-start"
            pl="8px"
            pt="8px"
          >
            <Box sx={{ display: 'flex', alignSelf: 'baseline' }}>
              <Flex sx={{ gap: '8px' }}>
                <Box width="24px" height="24px" minWidth="24px">
                  <Image width="24px" height="24px" src={LinkedinLogo} alt="Linkedin logo" />
                </Box>
                <Flex flexDirection="column" sx={{ gap: '4px' }}>
                  <Text
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    fontSize="14px"
                    fontHeight="1.4"
                    color="text.dark"
                    fontWeight="500"
                  >
                    {integration.name}
                  </Text>
                  <Text fontSize="14px" fontHeight="1.4" color="text.subtle" fontWeight="400">
                    {integration.description}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        ),
        linkedinSyncStatus: (
          <Flex sx={{ gap: '8px' }} alignItems="baseline" pt="8px" fontSize="14px" height="100%">
            <Flex
              width="8px"
              height="8px"
              sx={{ borderRadius: '100%', gap: '4px' }}
              backgroundColor={statusMap[integration.linkedinSyncStatus]?.color}
            />
            {integration.linkedinSyncStatus === 'reconnection_needed' && (
              <Flex sx={{ flexDirection: 'column', alignItems: 'baseline' }}>
                <Text color="text.main">{statusMap[integration.linkedinSyncStatus]?.text} </Text>
                <Box
                  as={Link}
                  to={getLink('reconnect')}
                  color="text.main"
                  onClick={() => onUpdateLinkedinSync(null, 'help-reconnect')}
                >
                  Reconnect
                </Box>
              </Flex>
            )}
            {['active', 'paused', 'not_sharing', 'not_connected'].includes(integration.linkedinSyncStatus) && (
              <Text color="text.main">{statusMap[integration.linkedinSyncStatus]?.text}</Text>
            )}
          </Flex>
        ),
        lastSyncedAt: (
          <Flex
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              justifyContent: 'flex-start',
              width: '100%',
              alignItems: 'baseline',
              height: '100%',
            }}
            fontSize="14px"
            pt="8px"
            color="text.main"
          >
            {integration.lastSyncedAt
              ? new Date(integration.lastSyncedAt).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })
              : '-'}
          </Flex>
        ),
        contactsSynced: (
          <Flex
            height="100%"
            alignItems="baseline"
            width="100%"
            justifyContent="flex-start"
            fontSize="14px"
            color="text.main"
            pt="8px"
            lineHeight="24px"
          >
            {integration.contactsSynced ?? '-'}
          </Flex>
        ),
        actions:
          integration.linkedinSyncStatus !== 'not_connected' ? (
            <AdminDataTableActionItem
              ref={menuRef}
              actions={actions
                .filter((item) => item.show?.(integration))
                .map((action) => ({
                  ...action,
                  onClick: () => action.onClick(integration),
                }))}
              tableItem={integration}
            />
          ) : (
            <Box as={Link} to={getLink('sync')}>
              <Button icon={ChevronRight} iconPosition="right" iconGap="8px" size="small">
                Connect
              </Button>
            </Box>
          ),
      })),
    [actions, getLink, integrations, onUpdateLinkedinSync, statusMap],
  );

  return {
    columns: columns.filter((column) => column.show),
    rows,
    actions,
  };
};
