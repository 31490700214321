import { Button } from '@getro/rombo';
import { ArrowLeftRight, File, RefreshCw, User } from 'lucide-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Box, Flex, Image, Text } from 'rebass/styled-components';

import ContactsEmptyStateSvg from '../../assets/empty-state-contacts.svg';
import { activeNetworkSelector, userSelector } from '../../redux/selectors';

export const ContactsEmptyState = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { linkedinSync } = useSelector(userSelector);
  const history = useHistory();

  return (
    <Box p="4" maxWidth="600px" mt="5" mx="auto" sx={{ borderRadius: 'defaul' }}>
      <Box textAlign="center">
        <Image src={ContactsEmptyStateSvg} alt="Getro Network" width={['200px', '400px']} height={['100px', '200px']} />
        <Text fontSize="22px" fontWeight="semibold" color="text.dark" px={[0, '64px']}>
          Aggregate your team’s contacts
        </Text>
      </Box>
      <Flex alignItems="center" flexDirection="column">
        {!linkedinSync?.syncStatus && (
          <>
            <Text mt="32px" fontSize="15px">
              Start syncing your LinkedIn connections:
            </Text>
            <Button
              as={NavLink}
              mt="16px"
              width="280px"
              justifyContent="center"
              icon={RefreshCw}
              to={`/networks/${activeNetwork.slug}/linkedin/sync`}
            >
              Connect LinkedIn
            </Button>
          </>
        )}

        {linkedinSync?.syncStatus && !activeNetwork.shareContacts && (
          <>
            <Text mt="32px" fontSize="15px">
              Your contacts are already synced in Getro. Share them with {activeNetwork.name} to make them visible here.
            </Text>
            <Button
              as={NavLink}
              mt="16px"
              width="280px"
              justifyContent="center"
              icon={ArrowLeftRight}
              to={`/networks/${activeNetwork.slug}/settings/my-networks`}
            >
              Share my contacts
            </Button>
          </>
        )}
        <Text mt="32px" fontSize="15px">
          Or start manually adding contacts:
        </Text>
        <Button
          mt="16px"
          size="small"
          variant="secondary"
          width="280px"
          justifyContent="center"
          onClick={() => {
            history.push(`/networks/${activeNetwork.slug}/import-contacts/csv`);
          }}
        >
          <Flex alignItems="center">
            <Box as={File} mr="2" width="16px" height="16px" aria-hidden="true" />
            Import CSV
          </Flex>
        </Button>
        <Button
          mt="16px"
          size="small"
          variant="secondary"
          width="280px"
          justifyContent="center"
          onClick={() => {
            history.push(`/networks/${activeNetwork.slug}/contact/add`);
          }}
        >
          <Flex alignItems="center">
            <Box as={User} mr="2" width="16px" height="16px" aria-hidden="true" />
            Add a single contact
          </Flex>
        </Button>
      </Flex>
    </Box>
  );
};
