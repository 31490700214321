/* eslint-disable function-paren-newline */
import { createApi } from '@reduxjs/toolkit/query/react';

import { put, request } from 'api';
import { setUser } from 'actions/userActions';
import { updateNetwork } from 'actions/networksActions';
import { networkBaseQueryV2 } from './queries';
import { networkAdminsApiV2 } from './networkAdminV2';

export const userApi2 = createApi({
  reducerPath: 'userApi2',
  baseQuery: networkBaseQueryV2,
  tagTypes: ['user'],
  endpoints: (builder) => ({
    updateUserRole: builder.mutation({
      query: ({ userId, role }) => ({ url: `/users/${userId}?role=${role}`, method: 'PUT' }),
      invalidatesTags: ['user'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(networkAdminsApiV2.util.invalidateTags(['network-admins']));
      },
    }),
    shareContacts: builder.mutation({
      queryFn: async (data, { dispatch, getState }) => {
        const [response] = await Promise.allSettled([
          put(
            `/collections/${data.collectionId}/contacts/share`,
            {
              share: data.share,
            },
            {
              baseURL: process.env.REACT_APP_API_V2_BASE_URL,
            },
          ),
        ]);

        if (response.status === 'fulfilled') {
          const { user, networks } = getState();
          const activeNetwork = networks.networks[networks.active];

          dispatch(
            setUser({
              user,
              allyingCollections: user.allyingCollections.map((collection) =>
                collection.id === data.collectionId ? { ...collection, shareContacts: data.share } : collection,
              ),
              managingCollections: user.managingCollections.map((collection) =>
                collection.id === data.collectionId ? { ...collection, shareContacts: data.share } : collection,
              ),
            }),
          );

          if (activeNetwork) {
            dispatch(
              updateNetwork({
                id: activeNetwork.id,
                ...activeNetwork,
                shareContacts: data.share,
              }),
            );
          }

          return { data: response.value };
        }

        return { error: response.reason };
      },
    }),
    updateLinkedinSyncStatus: builder.mutation({
      queryFn: async (data, { dispatch, getState }) => {
        const [response] = await Promise.allSettled([
          request({
            url: `/user_linkedin_sync/${data.status}`,
            options: {
              method: 'PATCH',
              baseURL: process.env.REACT_APP_API_V2_BASE_URL,
            },
          }),
        ]);

        if (response.status === 'fulfilled') {
          const { user } = getState();

          dispatch(
            setUser({
              user,
              linkedinSync: {
                ...user.linkedinSync,
                syncStatus: data.status === 'pause' ? 'paused' : 'active',
              },
            }),
          );

          return { data: response.value };
        }

        return { error: response.reason };
      },
    }),
  }),
});

export const { useUpdateUserRoleMutation, useShareContactsMutation, useUpdateLinkedinSyncStatusMutation } = userApi2;
