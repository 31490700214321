import { ProfilePicture, Tooltip } from '@getro/rombo';
import copy from 'copy-to-clipboard';
import { Copy, Linkedin, Mail, Maximize2 } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import { NavLink, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

import { mapSeniorityType } from 'services/contacts/seniorityTypes';
import { mapCompanyFundType } from 'services/organizations/organizationFundTypes';
import ContactActionMenu from '../components/contactActionMenu';

export const useTableRows = ({ network = {}, sharedList, companies, queryParams }) => {
  const history = useHistory();

  const calculateGridTemplateRows = (contacts) =>
    contacts
      .reduce((acc, contact) => {
        if (contact.importers.length > 1) {
          acc.push(`${contact.importers.length}fr`);
        } else {
          acc.push('1fr');
        }

        return acc;
      }, [])
      .join(' ');

  const copyImporterLink = (email) => {
    copy(email);
    toast('Importer Email copied', { icon: 'success', id: 'copy-importer-email' });
  };

  const renderContactName = useCallback(
    ({ contact, companyId, isLastContact }) => (
      <Flex
        key={`company-${companyId}-contact-${contact.id}`}
        alignItems="flex-start"
        p="8px"
        width="100%"
        sx={{
          gap: '8px',
          borderBottom: isLastContact ? 'none' : '1px solid',
          borderBottomColor: 'border.subtle',
          '&:hover .table-item-contact-actions': {
            visibility: 'visible',
          },
        }}
      >
        <Flex
          as={NavLink}
          to={`/networks/${network.slug}/list/${sharedList.id}/contacts/detail/${contact.id}${history.location.search}`}
          sx={{ gap: '8px', cursor: 'pointer', alignItems: 'center', flexGrow: 1, textDecoration: 'none' }}
        >
          <Box width="24px" height="24px" flex="0 0 auto">
            <ProfilePicture imageUrl={contact.profilePicUrl} name={contact.fullName} />
          </Box>
          <Text
            fontSize="14px"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {contact.fullName}
          </Text>
        </Flex>
        <Flex
          className="table-item-contact-actions"
          alignItems="center"
          flex="0 0 auto"
          sx={{ gap: '4px', visibility: 'hidden' }}
        >
          <Box
            as={Maximize2}
            height="16px"
            width="16px"
            aria-hidden="true"
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(
                `/networks/${network.slug}/list/${sharedList.id}/contacts/detail/${contact.id}${history.location.search}`,
              )
            }
          />
          {contact.linkedinUrl && (
            <Box
              as={Linkedin}
              height="16px"
              width="16px"
              aria-hidden="true"
              sx={{ cursor: 'pointer' }}
              onClick={() => window.open(contact.linkedinUrl, '_blank')}
            />
          )}
          <ContactActionMenu
            contact={contact}
            network={network}
            queryParams={queryParams}
            companyId={companyId}
            sharedList={sharedList}
          />
        </Flex>
      </Flex>
    ),
    [history, network, queryParams, sharedList],
  );

  const renderContactNames = useCallback(
    ({ contacts, companyId }) =>
      contacts?.length > 0 ? (
        <Box display="grid" height="100%" width="100%" sx={{ gridTemplateRows: calculateGridTemplateRows(contacts) }}>
          {contacts.map(
            (contact, index) =>
              renderContactName({
                contact,
                companyId,
                isLastContact: index === contacts.length - 1,
              }),
            // eslint-disable-next-line function-paren-newline
          )}
        </Box>
      ) : (
        <Text p="8px" fontSize="14px" fontStyle="italic" color="text.subtle">
          No contacts at this company
        </Text>
      ),
    [renderContactName],
  );

  const renderIndustryTags = (tags) =>
    tags?.length > 0 ? (
      <Text as="span" fontSize="14px">
        {tags.join(' · ')}
      </Text>
    ) : null;

  const renderSharedLists = (lists) =>
    lists?.length > 0 ? (
      <Box>
        <Text as="span" fontSize="14px">
          {lists.map((list) => list.name).join(' · ')}
        </Text>
      </Box>
    ) : (
      <Text fontSize="14px" fontStyle="italic" color="text.subtle">
        None
      </Text>
    );

  const renderLocations = (locations) =>
    locations?.length > 0 ? (
      <Box>
        <Text as="span" fontSize="14px">
          {locations.map((location) => location.name).join(' · ')}
        </Text>
      </Box>
    ) : (
      <Text fontSize="14px" fontStyle="italic" color="text.subtle">
        None
      </Text>
    );

  const formatRow = useCallback(
    (company) => ({
      original: company,
      id: company.id,
      name: (
        <Flex width="200px" maxWidth="200px" alignItems="flex-start" justifyContent="flex-start">
          <Flex alignItems="center" sx={{ gap: '8px' }}>
            <Box width="24px" height="24px">
              <ProfilePicture variant="square" imageUrl={company.logoUrl} name={company.name} />
            </Box>
            <Text fontWeight="medium" fontSize="14px">
              {company.name}
            </Text>
          </Flex>
        </Flex>
      ),
      contactsCount: <Text fontSize="14px">{company.contacts.length}</Text>,
      contactName: renderContactNames({ contacts: company.contacts, companyId: company.id }),
      jobTitle: (
        <Box
          display="grid"
          height="100%"
          width="100%"
          sx={{ gridTemplateRows: calculateGridTemplateRows(company.contacts) }}
        >
          {company.contacts.map((contact, index) => (
            <Flex
              key={`company-${company.id}-contact-${contact.id}-job-title`}
              p="8px"
              alignItems="flex-start"
              sx={{
                borderBottom: index === company.contacts.length - 1 ? 'none' : '1px solid',
                borderBottomColor: 'border.subtle',
              }}
            >
              <Text
                fontSize="14px"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {contact.targetPositions?.find((position) => position.active)?.jobTitle}
              </Text>
            </Flex>
          ))}
        </Box>
      ),
      seniority: (
        <Box
          display="grid"
          height="100%"
          width="100%"
          sx={{ gridTemplateRows: calculateGridTemplateRows(company.contacts) }}
        >
          {company.contacts.map((contact, index) => (
            <Flex
              key={`company-${company.id}-contact-${contact.id}-seniority`}
              p="8px"
              alignItems="flex-start"
              sx={{
                borderBottom: index === company.contacts.length - 1 ? 'none' : '1px solid',
                borderBottomColor: 'border.subtle',
              }}
            >
              <Text
                fontSize="14px"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {mapSeniorityType(contact.targetPositions?.find((position) => position.active)?.seniority)}
              </Text>
            </Flex>
          ))}
        </Box>
      ),
      yearsInCompany: (
        <Box
          display="grid"
          height="100%"
          width="100%"
          sx={{ gridTemplateRows: calculateGridTemplateRows(company.contacts) }}
        >
          {company.contacts.map((contact, index) => (
            <Flex
              key={`company-${company.id}-contact-${contact.id}-seniority`}
              p="8px"
              alignItems="flex-start"
              sx={{
                borderBottom: index === company.contacts.length - 1 ? 'none' : '1px solid',
                borderBottomColor: 'border.subtle',
              }}
            >
              <Text
                fontSize="14px"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {Math.round(contact.targetPositions?.reduce((acc, position) => acc + position.duration, 0) || 0)}
              </Text>
            </Flex>
          ))}
        </Box>
      ),
      contactOf:
        company.contacts.length > 0 ? (
          <Box
            display="grid"
            height="100%"
            width="100%"
            sx={{ gridTemplateRows: calculateGridTemplateRows(company.contacts) }}
          >
            {company.contacts.map((contact, index) => (
              <Flex
                key={`company-${company.id}-contact-${contact.id}-contact-of`}
                flexDirection="column"
                width="100%"
                height="100%"
                sx={{
                  gap: '14px',
                  borderBottom: index === company.contacts.length - 1 ? 'none' : '1px solid',
                  borderBottomColor: 'border.subtle',
                  p: contact.importers.length > 0 ? '8px' : '0px',
                }}
              >
                {contact.importers.length > 0 ? (
                  contact.importers.map((importer) => (
                    <Flex
                      key={`company-${company.id}-contact-${contact.id}-importer-${importer.id}`}
                      alignItems="flex-start"
                      width="100%"
                      sx={{
                        gap: '8px',
                        '&:hover .table-item-importer-actions': {
                          visibility: 'visible',
                        },
                      }}
                    >
                      <Flex alignItems="center" flexGrow={1} sx={{ gap: '8px' }}>
                        <Box width="24px" height="24px" flex="0 0 auto">
                          <ProfilePicture imageUrl={importer.avatarUrl} name={importer.name} />
                        </Box>
                        <Text fontSize="14px">{importer.name}</Text>
                      </Flex>
                      {importer.email && (
                        <Flex
                          className="table-item-importer-actions"
                          alignItems="center"
                          flex="0 0 auto"
                          pt="4px"
                          sx={{ gap: '4px', visibility: 'hidden', color: 'neutral.500' }}
                        >
                          <Box
                            as={Copy}
                            height="16px"
                            width="16px"
                            aria-hidden="true"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              copyImporterLink(importer.email);
                            }}
                          />
                        </Flex>
                      )}
                    </Flex>
                  ))
                ) : (
                  <Flex p="8px" alignItems="flex-start" height="100%">
                    <Text fontSize="14px" fontStyle="italic" color="text.subtle">
                      None
                    </Text>
                  </Flex>
                )}
              </Flex>
            ))}
          </Box>
        ) : (
          <Text p="8px" fontSize="14px" fontStyle="italic" color="text.subtle">
            None
          </Text>
        ),
      intros: (
        <Box
          display="grid"
          height="100%"
          width="100%"
          sx={{ gridTemplateRows: calculateGridTemplateRows(company.contacts) }}
        >
          {company.contacts.map((contact, index) => (
            <Flex
              key={`company-${company.id}-contact-${contact.id}-seniority`}
              p="8px"
              alignItems="flex-start"
              sx={{
                borderBottom: index === company.contacts.length - 1 ? 'none' : '1px solid',
                borderBottomColor: 'border.subtle',
              }}
            >
              {contact.intros?.length > 0 ? (
                <Flex flexDirection="column" sx={{ gap: '4px' }}>
                  {contact.intros.map((intro) => (
                    <Flex
                      alignItems="center"
                      sx={{
                        gap: '8px',
                        '&:hover .table-item-intros-actions': {
                          visibility: 'visible',
                        },
                      }}
                    >
                      <Flex alignItems="center" flexGrow={1} sx={{ gap: '8px' }}>
                        <Box width="24px" height="24px" flex="0 0 auto">
                          <ProfilePicture imageUrl={intro.offererAvatarUrl} name={intro.offererName} />
                        </Box>
                        <Text
                          fontSize="14px"
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {`${intro.offererName}${intro.offererCollection ? ` ${intro.offererCollection}` : ''}`}
                        </Text>
                      </Flex>
                      <Box className="table-item-intros-actions" pt="4px" flex="0 0 auto" sx={{ visibility: 'hidden' }}>
                        <Tooltip
                          trigger={
                            <Link href={`mailto:${intro.offererEmail}`} sx={{ color: 'text.subtle' }}>
                              <Box as={Mail} height="16px" width="16px" aria-hidden="true" sx={{ cursor: 'pointer' }} />
                            </Link>
                          }
                          content="Compose and send forwardable"
                        />
                      </Box>
                    </Flex>
                  ))}
                </Flex>
              ) : (
                <Text fontSize="14px" fontStyle="italic" color="text.subtle">
                  No intros offered yet
                </Text>
              )}
            </Flex>
          ))}
        </Box>
      ),
      domain: (
        <Text
          fontSize="14px"
          maxWidth="104px"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {company.domain}
        </Text>
      ),
      location: renderLocations(company.locations),
      industry: renderIndustryTags(company.industryTags),
      approxEmployees: <Text fontSize="14px">{company.approxEmployees}</Text>,
      stage: <Text fontSize="14px">{mapCompanyFundType(company.stage)}</Text>,
      yearFounded: <Text fontSize="14px">{company.founded}</Text>,
      lists: renderSharedLists(company.sharedLists),
    }),
    [renderContactNames],
  );

  return useMemo(() => companies.map(formatRow), [companies, formatRow]);
};
