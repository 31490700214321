import React from 'react';
import { Button, Modal } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

const StopSharingContacts = ({ isSubmitting, onCancel, onStopSharing, networkName }) => (
  <Modal
    title={`Stop sharing your contacts with ${networkName}`}
    isOpen
    onCancel={onCancel}
    actions={
      <Flex justifyContent={['flex-end']} sx={{ gap: '8px' }}>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <Button loading={isSubmitting} onClick={onStopSharing} variant="danger">
          Stop sharing contacts
        </Button>
      </Flex>
    }
    data-testid="pause-linkedin-sync"
  >
    <Text mb="8px" color="text.main">
      Note that taking this action will...
    </Text>
    <Box as="ul" pl="32px">
      <Box as="li">Remove your contacts from the network (unless they’re also contacts of other team members).</Box>
      <Box as="li">
        Permanently delete all associated network data (lists, tags, notes, and matches) of removed contacts.
      </Box>
      <Box as="li">Hire reports will be kept (they can be removed from the Hires screen).</Box>
    </Box>
  </Modal>
);

StopSharingContacts.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onStopSharing: PropTypes.func.isRequired,
  networkName: PropTypes.string.isRequired,
};

StopSharingContacts.defaultProps = {
  isSubmitting: false,
};

export default StopSharingContacts;
