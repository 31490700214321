import { Button, Dropdown, Tooltip } from '@getro/rombo';
import { ArrowLeftRight, FileSpreadsheet, Plus, RefreshCw, User, UserPlus } from 'lucide-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Box, Card, Flex, Text } from 'rebass/styled-components';

import { activeNetworkSelector, userSelector } from '../../../redux/selectors';

export const ImportButton = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { linkedinSync } = useSelector(userSelector);
  const isLimitReached = activeNetwork.connectTier && activeNetwork.contactsCount > activeNetwork.contactsLimit;

  return isLimitReached ? (
    <Button as={NavLink} to={`/networks/${activeNetwork.slug}/upgrade`} variant="accent" icon={Plus} height="32px">
      <Text fontSize="14px">Add contacts</Text>
    </Button>
  ) : (
    <Flex sx={{ gap: '8px' }}>
      {!linkedinSync && (
        <Tooltip
          position="bottom"
          content="Connect your LinkedIn account to sync all your current and future connections."
          trigger={
            <Button
              as={NavLink}
              size="small"
              variant="tertiary"
              icon={RefreshCw}
              to={`/networks/${activeNetwork.slug}/linkedin/sync`}
            >
              Connect LinkedIn
            </Button>
          }
        />
      )}
      {linkedinSync && !activeNetwork.shareContacts && (
        <Tooltip
          content="Share your contacts to make them visible to other admins of this network."
          trigger={
            <Button
              as={NavLink}
              size="small"
              variant="tertiary"
              icon={ArrowLeftRight}
              to={`/networks/${activeNetwork.slug}/settings/my-networks`}
              iconGap="8px"
            >
              Share my contacts
            </Button>
          }
        />
      )}
      <Dropdown
        placement="bottom-end"
        triggerProps={{
          variant: 'accent',
          children: (
            <Flex alignItems="center" fontSize="14px">
              <Box as={UserPlus} mr="2" width="16px" height="16px" aria-hidden="true" />
              Add contacts
            </Flex>
          ),
          sx: {
            height: '32px',
          },
        }}
      >
        <Card px="0" py="8px" width="208px">
          <Flex
            as={NavLink}
            to={`/networks/${activeNetwork.slug}/contact/add`}
            height="32px"
            px="16px"
            sx={{ gap: '8px', alignItems: 'center', textDecoration: 'none', ':hover': { bg: 'neutral.50' } }}
          >
            <Box as={User} width="16px" height="16px" aria-hidden="true" />
            Add single contact
          </Flex>
          <Flex
            as={NavLink}
            to={`/networks/${activeNetwork.slug}/import-contacts/csv`}
            height="32px"
            px="16px"
            sx={{ gap: '8px', alignItems: 'center', textDecoration: 'none', ':hover': { bg: 'neutral.50' } }}
          >
            <Box as={FileSpreadsheet} width="16px" height="16px" aria-hidden="true" />
            Import CSV
          </Flex>
        </Card>
      </Dropdown>
    </Flex>
  );
};
