import React from 'react';
import { FormInput, Radio } from '@getro/rombo';
import { FastField, Field } from 'formik';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';

import { useProjectCompaniesOptions } from '../hooks/useProjectCompaniesOptions';
import { useOrganizationIndustries } from '../hooks/useOrganizationIndustries';
import { useOrganizationSize } from '../hooks/useOrganizationSize';
import { useOrganizationStage } from '../hooks/useOrganizationStage';
import ReduxFormSelect from './ReduxFormSelect';
import { CompanyDetailsField } from './companyDetailsField';

export const CompanyDetailsForm = ({
  values,
  setFieldValue,
  isCompanyFromPortfolio,
  setIsCompanyFromPortfolio,
  edit,
  noJobBoard,
}) => {
  const companies = useProjectCompaniesOptions();

  return (
    <Box>
      {!edit && !noJobBoard && (
        <Flex alignItems="center" sx={{ gap: '16px' }}>
          <Flex sx={{ gap: '16px' }} mb="12px">
            <Radio
              name="none"
              value="none"
              label="From my portfolio"
              onChange={() => {}}
              checked={isCompanyFromPortfolio}
              onClick={() => setIsCompanyFromPortfolio(true)}
            />
            <Radio
              name="none"
              value="none"
              label="Outside my portfolio"
              onChange={() => setIsCompanyFromPortfolio(false)}
              checked={!isCompanyFromPortfolio}
            />
          </Flex>
        </Flex>
      )}
      <Box mt={noJobBoard ? '0px' : '24px'}>
        {isCompanyFromPortfolio && !edit ? (
          <Field
            component={ReduxFormSelect}
            name="company"
            label="Select company"
            placeholder="Start typing..."
            labelExtra="Required"
            value={values.company}
            onChange={(value) => setFieldValue('company', value)}
            onInputChange={companies.search}
            loading={companies.loading}
            options={companies.options}
          />
        ) : (
          <Box>
            <FastField
              component={FormInput}
              name="organizationName"
              label="Company name"
              helpText="Only for your reference, it will never be shared externally."
            />
            <Flex alignItems="center" mt="24px" sx={{ gap: '16px' }}>
              <Box flex="1">
                <CompanyDetailsField
                  name="organizationSize"
                  label="Company size"
                  placeholder="Select"
                  hook={useOrganizationSize}
                  value={values.organizationSize}
                />
              </Box>
              <Box flex="1">
                <CompanyDetailsField
                  name="organizationStage"
                  label="Company stage"
                  placeholder="Select"
                  hook={useOrganizationStage}
                  value={values.organizationStage}
                />
              </Box>
            </Flex>
            <Flex alignItems="center" mt="24px" sx={{ gap: '16px' }}>
              <Box flex="1">
                <CompanyDetailsField
                  name="organizationIndustryTagIds"
                  label="Company industries"
                  placeholder="Select"
                  type="search"
                  hook={useOrganizationIndustries}
                  value={values.organizationIndustryTagIds}
                />
              </Box>
            </Flex>
            <Text mt="16px" fontSize="14px">
              This information helps generating better matches.
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

CompanyDetailsForm.propTypes = {
  values: PropTypes.object.isRequired,
  isCompanyFromPortfolio: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setIsCompanyFromPortfolio: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  noJobBoard: PropTypes.bool,
};

CompanyDetailsForm.defaultProps = {
  isCompanyFromPortfolio: true,
  edit: false,
  noJobBoard: false,
};
