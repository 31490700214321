import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import withMainLayout from '../../hocs/withMainLayout';

import { hasImpactAnalyticsEnabledSelector, networksSelector } from '../../redux/selectors';

// NetworkHome redirects the user to a network route depending on the user role and the network features
const NetworkHome = () => {
  const history = useHistory();
  const { networks, active } = useSelector(networksSelector);
  const network = networks[active];
  const isImpactAnalyticsEnabled = useSelector(hasImpactAnalyticsEnabledSelector);

  const { pathname } = history.location;

  // Don't render anything if URL doesn't match active network
  // NetworkHome will be rendered again when store changes
  if (!pathname.includes(network.slug)) {
    return null;
  }

  let newPath = pathname;
  if (newPath[pathname.length - 1] !== '/') {
    newPath += '/';
  }

  if (network.isManager || network.isConnector) {
    if (isImpactAnalyticsEnabled && network.features.includes('job_board')) {
      newPath += 'home';
    } else if (network.features.includes('talent_network')) {
      newPath += 'members';
    } else if (network.features.includes('job_board')) {
      newPath += 'companies';
    } else {
      newPath += 'contacts';
    }
  } else if (network.isOrganizationManager) {
    if (network.features.includes('talent_network')) {
      newPath += 'members';
    } else {
      newPath += `companies/${network.isOrganizationManager[Object.keys(network.isOrganizationManager)[0]].slug}`;
    }
  }

  return <Redirect to={{ pathname: newPath }} />;
};

export default withMainLayout(NetworkHome);
