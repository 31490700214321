import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageBlock } from '@getro/rombo';
import { PickerOverlay } from 'filestack-react';
import { ProfileDescription } from '../ProfileDescription';
import { userSelector } from '../../../redux/selectors';
import { loadUpdateUserProfile } from '../../../actions/profileActions';
import { key, makeSelectUserFetchError } from '../../../redux/userProfile.selector';
import reducer from '../../../redux/reducers/userProfileReducer';
import saga from '../../../redux/sagas/userProfileSaga';
import { useInjectReducer } from '../../../hooks/useInjectReducer';
import { useInjectSaga } from '../../../hooks/useInjectSaga';
import { makeSelectIsLoading } from '../../../redux/loadingSelector';
import { trackException } from '../../../helpers/errorTracker';

const UserProfile = () => {
  const dispatch = useDispatch();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const fetchError = useSelector(makeSelectUserFetchError);
  const currentUser = useSelector(userSelector);
  const isUpdatingUserProfile = useSelector(makeSelectIsLoading('updateUserProfile'));

  return (
    <>
      {fetchError && (
        <MessageBlock
          title="There was an unexpected error"
          content="Please try again or contact us at help@getro.com."
          type="warning"
        />
      )}

      {!fetchError && (
        <ProfileDescription
          isLoading={false}
          handleException={trackException}
          isUpdating={isUpdatingUserProfile}
          errorMessage={fetchError}
          Picker={PickerOverlay}
          filestackApiKey={process.env.REACT_APP_FILESTACK_API_KEY}
          userProfile={currentUser}
          onUserUpdate={(updatedUser) => {
            dispatch(loadUpdateUserProfile({ updatedUser }));
          }}
        />
      )}
    </>
  );
};

export default UserProfile;
