import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import { trackEvent } from 'helpers/analytics';
import { RefreshContactModal } from 'components/molecules/refreshContactModal';

export const EnrichmentButton = ({ contact, networkId, isList, isContactDetail }) => {
  const [showRefreshModal, setShowRefreshModal] = useState(false);

  if (!contact.linkedinUrl || !contact.linkedinUrl.match(/^https?:\/\/[^\s/$.?#].[^\s]*$/i)) {
    return null;
  }

  return (
    <>
      {showRefreshModal && (
        <RefreshContactModal
          onCloseModal={() => setShowRefreshModal(false)}
          linkedinUrl={contact.linkedinUrl}
          trackRefreshEvent={() => {
            trackEvent('contacts:refresh_profile_click', {
              collectionId: networkId,
              contactId: contact.id,
              linkedinHandle: contact?.linkedinHandle,
              page: isList ? 'list' : 'contacts',
              informationDaysOld: contact.enrichedProfileAgeInDays,
              experienceDaysOld: contact.enrichedExperienceAgeInDays,
              contactEnriched: contact.enrichmentStatus === 'enriched',
              experiencesCount: contact.contactWorkExperiences.length,
            });
          }}
        />
      )}
      <Flex sx={{ gap: '4px', flexDirection: 'column', mt: isContactDetail ? '40px' : '0' }}>
        <Text
          sx={{ gridColumn: '1 / span 2', fontWeight: 400, fontStyle: 'italic' }}
          fontSize="14px"
          color="text.subtle"
        >
          This contact cannot be automatically enriched, likely due to their privacy settings.{' '}
        </Text>
        <Box>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setShowRefreshModal(true);
            }}
            size="small"
            variant="secondary"
          >
            Enrich manually
          </Button>
        </Box>
      </Flex>
    </>
  );
};

EnrichmentButton.propTypes = {
  contact: PropTypes.object.isRequired,
  networkId: PropTypes.string.isRequired,
  isList: PropTypes.bool.isRequired,
  isContactDetail: PropTypes.bool.isRequired,
};
