import React from 'react';
import { Button, FormInput, FormTextarea } from '@getro/rombo';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import { urlWithoutProtocolRegEx } from '../../../helpers/regExs';

const CompaniesCreate = ({ formRef, formDefaultValues, isEdit, isUpdatingList, isSubmitting, onSubmit }) => {
  const getInvalidDomains = (domains) =>
    domains
      .filter((domain) => domain && domain.length)
      .filter((domain) => !RegExp(urlWithoutProtocolRegEx).test(domain));

  const onFormValidation = (values) => {
    const { name, domains = [] } = values;

    if (!name) {
      return { name: 'Required' };
    }

    if (!domains.length || !domains.filter((domain) => domain.trim().length > 0).length) {
      return { domains: 'Required' };
    }

    const invalidDomains = getInvalidDomains(domains.map((domain) => domain.trim()));

    if (invalidDomains.length) {
      return { domains: `Please review the format of the following websites: ${invalidDomains.join(', ')}` };
    }

    return {};
  };

  const onCurrentSubmit = async (values) => {
    const { domains } = values;
    const domainsArray = domains.filter((domain) => domain.trim().length > 0);

    onSubmit({ ...values, domains: domainsArray });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ domains: [], ...formDefaultValues }}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onCurrentSubmit}
      validate={onFormValidation}
    >
      {({ values, setFieldValue }) => (
        <Box
          as={Form}
          width="100%"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Field
            autoFocus
            component={FormInput}
            name="name"
            label="Name"
            labelExtra="Required"
            sx={{
              fontSize: '14px',
            }}
          />
          <Field
            type="text"
            name="domains"
            component={FormTextarea}
            value={values?.domains?.join(',').replace(/,/g, '\n')}
            height="120px"
            placeholder={`website1.com\nwebsite2.com\nwebsite3.com\n...`}
            label="Companies websites"
            helpText="Enter the websites of the companies you want to add, one per line or separated by commas. You can easily copy and paste them from a spreadsheet."
            onChange={(e) => {
              setFieldValue('domains', e.target.value.split(/[\n,]+/));
            }}
          />
          {isEdit ? (
            <Flex justifyContent="flex-start" mt="8px">
              <Button type="submit" loading={isUpdatingList} size="small">
                Update
              </Button>
            </Flex>
          ) : (
            <Flex justifyContent="flex-start" mt="8px">
              <Button type="submit" loading={isSubmitting} size="small">
                Create companies list
              </Button>
            </Flex>
          )}
        </Box>
      )}
    </Formik>
  );
};

CompaniesCreate.propTypes = {
  formRef: PropTypes.object,
  formDefaultValues: PropTypes.object,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isUpdatingList: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

CompaniesCreate.defaultProps = {
  formRef: null,
  formDefaultValues: {},
  isEdit: false,
  isUpdatingList: false,
  isSubmitting: false,
};

export default CompaniesCreate;
