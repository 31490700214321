import React, { useCallback, useMemo } from 'react';
import { ProfilePicture } from '@getro/rombo';
import copy from 'copy-to-clipboard';
import { Check, Copy, Linkedin } from 'lucide-react';
import { Box, Flex, Text } from 'rebass/styled-components';
import toast from 'react-hot-toast';
import { OfferIntro } from '../components/offerIntro';

export const useTableRows = ({ companies, sharedList, userId, showContactOf }) => {
  const calculateGridTemplateRows = useCallback(
    (contacts = []) =>
      showContactOf
        ? contacts
            .reduce((acc, contact) => {
              if (contact.importers.length > 1) {
                acc.push(`${contact.importers.length}fr`);
              } else {
                acc.push('1fr');
              }

              return acc;
            }, [])
            .join(' ')
        : contacts.map(() => '1fr').join(' '),
    [showContactOf],
  );

  const copyImporterLink = (email) => {
    copy(email);
    toast('Importer Email copied', { icon: 'success', id: 'copy-importer-email' });
  };

  const renderContactName = useCallback(
    ({ contact, companyId, isLastContact }) => (
      <Flex
        key={`company-${companyId}-contact-${contact.id}`}
        alignItems="flex-start"
        p="8px"
        width="100%"
        sx={{
          gap: '8px',
          borderBottom: isLastContact ? 'none' : '1px solid',
          borderBottomColor: 'border.subtle',
          '&:hover .table-item-contact-actions': {
            visibility: 'visible',
          },
        }}
      >
        <Box flexGrow="1">
          <Flex sx={{ gap: '8px', alignItems: 'center' }}>
            <Box width="24px" height="24px" flex="0 0 auto">
              <ProfilePicture imageUrl={contact.profilePicUrl} name={contact.fullName} />
            </Box>
            <Text
              fontSize="14px"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {contact.fullName}
            </Text>
          </Flex>
          {contact.intros?.length > 1 && contact.intros?.some((importer) => importer.id !== userId) && (
            <Flex mt="4px" alignItems="center" flex="0 0 auto" sx={{ gap: '4px' }}>
              <Box as={Check} width="10px" height="10px" aria-hidden="true" />
              <Text fontSize="12px" color="text.subtle">
                Someone else offered an intro
              </Text>
            </Flex>
          )}
        </Box>
        <Flex
          className="table-item-contact-actions"
          alignItems="center"
          flex="0 0 auto"
          sx={{ gap: '4px', visibility: 'hidden' }}
        >
          {contact.linkedinUrl && (
            <Box
              as={Linkedin}
              height="16px"
              width="16px"
              aria-hidden="true"
              sx={{ cursor: 'pointer' }}
              onClick={() => window.open(contact.linkedinUrl, '_blank')}
            />
          )}
        </Flex>
      </Flex>
    ),
    [userId],
  );

  const renderContactNames = useCallback(
    ({ contacts, companyId }) =>
      contacts?.length > 0 ? (
        <Box display="grid" height="100%" width="100%" sx={{ gridTemplateRows: calculateGridTemplateRows(contacts) }}>
          {contacts.map(
            (contact, index) =>
              renderContactName({
                contact,
                companyId,
                isLastContact: index === contacts.length - 1,
              }),
            // eslint-disable-next-line function-paren-newline
          )}
        </Box>
      ) : (
        <Text p="8px" fontSize="14px" fontStyle="italic" color="text.subtle">
          No contacts at this company
        </Text>
      ),
    [calculateGridTemplateRows, renderContactName],
  );

  const formatRow = useCallback(
    (company) => ({
      original: company,
      id: company.id,
      name: (
        <Flex width="200px" maxWidth="200px" alignItems="flex-start" justifyContent="flex-start">
          <Flex alignItems="center" sx={{ gap: '8px' }}>
            <Box width="24px" height="24px">
              <ProfilePicture variant="square" imageUrl={company.logoUrl} name={company.name} />
            </Box>
            <Text fontWeight="medium" fontSize="14px">
              {company.name}
            </Text>
          </Flex>
        </Flex>
      ),
      contactName: renderContactNames({ contacts: company.contacts, companyId: company.id }),
      jobTitle: (
        <Box
          display="grid"
          height="100%"
          width="100%"
          sx={{ gridTemplateRows: calculateGridTemplateRows(company.contacts) }}
        >
          {company.contacts.map((contact, index) => (
            <Flex
              key={`company-${company.id}-contact-${contact.id}-job-title`}
              p="8px"
              alignItems="flex-start"
              sx={{
                borderBottom: index === company.contacts.length - 1 ? 'none' : '1px solid',
                borderBottomColor: 'border.subtle',
              }}
            >
              <Text
                fontSize="14px"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {contact.targetPositions?.find((position) => position.active)?.jobTitle}
              </Text>
            </Flex>
          ))}
        </Box>
      ),
      ...(showContactOf
        ? {
            contactOf:
              company.contacts.length > 0 ? (
                <Box
                  display="grid"
                  height="100%"
                  width="100%"
                  sx={{ gridTemplateRows: calculateGridTemplateRows(company.contacts) }}
                >
                  {company.contacts.map((contact, index) => (
                    <Flex
                      key={`company-${company.id}-contact-${contact.id}-contact-of`}
                      flexDirection="column"
                      width="100%"
                      height="100%"
                      sx={{
                        gap: '14px',
                        borderBottom: index === company.contacts.length - 1 ? 'none' : '1px solid',
                        borderBottomColor: 'border.subtle',
                        p: contact.importers.length > 0 ? '8px' : '0px',
                      }}
                    >
                      {contact.importers.length > 0 ? (
                        contact.importers.map((importer) => (
                          <Flex
                            key={`company-${company.id}-contact-${contact.id}-importer-${importer.id}`}
                            alignItems="flex-start"
                            width="100%"
                            sx={{
                              gap: '8px',
                              '&:hover .table-item-importer-actions': {
                                visibility: 'visible',
                              },
                            }}
                          >
                            <Flex alignItems="center" flexGrow={1} sx={{ gap: '8px' }}>
                              <Box width="24px" height="24px" flex="0 0 auto">
                                <ProfilePicture imageUrl={importer.avatarUrl} name={importer.name} />
                              </Box>
                              <Text fontSize="14px">{importer.name}</Text>
                            </Flex>
                            {importer.email && (
                              <Flex
                                className="table-item-importer-actions"
                                alignItems="center"
                                flex="0 0 auto"
                                pt="4px"
                                sx={{ gap: '4px', visibility: 'hidden', color: 'neutral.500' }}
                              >
                                <Box
                                  as={Copy}
                                  height="16px"
                                  width="16px"
                                  aria-hidden="true"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    copyImporterLink(importer.email);
                                  }}
                                />
                              </Flex>
                            )}
                          </Flex>
                        ))
                      ) : (
                        <Flex p="8px" alignItems="flex-start" height="100%">
                          <Text fontSize="14px" fontStyle="italic" color="text.subtle">
                            None
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  ))}
                </Box>
              ) : (
                <Text p="8px" fontSize="14px" fontStyle="italic" color="text.subtle">
                  None
                </Text>
              ),
          }
        : undefined),
      offerIntro: (
        <Box
          display="grid"
          height="100%"
          width="100%"
          sx={{ gridTemplateRows: calculateGridTemplateRows(company.contacts) }}
        >
          {company.contacts.map((contact, index) => (
            <Flex
              key={`company-${company.id}-contact-${contact.id}-offer-intro`}
              p="8px"
              alignItems="center"
              sx={{
                borderBottom: index === company.contacts.length - 1 ? 'none' : '1px solid',
                borderBottomColor: 'border.subtle',
              }}
            >
              <OfferIntro contact={contact} company={company} sharedList={sharedList} />
            </Flex>
          ))}
        </Box>
      ),
    }),
    [calculateGridTemplateRows, renderContactNames, sharedList, showContactOf],
  );

  return useMemo(() => companies.map(formatRow), [companies, formatRow]);
};
