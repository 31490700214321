import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './queries';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery,
  tagTypes: ['user'],
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (data) => ({
        url: '/login',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['user'],
    }),
    signUp: builder.mutation({
      query: (data) => ({
        url: '/users',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['user'],
    }),
    getUser: builder.query({
      query: () => ({ url: '/users/me' }),
      providesTags: ['user'],
    }),
    updateUser: builder.mutation({
      query: (data) => ({ url: `/users/me`, method: 'PUT', data }),
      invalidatesTags: ['user'],
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        await queryFulfilled;
        const { user } = getState();

        dispatch({
          type: 'SET_USER',
          payload: {
            ...user,
            onboardingCompleted: true,
          },
        });
      },
    }),
  }),
});

export const { useSignUpMutation, useGetUserQuery, useLazyGetUserQuery, useUpdateUserMutation, useSignInMutation } =
  userApi;
