import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { activeNetworkSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { useCreateSharedListMutation, useUpdateSharedListMutation } from 'services/contacts';
import { defaultFilters } from 'components/organisms/contactsFilters/defaultFilters';
import { parseFiltersData } from 'services/contacts/filters';
import strings from 'strings';
import { trackEvent } from 'helpers/analytics';
import isEqual from 'lodash.isequal';
import { useListTitle } from './useListTitle';
import { useSharedList } from './useSharedList';

export const useCreateList = () => {
  const { listId } = useParams();
  const { state } = useLocation();
  const isEdit = Boolean(listId);
  const [step, setStep] = useState(state?.step || (isEdit ? 'contacts:create' : 'initial'));
  const formSchema = Yup.object().shape({
    name: Yup.string().required(),
  });
  const activeNetwork = useSelector(activeNetworkSelector);
  const [formDefaultValues, setFormValues] = useState({
    name: '',
    description: strings.sharedLists.defaultDescription,
  });
  const formRef = useRef();
  const [createSharedList, { isLoading: isSubmitting }] = useCreateSharedListMutation();

  const [updateSharedList, { isLoading: isUpdatingList }] = useUpdateSharedListMutation();
  const [match, setMatch] = useState(null);
  const [filters, setFilters] = useState(!isEqual(defaultFilters, state?.filters) ? state?.filters : null);
  const [matchType, setMatchType] = useState(state?.matchType ?? 'job');
  const history = useHistory();
  const title = useListTitle({ isEdit, step });
  const noJobBoard = !activeNetwork.features.includes('job_board');

  const { isLoadingList, sharedListData } = useSharedList({
    listId,
    setFormValues,
    setMatch,
    setMatchType,
    setFilters,
  });

  const getMatchingProject = (currentMatch) => {
    let matchingProject = {};
    if (matchType === 'job' && step !== 'contacts:matchesSettings' && currentMatch) {
      matchingProject = {
        jobId: currentMatch.job.id,
      };
    } else {
      matchingProject = {
        jobId: currentMatch?.job?.id,
        ...currentMatch?.payload,
      };
    }

    if (matchType === 'private' && currentMatch) {
      matchingProject = {
        ...currentMatch.payload,
      };
    }

    return matchingProject;
  };

  const getEventData = (data) => {
    let aiMatches = 'none';
    let company = null;

    if (data?.sharedList?.matchingProject && data?.sharedList?.matchingProject?.job?.id) {
      aiMatches = 'posted_job';
    }

    if (data?.sharedList?.matchingProject && !data?.sharedList?.matchingProject?.job?.id) {
      aiMatches = 'private_match';
      company = data?.sharedList?.matchingProject?.organization?.id ? 'in_portfolio' : 'outside_portfolio';
    }

    return {
      collectionId: activeNetwork.id,
      list_name: data.sharedList.name,
      aiMatches,
      autoUpdate: Boolean(data.sharedList.smartOptions?.includes?.('auto_update')),
      company,
      object_type: data?.sharedList?.kind,
      ...(data?.sharedList?.kind === 'companies'
        ? { number_of_companies: data?.sharedList?.domains?.length }
        : undefined),
    };
  };

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      matchingProject: getMatchingProject(match),
    };

    if (filters) {
      payload.smartListOptions = { filters: parseFiltersData(filters), enabled: true };
    }

    const { error, data } = await createSharedList(payload);

    if (!error) {
      trackEvent('lists:create_list', getEventData(data));
      history.push(`/networks/${activeNetwork.slug}/list/${data.sharedList.id}/contacts`);
    }
  };

  const handleSharedListUpdate = async (values, action = 'disable', smartOptionUpdate = 'smart_options', payload) => {
    const { error, data } = await updateSharedList({
      listId,
      ...values,
    });

    if (!error) {
      trackEvent(`contacts:list:${action}_${smartOptionUpdate}`, payload ?? getEventData(data));
    }

    if (error) {
      toast(strings.genericError, { icon: 'danger', id: 'update-list-error' });
    }
  };

  const updateList = async (
    selectedMatch,
    action = 'enable',
    smartOptionUpdate = 'smart_options',
    eventPayload = null,
  ) => {
    const payload = {};
    if (step === 'contacts:autoUpdate') {
      payload.smartListOptions = {
        filters: parseFiltersData(selectedMatch),
        enabled: true,
      };
    } else {
      payload.matchingProject = getMatchingProject(selectedMatch);
    }

    await handleSharedListUpdate(payload, action, smartOptionUpdate, eventPayload);

    setStep('contacts:create');
  };

  const onMatchSelected = async (selectedMatch) => {
    setStep('contacts:create');

    if (!formDefaultValues.name && selectedMatch?.job?.title && selectedMatch?.organization?.name) {
      setFormValues((current) => {
        const listTitle = `${selectedMatch?.job?.title} at ${selectedMatch?.organization?.name}`;
        return {
          ...current,
          name: listTitle,
        };
      });
    }

    if (step === 'contacts:matchesSettings') {
      if (isEdit) {
        await updateList(selectedMatch, 'update_info_for', 'matches', {
          collectionId: activeNetwork.id,
          company: selectedMatch?.organization?.id ? 'in_portfolio' : 'outside_portfolio',
          listId,
        });
      }
      setMatch(selectedMatch);

      setMatchType(null);
      return;
    }

    if (isEdit && step === 'contacts:matches' && matchType === 'private') {
      setMatch(selectedMatch);

      await updateList(selectedMatch);

      return;
    }

    if (isEdit && (step === 'contacts:autoUpdate' || step === 'contacts:matches')) {
      await updateList(selectedMatch);
    }

    if (step === 'contacts:matches' && selectedMatch) {
      setMatch(selectedMatch);
    }

    if (step === 'contacts:autoUpdate') {
      setFilters(selectedMatch);
    }
  };

  const updateSmartOption = async ({ type, matchType: matchTypeToUpdate, action }) => {
    setFormValues(formRef.current.values);

    if (action === 'disable' && type === 'autoUpdate') {
      if (isEdit) {
        handleSharedListUpdate({
          smartListOptions: {
            filters: parseFiltersData(defaultFilters),
            enabled: false,
          },
        });
      }
      setFilters(null);
    }

    if (action === 'disable' && type === 'matches') {
      if (isEdit) {
        handleSharedListUpdate({
          disableAiMatching: true,
          listId,
        });
      }
      setMatch(null);
      setMatchType('job');
    }

    if (action === 'edit') {
      if (type === 'matches') {
        setStep('contacts:matches');
        setMatchType(matchTypeToUpdate);
      } else {
        setStep(type === 'autoUpdate' ? 'contacts:autoUpdate' : type);
      }
    }

    if (type === 'ai-match-settings') {
      if (action === 'disable') {
        setMatch(null);
        if (isEdit) {
          handleSharedListUpdate({
            disableAiMatching: true,
            listId,
          });
        }
      } else {
        setStep('contacts:matchesSettings');
      }
      setMatchType('job');
    }
  };

  const updateListTitle = async (values) => {
    await handleSharedListUpdate(
      {
        listId,
        ...values,
      },
      'edit',
      'list',
    );
  };

  const goBack = () => {
    switch (step) {
      case 'contacts:matches':
      case 'contacts:autoUpdate':
      case 'contacts:matchesSettings':
        setStep('contacts:create');
        break;
      default:
        setStep('initial');
    }
  };

  const showGoBack = () => {
    if (step === 'initial' || (isEdit && (step === 'contacts:create' || step === 'companies:create'))) {
      return null;
    }

    return goBack;
  };

  return {
    step,
    setStep: (value) => {
      if (value === 'contacts:matches' && noJobBoard) {
        setMatchType('private');
      }

      setStep(value);

      if (formRef?.current?.values) {
        setFormValues(formRef.current.values);
      }
    },
    formSchema,
    activeNetwork,
    formRef,
    createSharedList,
    isSubmitting,
    isLoadingList: isEdit && (!sharedListData || isLoadingList),
    isUpdatingList,
    onSubmit: isEdit ? updateListTitle : onSubmit,
    title,
    onMatchSelected,
    match,
    setMatch,
    goBack: showGoBack(),
    updateSmartOption,
    filters,
    setFilters,
    setMatchType,
    matchType,
    formDefaultValues,
    isEdit,
    contactsCount: sharedListData?.contactsCount ?? 0,
    matchId: sharedListData?.matchingProject?.id,
    hasAutoUpdateFilters: (!isEdit && Boolean(filters)) || sharedListData?.smartOptions?.includes('auto_update'),
    noJobBoard,
  };
};
