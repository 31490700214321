import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Image, Text } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import HandOk from 'assets/icon-hand-ok.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';

export const AllyLinkedinSyncActive = ({ isOnlyAlly, activeNetwork: userNetwork }) => {
  const network = useSelector(activeNetworkSelector);

  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: '32px' }}>
      <Image src={HandOk} alt="Hand Ok" />
      <Flex flexDirection="column" sx={{ gap: '24px' }}>
        <Text color="text.dark" fontWeight="600" fontSize="18px">
          Thanks for your help
        </Text>
        <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
          <Text color="text.main">
            {userNetwork.name} can now see your contacts to find high-impact intro opportunities. They will reach out to
            you with warm intro requests.
          </Text>
          <Text color="text.subtle" fontSize="14px">
            You can manage your contacts sync and sharing settings in your account at any time.
          </Text>
        </Flex>
        <Button
          as={Link}
          to={isOnlyAlly ? '/settings/my-networks' : `/networks/${network.slug}/settings/my-networks`}
          size="medium"
          variant="primary"
          sx={{ width: '100%', justifyContent: 'center' }}
        >
          Go to my dashboard
        </Button>
      </Flex>
    </Flex>
  );
};

AllyLinkedinSyncActive.propTypes = {
  activeNetwork: PropTypes.object.isRequired,
  isOnlyAlly: PropTypes.bool.isRequired,
};
