const SENIORITY_TYPES = {
  internship: 'Internship',
  entry_level: 'Entry Level',
  associate: 'Associate',
  mid_senior: 'Mid-Senior Level',
  senior: 'Senior',
  director: 'Director',
  executive: 'Executive',
  vice_president: 'Vice President',
  cxo: 'CXO',
  founder: 'Founder',
  strategic: 'Strategic',
  other: 'Other',
};

export const mapSeniorityType = (key) => SENIORITY_TYPES[key] || '';
