import { RESET_PAGE, SET_PAGE_TITLE } from 'actions/pageActions';

export const initialState = {
  title: '',
  isTitleHidden: false,
};

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_PAGE:
      return {
        ...initialState,
      };
    case SET_PAGE_TITLE: {
      const { title, options } = action.payload;

      return {
        ...state,
        title,
        options,
      };
    }
    default:
      return state;
  }
}

export default pageReducer;
