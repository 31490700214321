import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { trackEvent } from '../../../helpers/analytics';
import { activeNetworkSelector } from '../../../redux/selectors';
import { useUpdateSharedListMutation } from '../../../services/contacts';

export const useEditCompanyList = (sharedList) => {
  const [update, { isLoading }] = useUpdateSharedListMutation();
  const activeNetwork = useSelector(activeNetworkSelector);
  const initialValues = {
    name: sharedList.name,
    domains: sharedList.domains?.join(',').replace(/,/g, '\n') || '',
  };

  const updateSharedList = async (values) => {
    const { error } = await update({
      listId: sharedList.id,
      ...values,
    });

    if (!error) {
      trackEvent('contacts:list:edit_list', {
        collectionId: activeNetwork.id,
        listId: sharedList.id,
        listName: values.name,
        objectType: 'companies',
      });
      toast('List updated', { icon: 'success', id: 'edit-company-list-success' });
    }
  };

  return { initialValues, updateSharedList, isUpdatingList: isLoading };
};
