import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { toggleMenu } from '../../../features/mainMenu';

export const MenuNav = ({ path, href, label, icon, slug }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch(`/networks/${slug}/list`);

  return (
    <Box
      key={`${path}-${href}`}
      as="li"
      sx={{
        '> a': {
          '&:hover': {
            '> div': {
              bg: 'neutral.20',
              color: 'text.dark',
            },
          },
          '&.active': {
            '> div': {
              bg: 'purple.100',
              color: 'text.dark',
            },
          },
        },
      }}
    >
      <NavLink
        to={path}
        className={({ isActive }) => (isActive || (label === 'Lists' && !!match) ? 'active' : '')}
        style={{ textDecoration: 'none' }}
        onClick={(e) => {
          const jobsPath = `/networks/${slug}/jobs`;
          if (path === jobsPath && location.pathname === jobsPath) {
            e.preventDefault();
          }

          const width = window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

          if (width < 990) {
            dispatch(toggleMenu());
          }
        }}
      >
        <Flex tx="mainMenu" variant="item" alignItems="center" sx={{ gap: '8px', borderRadius: 'default' }}>
          {icon && <Box as={icon} width="16px" height="16px" aria-hidden="true" flex="0 0 auto" />}
          <Text data-testid={`main-menu-${label.toLowerCase()}`} className="main-menu-item">
            {label}
          </Text>
        </Flex>
      </NavLink>
    </Box>
  );
};

MenuNav.propTypes = {
  path: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
};
