import React from 'react';
import { Button, Loader, Message } from '@getro/rombo';
import { Box, Flex, Image, Link, Text } from 'rebass/styled-components';

import ContactsLinkedInSyncImage from '../../assets/contacts-linkedin-sync.png';
import { openChat } from '../../helpers/supportChat';
import usePageHook from '../../hooks/usePageHook';
import { InstallChromeExtension } from './installChromeExtension';
import { EXTENSION_MESSAGE_TYPES, useLinkedInSync } from './useLinkedInSync';
import { LinkedInSyncActive } from './linkedinSyncActive';
import { ConnectSyncLayout } from './connectSyncLayout';
import { AllySyncLayout } from './allySyncLayout';
import { AllyLinkedinSyncActive } from './allyLinkedinSyncActive';

export const ContactsLinkedInSync = () => {
  const {
    isLoadingVoyager,
    isExtensionInstalled,
    activeNetwork,
    action,
    responseType,
    featureList: items,
    onConnect,
    syncItems,
    isAlly,
    isLoadingOrLoadingVoyager,
    syncCompleted,
    showSyncImage,
    allySyncItems,
    synContacts,
    user,
  } = useLinkedInSync();
  usePageHook(`LinkedIn ${action}`);
  const Layout = isAlly ? AllySyncLayout : ConnectSyncLayout;

  return (
    <Layout isOnlyAlly={user.isOnlyAlly} allySyncItems={allySyncItems} activeNetwork={activeNetwork} action={action}>
      <Box width="100%" maxWidth="440px" mx="auto" my="0" pb="40px">
        <Box>
          {showSyncImage && <Image src={ContactsLinkedInSyncImage} alt="LinkedIn Sync" />}
          {!isLoadingOrLoadingVoyager && user?.linkedinSync?.syncStatus !== 'active' && (
            <Flex flexDirection="column" sx={{ gap: '16px' }}>
              <Text lineHeight="28px" fontSize="20px" color="text.dark" fontWeight="500">
                Sync your LinkedIn connections with our Chrome extension
              </Text>

              <Text fontSize="16px" lineHeight="20px" color="text.main">
                Set up once – unleash unlimited warm introductions.
              </Text>
            </Flex>
          )}
        </Box>
        {isLoadingOrLoadingVoyager && (
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: '24px' }}>
            <Loader mt="40px" />
            {isLoadingVoyager && (
              <Text fontSize="14px" lineHeight="1.4" color="text.main" textAlign="center" width="300px">
                We are fetching your LinkedIn connections, this can take a few minutes...
              </Text>
            )}
          </Flex>
        )}
        {syncCompleted && !isAlly && <LinkedInSyncActive syncItems={syncItems} network={activeNetwork} />}
        {syncCompleted && isAlly && (
          <AllyLinkedinSyncActive isOnlyAlly={user.isOnlyAlly} activeNetwork={activeNetwork} />
        )}
        {synContacts && (
          <>
            <Flex as="ul" pl="0" py="40px" flexDirection="column" sx={{ gap: '16px', listStyle: 'none' }}>
              {items.map(({ icon, title, description }) => (
                <Flex key={title} as="li" alignItems="flex-start" sx={{ gap: '16px' }}>
                  <Flex
                    flex="0 0 auto"
                    alignItems="center"
                    color="purple.300"
                    sx={{ position: 'relative', top: '3px' }}
                  >
                    <Box as={icon} width="20px" height="20px" aria-hidden="true" />
                  </Flex>
                  <Box flexGrow="1">
                    <Text fontSize="14px" fontWeight="medium" color="text.dark">
                      {title}
                    </Text>
                    <Text fontSize="14px">{description}</Text>
                  </Box>
                </Flex>
              ))}
            </Flex>
            {!isExtensionInstalled && <InstallChromeExtension />}
            {isExtensionInstalled && (
              <Button
                width="100%"
                justifyContent="center"
                loading={isLoadingVoyager}
                disabled={!isExtensionInstalled}
                onClick={onConnect}
              >
                Sync my contacts
              </Button>
            )}
            {responseType === EXTENSION_MESSAGE_TYPES.IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR && (
              <Box data-testid="linkedin-cookies-warning" pt="16px" sx={{ '& > div': { alignItems: 'center' } }}>
                <Message
                  type="warning"
                  content={
                    <Box fontSize="14px" lineHeight="1.4">
                      <Text as="p" fontWeight="500" mb="8px">
                        Make sure you’re logged in to LinkedIn
                      </Text>
                      <Text as="p" fontWeight="400">
                        Please go to{' '}
                        <Link href="https://linkedin.com" target="__blank" rel="noopener noreferrer" color="text.main">
                          www.linkedin.com
                        </Link>
                        {`, log in, and then try again. `}
                        <Text
                          display="inline-block"
                          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                          onClick={() => openChat(null, { name: 'Support' })}
                        >
                          Need help?
                        </Text>
                      </Text>
                    </Box>
                  }
                />
              </Box>
            )}
            {(responseType === EXTENSION_MESSAGE_TYPES.IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR ||
              !isExtensionInstalled) && (
              <Text mt="24px" fontSize="14px" fontWeight="400" lineHeight="1.4" color="text.subtle" textAlign="center">
                Come back to this tab after installing it.{' '}
                <Text
                  display="inline-block"
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => openChat(null, { name: 'Support' })}
                >
                  Need help?
                </Text>
              </Text>
            )}
          </>
        )}
      </Box>
    </Layout>
  );
};
