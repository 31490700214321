import React, { useState } from 'react';
import { SettingsLayoutHeader } from 'components/atoms/settingsLayoutHeader';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Button, DataTable, Message } from '@getro/rombo';
import toast from 'react-hot-toast';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { activeNetworkSelector, userSelector } from 'redux/selectors';
import { useUpdateLinkedinSyncStatusMutation } from 'services/userV2';
import { trackEvent } from 'helpers/analytics';
import PauseLinkedinSync from './PauseLinkedinSync';
import { useIntegrationData } from './hooks/useIntegrationData';

export const Integrations = () => {
  const user = useSelector(userSelector);
  const [actionPayload, setActionPayload] = useState(null);
  const activeNetwork = useSelector(activeNetworkSelector);
  const getLink = (status = 'sync') => {
    if (user.isOnlyAlly) {
      return `/linkedin/${status}`;
    }
    return `/networks/${activeNetwork.slug}/linkedin/${status}`;
  };
  const history = useHistory();
  const [updateLinkedinSyncStatus, { isLoading: isUpdatingLinkedinSync }] = useUpdateLinkedinSyncStatusMutation();
  const integrations = [
    {
      id: '1',
      name: 'LinkedIn sync',
      linkedinSyncStatus: !user.linkedinSync ? 'not_connected' : user.linkedinSync?.syncStatus,
      lastSyncedAt: user.linkedinSync?.lastSyncedAt,
      description: 'Automatically add your current and future LinkedIn connections to Getro.',
      contactsSynced: user.linkedinSync?.contactsSynced,
      isSyncing: true,
    },
  ];

  const { columns, rows } = useIntegrationData({
    integrations,
    getLink,
    onUpdateLinkedinSync: (data, action) => {
      setActionPayload({ data, action });

      if (action === 'resume') {
        updateLinkedinSyncStatus({ status: 'resume' });
        toast('LinkedIn sync resumed', {
          icon: 'success',
          id: 'linkedin-sync-resumed',
        });
      }

      if (action === 'reconnect') {
        history.push(getLink('reconnect'));
      }
    },
  });

  return (
    <Box maxWidth={['100%', '100%', '1200px']} width="100%" pr={['0px', '0px', '80px']}>
      <SettingsLayoutHeader
        title="Integrations"
        description="Keep your contacts synced in Getro, then choose networks to share them with on the ‘My networks’ screen."
      />
      <Flex sx={{ flexDirection: 'column', gap: '40px', pt: '40px', width: '100%' }}>
        <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
          <DataTable
            sx={{
              minWidth: '600px',
              maxWidth: '1120px!important',
              height: ['600px', '500px', '100%'],
              width: ['100%!important', '100%!important', '100%!important'],
              flexGrow: 1,
              scrollbarWidth: 'thin',
              scrollbarColor: '#dee3ed transparent',
              '[role="cell"]:last-child': {
                alignItems: 'baseline',
              },
              '[role="row"]': {
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
              '[role="columnheader"]': {
                alignSelf: 'flex-end',
              },
            }}
            virtuosoProps={{
              useWindowScroll: true,
            }}
            items={rows}
            columnDefinition={columns}
            isInitialized
            rowProps={({ original }) => ({
              as: 'div',
              'data-testid': `admin-${original.id}`,

              sx: {
                '& > div > div': {
                  display: 'flex',
                  alignItems: 'center',
                },
                ':hover': {
                  backgroundColor: 'neutral.20',
                },
              },
            })}
          />
          {user.linkedinSync?.contactsImportInProgress && (
            <Message content="We’re syncing your LinkedIn connections, this may take up to 3 hours." />
          )}

          {user.linkedinSync?.syncStatus === 'reconnection_needed' && (
            <Message
              type="warning"
              content={
                <Text>
                  Your LinkedIn sync has stopped working.{' '}
                  <Box as={NavLink} to={getLink('reconnect')} sx={{ textDecoration: 'underline' }}>
                    Reconnect now
                  </Box>{' '}
                  to keep your new connections synced in Getro.
                </Text>
              }
            />
          )}
        </Flex>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="space-between"
          sx={{ gap: '16px', p: '16px', bg: 'neutral.20', borderRadius: '8px' }}
        >
          <Flex flexDirection="column" fontSize="14px" fontHeight="1.4">
            <Text color="text.dark" fontWeight="500">
              What integrations would be most useful for you?
            </Text>
            <Text color="text.subtle" fontSize="14px" fontHeight="1.4">
              Fill out a quick survey to help us prioritize our roadmap.{' '}
            </Text>
          </Flex>
          <Button
            onClick={() => {
              if (activeNetwork) {
                window.open(
                  `https://getro-forms.typeform.com/to/NAc6nhPQ?email=${user.email}&name=${user.firstName} ${user.lastName}&collection_name=${activeNetwork.name}&collection_id=${activeNetwork.id}`,
                  '_blank',
                );
              } else {
                window.open(
                  `https://getro-forms.typeform.com/to/NAc6nhPQ?email=${user.email}&name=${user.firstName} ${user.lastName}`,
                  '_blank',
                );
              }

              trackEvent('user_account:more_integrations_feedback');
            }}
            size="small"
            variant="secondary"
          >
            Go to survey
          </Button>
        </Flex>
      </Flex>
      {actionPayload?.action === 'pause' && (
        <PauseLinkedinSync
          isSubmitting={isUpdatingLinkedinSync}
          onCancel={() => setActionPayload(null)}
          onPauseLinkedinSync={async () => {
            await updateLinkedinSyncStatus({ status: 'pause' });
            toast('LinkedIn sync paused', {
              icon: 'success',
              id: 'linkedin-sync-paused',
            });
            setActionPayload(null);
          }}
        />
      )}
    </Box>
  );
};
