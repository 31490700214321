import React from 'react';
import { Button, FormInput, FormTextarea } from '@getro/rombo';
import { Formik, Form, Field } from 'formik';
import { ChevronLeft } from 'lucide-react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { validateDomains } from '../../../helpers/yupValidators';
import { useEditCompanyList } from '../hooks/useEditCompanyList';

export const AddCompanies = ({ activeNetwork, sharedList }) => {
  const { initialValues, updateSharedList, isUpdatingList } = useEditCompanyList(sharedList);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    domains: Yup.string().test(validateDomains).required('Companies websites are required'),
  });

  const onSubmit = async (values) => {
    const { name, domains } = values;

    updateSharedList({
      name,
      domains: domains.split(/[\n,]+/).filter((domain) => domain.trim().length > 0),
    });
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        as={Link}
        to={`/networks/${activeNetwork.slug}/list/${sharedList.id}/contacts/settings`}
        sx={{ textDecoration: 'none', fontWeight: 'body', position: 'absolute', top: '-20px', left: '0' }}
      >
        <Flex sx={{ alignItems: 'center', color: 'text.subtle', gap: '8px' }}>
          <Box as={ChevronLeft} width="16px" height="16px" aria-hidden="true" sx={{ strokeWidth: 1.5 }} />
          <Text fontSize="12px" fontWeight="medium" lineHeight="20px">
            Back
          </Text>
        </Flex>
      </Box>
      <Text fontSize="20px" fontWeight="semibold" color="text.dark" lineHeight="28px">
        Add companies
      </Text>
      <Box mt="32px">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values }) => (
            <Form>
              <Field name="name" component={FormInput} type="hidden" />
              <Field
                height="120px"
                name="domains"
                component={FormTextarea}
                label="Companies websites"
                helpText="Enter the websites of the companies you want to add, one per line or separated by commas. You can easily copy and paste them from a spreadsheet."
                placeholder={`website1.com\nwebsite2.com\nwebsite3.com\n...`}
                value={values?.domains?.replace(/,/g, '\n')}
              />
              <Button type="submit" size="small" mt="24px" loading={isUpdatingList}>
                Add companies
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

AddCompanies.propTypes = {
  activeNetwork: PropTypes.object,
  sharedList: PropTypes.object,
};

AddCompanies.defaultProps = {
  activeNetwork: {},
  sharedList: {},
};
