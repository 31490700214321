import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import { useContactsExtended } from 'pages/contactsExtended/hooks/useContactsExtended';
import { getCompanyColumns } from 'pages/listDetail/getCompanyColumns';
import { useCompanyTableFunctions } from 'pages/listDetail/hooks/useCompanyTableFunctions';
import { useTableRows } from 'pages/listDetail/hooks/useCompanyTableRows';
import { qsParse } from 'helpers/queryString';
import { useRemoveContactFromSharedListMutation, useUpdateSharedListMutation } from 'services/contacts';
import { parseFiltersData, reverseParsedFilters } from '../../../services/contacts/filters';
import { useActionItems } from './useActionItems';

export const DEFAULT_SORT = {
  sortBy: 'contactsCount',
  sortDirection: 'desc',
};

export const useCompanyList = (sharedList) => {
  const location = useLocation();
  const [updateSharedList] = useUpdateSharedListMutation();
  const [removeContactFromSharedList, { isLoading: isLoadingRemoveContact }] = useRemoveContactFromSharedListMutation();
  const {
    data,
    isLoading,
    isFetching,
    activeNetwork,
    listId,
    queryParams,
    setQueryParams,
    filtersCount,
    onSetFilters,
  } = useContactsExtended({ allList: false, defaultSort: DEFAULT_SORT, listKind: 'companies' });
  const companiesColumns = getCompanyColumns(new Set([]));
  const currentPage = Math.ceil(data?.ids?.length / 20);
  const hasMore = currentPage + 1 <= Math.ceil(data?.meta?.queryTotal / 20);

  useEffect(() => {
    if (sharedList?.smartSharedList) {
      onSetFilters(reverseParsedFilters(sharedList.smartSharedList.filters));
    }
  }, [sharedList]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSaveFilters = async (filters) => {
    const payload = {
      smartListOptions: {
        filters: parseFiltersData(filters),
        enabled: true,
      },
    };

    await updateSharedList({
      listId: sharedList.id,
      ...payload,
    });

    toast('Filters saved', { icon: 'success', id: 'filters-saved' });
  };

  const onSort = (args) => {
    const [{ desc, column }] = args;

    setQueryParams({ ...queryParams, sortBy: column.accessor, sortDirection: desc ? 'desc' : 'asc', page: 1 });
  };

  const removeSharedListDomains = (domainToRemove) => {
    updateSharedList({
      listId: sharedList.id,
      domains: sharedList.domains.filter((domain) => domain !== domainToRemove),
    });
    toast('Company removed from this list', { icon: 'success', id: 'company-removed-from-list' });
  };

  const actionMenuItems = useActionItems({
    removeSharedListDomains,
  });
  const { handleSort, onColumnAction } = useCompanyTableFunctions({ companies: data?.entities, onSort });
  const tableRows = useTableRows({
    network: activeNetwork,
    sharedList,
    companies: data?.ids?.map((id) => data?.entities[id]) || [],
    queryParams,
  });

  const initialSortBy = useMemo(() => {
    const params = qsParse(location);
    let id = DEFAULT_SORT.sortBy;
    let desc = DEFAULT_SORT.sortDirection === 'desc';
    if (params.sortBy) {
      id = params.sortBy;
    }
    if (params.sortDirection) {
      desc = params.sortDirection === 'desc';
    }
    return { id, desc };
  }, [location]);

  const onLoadMore = () => {
    setQueryParams({
      ...queryParams,
      page: currentPage + 1,
    });
  };

  return {
    activeNetwork,
    listId,
    companiesColumns,
    tableRows,
    totalItems: data?.meta?.queryTotal,
    isFetching,
    isLoading,
    initialSortBy,
    handleSort,
    actionMenuItems,
    onColumnAction,
    queryParams,
    filtersCount,
    onSetFilters,
    onSaveFilters,
    removeContactFromSharedList,
    isLoadingRemoveContact,
    hasMore,
    onLoadMore,
  };
};
