import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import isEqual from 'lodash.isequal';
import { defaultFilters } from '../../../components/organisms/contactsFilters/defaultFilters';
import { trackEvent, trackPage } from '../../../helpers/analytics';
import useContactsFilterCount from '../../../hooks/useContactsFilterCount';
import { activeNetworkSelector } from '../../../redux/selectors';
import { useFetchContacts } from './useFetchContacts';

export const useContactsExtended = ({ allList = true, defaultSort = {}, listKind }) => {
  const {
    listId,
    queryParams,
    setQueryParams,
    selectedRows,
    setSelectedRows,
    data,
    isLoading,
    isFetching,
    isLoadingAI,
  } = useFetchContacts({ allList, defaultSort, listKind });
  const filtersCount = useContactsFilterCount(queryParams.filters);
  const activeNetwork = useSelector(activeNetworkSelector);
  const { meta } = data;
  const listIdToUse = allList ? null : listId;

  const showAllContactsEmptyState =
    !isLoading &&
    !data?.ids?.length &&
    meta?.contactsTotal === 0 &&
    !listId &&
    isEqual(defaultFilters, queryParams.filters);

  useEffect(() => {
    if (!listId) {
      trackEvent('contacts:view', {
        collectionId: activeNetwork.id,
        ...(listIdToUse ? { listId: listIdToUse } : undefined),
      });
    }

    trackPage();
  }, [listIdToUse]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLoadMore = () => {
    const currentPage = Math.ceil(data.ids.length / 20);

    if (currentPage + 1 <= Math.ceil(data.meta.queryTotal / 20)) {
      setQueryParams({
        ...queryParams,
        page: currentPage + 1,
      });
    }
  };

  const onChangeSelectedContact = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((position) => position !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const onSetFilters = (newFilters) => {
    setQueryParams({ ...queryParams, filters: newFilters, page: 1 });
    setSelectedRows([]);
    window.scrollTo(0, 0);
  };

  return {
    listId,
    queryParams,
    setQueryParams,
    selectedRows,
    setSelectedRows,
    data,
    isLoading,
    isFetching,
    filtersCount,
    meta,
    showAllContactsEmptyState,
    onLoadMore,
    onChangeSelectedContact,
    onSetFilters,
    activeNetwork,
    isLoadingAI,
  };
};
