import React from 'react';
import { NavLink } from 'react-router-dom';
import { Flex, Text, Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import NetworkSchema from 'schemas/network';

export const LinkedInSyncActive = ({ syncItems, network }) => (
  <Flex flexDirection="column" sx={{ gap: '48px' }}>
    <Text sx={{ fontSize: '18px', fontWeight: '600', lineHeight: '1.4', textAlign: 'center', color: 'text.dark' }}>
      Your LinkedIn sync is active
    </Text>
    <Flex
      sx={{
        flexDirection: 'column',
        '@keyframes spin': { from: { transform: 'rotate(0deg)' }, to: { transform: 'rotate(360deg)' } },
      }}
    >
      {syncItems.map((item, index) => (
        <Flex key={item.title} sx={{ gap: '16px' }}>
          <Flex
            sx={{
              position: 'relative',
              minWidth: '32px',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              color={index === 2 ? 'neutral.500' : 'purple.400'}
              bg={index === 2 ? 'neutral.50' : 'purple.100'}
              width="32px"
              height="32px"
              minWidth="32px"
              flex="0 0 auto"
              sx={{ borderRadius: 'circle' }}
            >
              <Box
                as={item.icon}
                width="16px"
                height="16px"
                aria-hidden="true"
                sx={{
                  animation: index === 2 ? 'spin 2s linear infinite' : 'non',
                }}
              />
            </Flex>
            {index !== 2 && <Box sx={{ width: '1px', height: 'calc(100% + 32px)', left: '50%', bg: 'purple.100' }} />}
          </Flex>

          <Flex sx={{ flexDirection: 'column', gap: '4px', pb: index !== 2 ? '24px' : '0' }}>
            <Text
              sx={{
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '1.4',
                color: 'text.dark',
              }}
            >
              {item.title}
            </Text>
            <Text sx={{ fontSize: '14px', lineHeight: '1.4', color: 'text.main' }}>{item.description}</Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
    <Button as={NavLink} width="100%" to={`/networks/${network?.slug}/contacts`} sx={{ justifyContent: 'center' }}>
      OK
    </Button>
  </Flex>
);

LinkedInSyncActive.propTypes = {
  syncItems: PropTypes.array.isRequired,
  network: NetworkSchema.isRequired,
};
