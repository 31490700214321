import { useSelector } from 'react-redux';
import { userSelector } from '../../../redux/selectors';
import { useOfferIntroMutation } from '../../../services/intros';
import { trackEvent } from '../../../helpers/analytics';

export const useOfferIntro = ({ contact, company, sharedList }) => {
  const [offerIntro, { isLoading }] = useOfferIntroMutation();
  const user = useSelector(userSelector);
  const isIntroOffered = !!contact?.intros?.find((intro) => intro.offererId === user.id);
  const template = ({ userName, contactName, networkName }) => `Hi ${contactName},

I hope this message finds you well! I’m reaching out because ${networkName} is eager to connect with you, and I believe there’s strong potential for mutual value. They’re focused on {insert mission or purpose}, and based on our conversations, I think their expertise aligns with your goals or interests.

Would you be open to connecting with them? Let me know, and I’d be happy to facilitate an introduction.

Best regards,
${userName}
`;
  const mailto = `mailto:${contact?.email || ''}?subject=${encodeURIComponent(
    `Potential Introduction with ${contact.fullName}`,
  )}&body=${encodeURIComponent(
    template({ userName: user.firstName, contactName: contact.fullName, networkName: sharedList.collection?.name }),
  )}`;

  const onOfferIntro = () => {
    offerIntro({
      contactId: contact.id,
      organizationId: company.id,
      sharedListId: sharedList.id,
      networkId: sharedList.collection?.id,
      companyId: company.id,
    })
      .unwrap()
      .then(() => {
        trackEvent('intros:intro_offered', {
          collectionId: sharedList.collection?.id,
          listId: sharedList.id,
          objectType: 'companies',
          page: 'shared_list_view',
        });
      });
  };

  const onClickMakeIntro = () => {
    trackEvent('intros:make_intro_click', {
      collectionId: sharedList.collection?.id,
      listId: sharedList.id,
      objectType: 'companies',
      page: 'shared_company_list',
    });
  };

  return { mailto, isIntroOffered, offerIntro: onOfferIntro, isLoading, onClickMakeIntro };
};
