import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronLeft, SidebarClose } from 'lucide-react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Logo } from '@getro/rombo';
import { toggleMenu } from '../../../features/settingsMenu';
import usePermissions from '../../../hooks/usePermissions';
import { activeNetworkSelector, isGetroUserSelector, userSelector } from '../../../redux/selectors';
import { createMenuLinks } from './menuLinks';
import { UserAndHelpMenu } from '../userAndHelpMenu';

export const SettingsLayoutMenu = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { url } = useRouteMatch();
  const match = useRouteMatch(`/networks/${activeNetwork?.slug}/list`);
  const {
    notifications: { canManageNotifications },
  } = usePermissions();
  const isGetroUser = useSelector(isGetroUserSelector);
  const isMenuOpen = useSelector((state) => state.settingsMenu.isOpen);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  return (
    <>
      <Box
        display={[isMenuOpen ? 'block' : 'none', isMenuOpen ? 'block' : 'none', 'none']}
        bg="neutral.20"
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 98,
          opacity: 0.8,
        }}
        onClick={() => dispatch(toggleMenu())}
      />
      <Flex
        className="main-menu"
        height="100vh"
        width="240px"
        flexDirection="column"
        bg="neutral.0"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: [99, 99, 1],
          borderRight: 1,
          borderRightColor: 'neutral.100',
          boxShadow: [isMenuOpen ? 'medium' : 'none', 'none', 'none'],
          transition: ['transform 0.15s ease-in-out', 'transform 0.15s ease-in-out', 'width 0.15s ease-in-out'],
          transform: [
            isMenuOpen ? 'translate3d(0,0,0)' : 'translate3d(-240px,0,0)',
            isMenuOpen ? 'translate3d(0,0,0)' : 'translate3d(-240px,0,0)',
            'none',
          ],
        }}
      >
        {!user.isOnlyAlly && (
          <Flex
            py="8px"
            alignItems="center"
            flex="0 0 auto"
            sx={{
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Flex
              to={`/networks/${activeNetwork?.slug}`}
              as={NavLink}
              sx={{ width: '40px', height: '40px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => dispatch(toggleMenu())}
            >
              <Box as={ChevronLeft} size="16px" aria-hidden="true" />
            </Flex>
            <Text fontWeight="medium" color="text.dark" flexGrow="1">
              Settings
            </Text>
            <Box
              display={['flex', 'flex', 'none']}
              className="main-menu-close-icon"
              width="32px"
              height="32px"
              mr="8px"
              mt="2px"
              sx={{ cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => dispatch(toggleMenu())}
            >
              <Box
                as={SidebarClose}
                className="main-menu-item"
                width="16px"
                height="16px"
                aria-hidden="true"
                flex="0 0 auto"
              />
            </Box>
          </Flex>
        )}

        {user.isOnlyAlly && (
          <Flex pl="40px" pb="24px" pt="16px">
            <Logo color="primary" width="96px" />
          </Flex>
        )}

        <Flex flexDirection="column" justifyContent="space-between" flexGrow="1" overflowY="auto" pb="40px">
          <Box as="nav">
            {createMenuLinks(url, {
              isNetworkAdmin: !!activeNetwork?.isManager,
              showPlansAndBillings: isGetroUser,
              showAccountNotifications: canManageNotifications,
              isNotOnlyCompanyAdmin: !user.isOnlyCompanyAdmin,
              hasJobBoard: activeNetwork?.features?.includes('job_board'),
            }).map(({ title, items }) => (
              <Box
                key={`settings-menu-${title}`}
                as="ul"
                sx={{ listStyle: 'none', py: 0, pl: '32px', pr: '8px', mx: 0, mb: 0, mt: '24px' }}
              >
                <Text
                  key={title}
                  pl="8px"
                  pb="4px"
                  fontSize="12px"
                  fontWeight="medium"
                  color="text.subtle"
                  sx={{ textTransform: 'uppercase', letterSpacing: '1px' }}
                >
                  {title}
                </Text>
                {items.map(({ title: itemTitle, path }) => (
                  <Box
                    key={`settings-menu-item-${itemTitle}`}
                    as="li"
                    sx={{
                      '> a': {
                        '&.active': {
                          '> div': {
                            bg: 'purple.100',
                            color: 'text.dark',
                          },
                        },
                      },
                    }}
                  >
                    <NavLink
                      to={path}
                      className={({ isActive }) => (isActive || (itemTitle === 'Lists' && !!match) ? 'active' : '')}
                      style={{ textDecoration: 'none' }}
                      onClick={() => {
                        const width =
                          window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

                        if (width < 990) {
                          dispatch(toggleMenu());
                        }
                      }}
                    >
                      <Flex
                        tx="mainMenu"
                        variant="item"
                        alignItems="center"
                        sx={{ gap: '8px', borderRadius: 'default' }}
                      >
                        <Text data-testid={`main-menu-${itemTitle.toLowerCase()}`} className="main-menu-item">
                          {itemTitle}
                        </Text>
                      </Flex>
                    </NavLink>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Flex>
        <UserAndHelpMenu />
      </Flex>
    </>
  );
};
