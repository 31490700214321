import React, { Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookOpen, ChevronDown, ExternalLink, HelpCircle, LifeBuoy, MessageCircle } from 'lucide-react';
import { Dropdown } from '@getro/rombo';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import { Card } from '../../atoms/Card';
import { toggleMenu } from '../../../features/mainMenu';
import { openChat } from '../../../helpers/supportChat';
import UserMenu from '../UserMenu';

export const UserAndHelpMenu = () => {
  const dropdownRef = useRef(null);
  const isMenuOpen = useSelector((state) => state.mainMenu.isOpen);
  const dispatch = useDispatch();

  const support = [
    {
      label: 'Learning center',
      href: 'https://help.getro.com',
      icon: BookOpen,
    },
    {
      label: 'Contact support',
      onClick: () => openChat(null, { name: 'Support' }),
      icon: LifeBuoy,
    },
    {
      label: 'Share feedback',
      onClick: () => openChat(null, { name: 'Feedback' }),
      icon: MessageCircle,
    },
    {
      label: 'Terms of use',
      href: 'https://www.getro.com/terms',
      icon: ExternalLink,
    },
    {
      label: 'Privacy policy',
      href: 'https://www.getro.com/privacy',
      icon: ExternalLink,
    },
  ];

  return (
    <>
      <Dropdown
        ref={dropdownRef}
        className="help-and-support"
        placement="bottom-end"
        triggerProps={{
          variant: 'styleless',
          width: '100%',
          height: '36px',
          flex: '0 0 auto',
          p: '8px',
          children: (
            <Flex alignItems="center" pl="4px" width="100%" sx={{ gap: '12px' }}>
              <Box as={HelpCircle} height="16px" width="16px" aria-hidden="true" flex="0 0 auto" />
              <Text
                textAlign="left"
                fontSize="14px"
                fontWeight="medium"
                flexGrow="1"
                className="main-menu-item"
                sx={{
                  whiteSpace: 'nowrap',
                  opacity: isMenuOpen ? 1 : 0,
                }}
              >
                Help & support
              </Text>
              <Flex alignItems="center" color="text.subtle">
                <Box as={ChevronDown} width="16px" height="16px" aria-hidden="true" />
              </Flex>
            </Flex>
          ),
        }}
      >
        <Card width="200px">
          {support.map(({ label, icon, onClick, ...props }, index) => (
            <Fragment key={label}>
              {index === 3 && <Box my="8px" bg="border.subtle" width="100%" height="1px" />}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                color="text.main"
                sx={{
                  color: 'text.main',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  fontSize: '14px',
                  lineHeight: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  py: '4px',
                  ':hover': {
                    color: 'text.dark',
                  },
                }}
                onClick={() => {
                  if (onClick) onClick();
                  dropdownRef.current.closeDropdown();

                  const width =
                    window.screen.availWidth < window.innerWidth ? window.screen.availWidth : window.innerWidth;

                  if (width < 990) {
                    dispatch(toggleMenu());
                  }
                }}
                {...props}
              >
                <Box as={icon} size="16px" />
                <Box as="span"> {label}</Box>
              </Link>
            </Fragment>
          ))}
        </Card>
      </Dropdown>

      <UserMenu isMenuOpen={isMenuOpen} />
    </>
  );
};
