import React from 'react';
import { Box } from 'rebass/styled-components';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import SideModal from 'components/atoms/sideModal';
import { AiMatches } from './aiMatches';
import { AutoUpdate } from './autoUpdate';
import { useCreateList } from './hooks/useCreateList';
import { UpdateListSkeleton } from './components/updateListSkeleton';
import Initial from './steps/initial';
import CompaniesCreate from './steps/companiesCreate';
import ContactsCreate from './steps/contactsCreate';
import { AiMatchSettings } from './aiMatchSettings';

export const CreateList = ({ goBack: close }) => {
  const {
    step,
    setStep,
    formRef,
    formSchema,
    isEdit,
    isSubmitting,
    goBack,
    title,
    onMatchSelected,
    onSubmit,
    activeNetwork,
    filters,
    setFilters,
    setMatchType,
    matchType,
    formDefaultValues,
    contactsCount,
    isUpdatingList,
    matchId,
    isLoadingList,
    match,
    updateSmartOption,
    hasAutoUpdateFilters,
    noJobBoard,
  } = useCreateList();

  const history = useHistory();
  return (
    <SideModal
      isLoading={isLoadingList}
      loadingComponent={UpdateListSkeleton}
      headerText={title}
      goBack={goBack}
      width="680px"
      sx={{
        ...(step === 'contacts:matches' ? { px: 0, py: 0 } : {}),
        '& > div:first-child': {
          ...(step === 'contacts:matches' ? { top: '24px' } : {}),
        },
      }}
      headerRebassProps={{
        ...(step === 'contacts:matches' ? { px: ['16px', '40px'], pt: '24px' } : {}),
      }}
      onClose={() => {
        if (close) {
          close();
          return;
        }

        if (isEdit) {
          history.goBack();
          return;
        }
        history.push('.');
      }}
    >
      <Box>
        {step === 'initial' && <Initial setStep={setStep} />}
        {step === 'contacts:create' && (
          <ContactsCreate
            formRef={formRef}
            formDefaultValues={formDefaultValues}
            formSchema={formSchema}
            isEdit={isEdit}
            isUpdatingList={isUpdatingList}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            match={match}
            updateSmartOption={updateSmartOption}
            filters={filters}
            contactsCount={contactsCount}
            hasAutoUpdateFilters={hasAutoUpdateFilters}
            setStep={setStep}
          />
        )}
        {step === 'contacts:matches' && (
          <AiMatches
            noJobBoard={noJobBoard}
            onMatchSelected={onMatchSelected}
            setMatchType={setMatchType}
            matchType={matchType}
            isUpdatingList={isUpdatingList}
            isEdit={isEdit}
            matchId={matchId}
            setStep={setStep}
            match={match}
          />
        )}
        {step === 'contacts:matchesSettings' && (
          <AiMatchSettings match={match} onMatchSelected={onMatchSelected} matchId={matchId} setStep={setStep} />
        )}
        {step === 'contacts:autoUpdate' && (
          <AutoUpdate
            contactsCount={contactsCount}
            onMatchSelected={onMatchSelected}
            isTalentNetworkEnabled={!activeNetwork?.tnHidden}
            setFilters={setFilters}
            filters={filters}
            isUpdatingList={isUpdatingList}
            isEdit={isEdit}
          />
        )}
        {step === 'companies:create' && (
          <CompaniesCreate
            formDefaultValues={formDefaultValues}
            isEdit={isEdit}
            isUpdatingList={isUpdatingList}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
          />
        )}
      </Box>
    </SideModal>
  );
};

CreateList.propTypes = {
  goBack: PropTypes.func,
};
CreateList.defaultProps = {
  goBack: null,
};
