import React, { useState } from 'react';
import { Button, Tooltip } from '@getro/rombo';
import merge from 'lodash.merge';
import { Check } from 'lucide-react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass/styled-components';

import ShareContacts from 'pages/Settings/myNetworks/shareContacts';
import StopSharingContacts from 'pages/Settings/myNetworks/stopSharingContacts';
import { useShareContactsMutation } from 'services/userV2';
import { trackEvent } from 'helpers/analytics';

export const ShareYourContacts = ({ sharedList, user }) => {
  const [showShareContactsModal, setShowShareContactsModal] = useState(false);
  const [showStopSharingContactsModal, setShowStopSharingContactsModal] = useState(false);
  const [shareContacts] = useShareContactsMutation();

  const networks = merge([...user?.allyingCollections], [...user?.managingCollections]);
  const currentNetwork = networks.find((network) => network.id === sharedList.collection?.id);

  const onShareContacts = (action) => {
    shareContacts({
      collectionId: sharedList.collection?.id,
      share: action === 'share',
    })
      .unwrap()
      .then(() => {
        trackEvent(`contacts:contacts_sharing:${action === 'share' ? 'enabled' : 'disabled'}`, {
          collectionId: sharedList.collection?.id,
          page: 'shared_list_view',
        });
      });
  };

  return (
    <>
      <Box display={['block', 'block', 'none']}>
        <Text mt="16px" fontWeight="medium" color="text.dark">
          Share your contacts
        </Text>
        <Text pb="16px" fontSize="14px" color="text.subtle">
          {`Let ${sharedList.collection?.name} see all your contacts and request intros directly to you.`}
        </Text>
      </Box>
      {currentNetwork?.shareContacts ? (
        <Tooltip
          content={
            <Box>
              <Text
                fontSize="14px"
                color="neutral.0"
              >{`${sharedList.collection?.name} can see all your contacts and request intros directly to you.`}</Text>
              <Text fontSize="14px" mt="8px" color="neutral.0">
                Go to your account to manage who can see your contacts.
              </Text>
            </Box>
          }
          trigger={
            <Button
              size="small"
              variant="tertiary"
              width={['100%', '100%', 'auto']}
              icon={Check}
              onClick={() => setShowStopSharingContactsModal(true)}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Sharing contacts
            </Button>
          }
        />
      ) : (
        <Tooltip
          content={`Let ${sharedList.collection?.name} see all your contacts and request intros directly to you.`}
          trigger={
            <Button
              size="small"
              variant="accent"
              width={['100%', '100%', 'auto']}
              onClick={() => setShowShareContactsModal(true)}
              sx={{
                justifyContent: 'center',
              }}
            >
              Share your contacts
            </Button>
          }
        />
      )}

      {showShareContactsModal && (
        <ShareContacts
          isSubmitting={false}
          onCancel={() => setShowShareContactsModal(false)}
          onShareContacts={() => {
            onShareContacts('share');
            setShowShareContactsModal(false);
          }}
          networkName={sharedList.collection?.name}
        />
      )}
      {showStopSharingContactsModal && (
        <StopSharingContacts
          isSubmitting={false}
          onCancel={() => setShowStopSharingContactsModal(false)}
          onStopSharing={() => {
            onShareContacts('stop');
            setShowStopSharingContactsModal(false);
          }}
          networkName={sharedList.collection?.name}
        />
      )}
    </>
  );
};

ShareYourContacts.propTypes = {
  sharedList: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
