import { createApi } from '@reduxjs/toolkit/query/react';

import deserialize from 'helpers/getroPropertyMapper';
import { request } from 'api';
import { networkBaseQueryV2 } from './queries';
import { networkAdminsApiV2 } from './networkAdminV2';

export const networkAdminsApi = createApi({
  reducerPath: 'networkAdminsApi',
  baseQuery: networkBaseQueryV2,
  tagTypes: ['network-admins'],
  endpoints: (builder) => ({
    getNetworkAdmins: builder.query({
      query: () => ({ url: '/managers', params: { roles: ['network_admin'] } }),
      providesTags: ['network-admins'],
      transformResponse: (response) => {
        const sortedData = deserialize(response)
          .map((admin) => {
            if (!admin.name) {
              return { ...admin, name: admin.email };
            }

            return admin;
          })
          .sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });
        return sortedData;
      },
    }),
    updateLinkedinSync: builder.mutation({
      query: ({ adminId, state }) => ({ url: `/user_linkedin_syncs/${adminId}/${state}`, method: 'patch' }),
      onQueryStarted: ({ adminId, state }, { dispatch, queryFulfilled, getState }) => {
        const { networks } = getState();
        const patchResult = dispatch(
          networkAdminsApi.util.updateQueryData('getNetworkAdmins', { networkId: networks.active }, (draft) => {
            const admin = draft.find((item) => item.id === adminId);
            Object.assign(admin, {
              linkedinSyncStatus: state === 'pause' ? 'paused' : 'active',
            });
          }),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    resendNetworkAdminInvite: builder.mutation({
      async queryFn({ adminId, networkId: collectionId }) {
        try {
          const response = request({
            url: `/users/${adminId}/invitation_email/network_manager`,
            options: {
              method: 'post',
              params: {
                collectionId,
              },
            },
          });

          return response;
        } catch (error) {
          error.message = 'Failed to resend invitation';
          return {
            error,
          };
        }
      },
    }),
    removeNetworkAdmin: builder.mutation({
      query: ({ adminId, role }) => ({
        url: role === 'ally' ? `/allies/${adminId}` : `/managers/${adminId}`,
        method: 'delete',
      }),

      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(networkAdminsApiV2.util.invalidateTags(['network-admins']));
      },

      invalidatesTags: ['network-admins'],
    }),
    addNetworkAdmin: builder.mutation({
      async queryFn({ admin, networkId: collectionId }) {
        try {
          const response = await request({
            url: '/users/invite',
            options: {
              method: 'post',
              params: {
                collectionId,
              },
              data: {
                ...admin,
                roles: ['network_admin'],
              },
            },
          });

          return {
            data: response.data,
          };
        } catch (error) {
          error.message = 'Failed to invite admin';
          return {
            error,
          };
        }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(networkAdminsApiV2.util.invalidateTags(['network-admins']));
      },
      invalidatesTags: ['network-admins'],
    }),
  }),
});

export const {
  useGetNetworkAdminsQuery,
  useLazyGetNetworkAdminsQuery,
  useResendNetworkAdminInviteMutation,
  useRemoveNetworkAdminMutation,
  useAddNetworkAdminMutation,
  useUpdateLinkedinSyncMutation,
} = networkAdminsApi;
