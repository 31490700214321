import React from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Plus } from 'lucide-react';

export const FilterTitle = ({
  showClear,
  showToggle,
  clearFilter,
  title,
  onToggle,
  height,
  icon,
  titleMessage,
  ...props
}) => (
  <Flex sx={{ flexDirection: 'column', gap: '8px', width: '100%' }}>
    <Flex
      data-testid="filter-title-trigger"
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        height,
        width: '100%',
        cursor: 'pointer',
        ':hover': {
          p: {
            color: 'text.dark',
          },
          svg: {
            color: 'neutral.700',
          },
        },
      }}
      onClick={(e) => {
        e.stopPropagation();

        onToggle();
      }}
      {...props}
    >
      <Text as="p" fontSize="14px" fontWeight="500" lineHeight="1.4" color="text.main">
        {title}
      </Text>
      {showClear && (
        <Text
          onClick={(e) => {
            e.stopPropagation();
            clearFilter();
          }}
          color="text.subtle"
          fontSize="12px"
          lineHeight="16px"
        >
          Clear
        </Text>
      )}
      {showToggle && (
        <Box data-testid="multi-select-plus-icon" sx={{ color: 'neutral.300' }} size="16px" as={icon ?? Plus} />
      )}
    </Flex>
    {titleMessage && <Box sx={{ fontSize: '12px', color: 'text.subtle' }}>{titleMessage}</Box>}
  </Flex>
);

FilterTitle.propTypes = {
  showClear: PropTypes.bool.isRequired,
  showToggle: PropTypes.bool.isRequired,
  clearFilter: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  height: PropTypes.string,
  titleMessage: PropTypes.string,
  icon: PropTypes.elementType,
};

FilterTitle.defaultProps = {
  height: '20px',
  titleMessage: '',
  icon: null,
};
