export const mergeArraysBy = (arr1 = [], arr2 = [], key = 'id') => {
  const merged = [...arr1];

  arr2.forEach((item2) => {
    const index = merged.findIndex((item1) => item1[key] === item2[key]);

    if (index === -1) {
      merged.push(item2);
    } else {
      merged[index] = { ...merged[index], ...item2 };
    }
  });

  return merged;
};
