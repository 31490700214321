import React from 'react';
import { ProfilePicture, Tooltip } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { HelpCircle } from 'lucide-react';
import PropTypes from 'prop-types';

import { useGetLeaderboardQuery } from '../../../../services/intros';
import BackgroundImage from '../../../../assets/background-leaderboard-empty-state.png';

export const Leaderboard = ({ sharedList }) => {
  const { data = [] } = useGetLeaderboardQuery({ networkId: sharedList.collection?.id });

  return (
    <Box width="100%">
      <Flex
        p="8px"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderBottom: '1px solid', borderColor: 'border.subtle' }}
      >
        <Text fontWeight="medium" color="text.dark">
          Top connectors
        </Text>
        <Tooltip
          trigger={
            <Box color="text.subtle">
              <Box as={HelpCircle} width="16px" height="16px" aria-hidden="true" />
            </Box>
          }
          content="Top people and networks offering more intros to Mr.Fusion."
        />
      </Flex>
      <Box as="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
        {data?.length > 0 ? (
          <>
            {data.map((item) => (
              <Flex
                as="li"
                key={item.name}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                sx={{ borderBottom: '1px solid', borderColor: 'border.subtle' }}
              >
                <Flex alignItems="center" p="8px" sx={{ gap: '8px' }}>
                  <Box width="24px" height="24px">
                    <ProfilePicture
                      variant={item.type === 'User' ? 'default' : 'square'}
                      name={item.name}
                      imageUrl={item.image}
                    />
                  </Box>
                  <Text fontSize="14px">{item.name}</Text>
                </Flex>
                <Text p="8px" fontSize="14px">
                  {item.count}
                </Text>
              </Flex>
            ))}
          </>
        ) : (
          <Flex
            height="200px"
            sx={{
              backgroundImage: `url(${BackgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: 'neutral.0',
              position: 'relative',
              top: '-1px',
            }}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              px="32px"
              flex="1"
              bg="rgba(255, 255, 255, 0.5)"
            >
              <Text fontSize="14px" color="text.subtle" textAlign="center">
                No activity yet
              </Text>
              <Text mt="8px" fontSize="14px" color="text.subtle" textAlign="center">
                {`Offer intros to support ${sharedList.collection?.name}, and take the lead.`}
              </Text>
            </Flex>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

Leaderboard.propTypes = {
  sharedList: PropTypes.object.isRequired,
};
