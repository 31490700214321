import { Logo } from '@getro/rombo';
import { Check } from 'lucide-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex } from 'rebass/styled-components';

export const AllySyncLayout = ({ children, allySyncItems }) => {
  const getColor = (state) => {
    if (state.active) {
      return 'neutral.0';
    }

    if (state.completed) {
      return 'purple.300';
    }

    return 'text.subtle';
  };

  return (
    <Box title="Sync your LinkedIn">
      <Box width="120px" mx="auto" sx={{ position: 'absolute', left: 24, top: 24 }}>
        <Logo />
      </Box>
      <Box pb="96px" pt="16px">
        <Box
          sx={{
            width: '360px',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              width: '300px',
              height: '2px',
              left: '30px',
              top: '17px',
              bg: 'border.subtle',
              position: 'absolute',
            }}
          />

          {allySyncItems.map((item) => (
            <Flex
              key={item.id}
              sx={{
                position: 'relative',
                minWidth: '32px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                color={getColor(item)}
                bg={item.active ? 'purple.300' : 'purple.100'}
                width="32px"
                height="32px"
                minWidth="32px"
                flex="0 0 auto"
                sx={{ borderRadius: 'circle' }}
              >
                {item.completed && <Box as={Check} width="16px" height="16px" aria-hidden="true" />}
                {!item.completed && (
                  <Box as="p" fontWeight="500" fontSize="14px">
                    {item.id}
                  </Box>
                )}
              </Flex>
              <Box as="p" fontWeight="400" fontSize="14px">
                {item.title}
              </Box>
            </Flex>
          ))}
        </Box>
      </Box>
      {children}
    </Box>
  );
};

AllySyncLayout.propTypes = {
  children: PropTypes.node.isRequired,
  allySyncItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};
