import React from 'react';
import PropTypes from 'prop-types';

import RangeFilter from '../../atoms/RangeFIlter';

const YearsOfExperienceFilter = ({ label, name, min, max, yearsOfExperience, onYearsOfExperienceChange }) => {
  const value = yearsOfExperience
    ? { min: yearsOfExperience[`${name}_gteq`], max: Math.min(yearsOfExperience[`${name}_lteq`], 40) }
    : null;

  return (
    <RangeFilter
      title={label}
      min={min}
      max={max}
      value={value}
      onApply={(rangeValues) => {
        onYearsOfExperienceChange(
          rangeValues ? { [`${name}_lteq`]: rangeValues.max, [`${name}_gteq`]: rangeValues.min } : null,
        );
      }}
    />
  );
};

YearsOfExperienceFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  yearsOfExperience: PropTypes.object,
  onYearsOfExperienceChange: PropTypes.func.isRequired,
};

YearsOfExperienceFilter.defaultProps = {
  label: '',
  name: '',
  yearsOfExperience: null,
};

export default YearsOfExperienceFilter;
