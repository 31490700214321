import React, { useState } from 'react';
import { Button, Modal } from '@getro/rombo';
import { HelpCircle } from 'lucide-react';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import ImportContactsIcon from '../../../../assets/icon-getro-network.svg';

export const HowItWorks = ({ pointOfContact, networkName, sx }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Flex alignItems="center" sx={{ gap: '8px', cursor: 'pointer', ...sx }} onClick={() => setIsModalOpen(true)}>
        <Box as={HelpCircle} size="16px" aria-hidden="true" sx={{ strokeWidth: 1.5 }} />
        <Text fontSize="14px" fontWeight="medium" lineHeight="16px" display={['none', 'none', 'inline-block']}>
          How does this work?
        </Text>
      </Flex>
      {isModalOpen && (
        <Modal
          actions={[<Button onClick={() => setIsModalOpen(false)}>Got it</Button>]}
          isOpen
          onCancel={() => setIsModalOpen(false)}
        >
          <Box>
            <Flex sx={{ gap: '16px' }}>
              <Image
                src={ImportContactsIcon}
                alt="Getro Network"
                display={['none', 'block']}
                width="96px"
                height="96px"
                flex="0 0 auto"
              />
              <Box flexGrow="1">
                <Text fontSize="18px" fontWeight="semibold" color="text.dark">
                  {`Help ${networkName} connecting with these companies`}
                </Text>
                <Text mt="18px">
                  {`${pointOfContact.fullName} has created this list of companies they want to get a warm intro to. Easily find who you know at them and offer help.`}
                </Text>
              </Box>
            </Flex>
            <Text mt="40px" fontWeight="semibold" color="text.dark">
              How it works
            </Text>
            <Box as="ul" mt="24px" p="0">
              <Flex as="li" sx={{ listStyle: 'none', gap: '8px' }}>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="purple.100"
                  width="24px"
                  height="24px"
                  sx={{ borderRadius: 'circle' }}
                >
                  <Text fontSize="14px" fontWeight="medium" color="purple.400">
                    1
                  </Text>
                </Flex>
                <Box>
                  <Text fontSize="14px" fontWeight="500" color="text.dark">
                    Connect your LinkedIn
                  </Text>
                  <Text as="p" fontSize="14px">
                    We’ll fetch your connections while keeping them only visible to you.
                  </Text>
                </Box>
              </Flex>
              <Flex as="li" mt="16px" sx={{ listStyle: 'none', gap: '8px' }}>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="purple.100"
                  width="24px"
                  height="24px"
                  sx={{ borderRadius: 'circle' }}
                >
                  <Text fontSize="14px" fontWeight="medium" color="purple.400">
                    2
                  </Text>
                </Flex>
                <Box>
                  <Text fontSize="14px" fontWeight="500" color="text.dark">
                    Discover meaningful opportunities
                  </Text>
                  <Text as="p" fontSize="14px">
                    Identify key connections.
                  </Text>
                </Box>
              </Flex>
              <Flex as="li" mt="16px" sx={{ listStyle: 'none', gap: '8px' }}>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="purple.100"
                  width="24px"
                  height="24px"
                  sx={{ borderRadius: 'circle' }}
                >
                  <Text fontSize="14px" fontWeight="medium" color="purple.400">
                    3
                  </Text>
                </Flex>
                <Box>
                  <Text fontSize="14px" fontWeight="500" color="text.dark">
                    Make intros
                  </Text>
                  <Text as="p" fontSize="14px">
                    You take action on the intro opportunities you want.
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

HowItWorks.propTypes = {
  pointOfContact: PropTypes.object.isRequired,
  networkName: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

HowItWorks.defaultProps = {
  sx: {},
};
