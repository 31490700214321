export const createMenuLinks = (
  url,
  { isNetworkAdmin, showPlansAndBillings, showAccountNotifications, isNotOnlyCompanyAdmin, hasJobBoard = true },
) => [
  ...(isNetworkAdmin
    ? [
        {
          title: 'Network',
          items: [
            { title: 'General', path: `${url}/general` },
            { title: 'Team members', path: `${url}/team-members` },
            ...(showPlansAndBillings ? [{ title: 'Plans and billing', path: `${url}/plans-and-billing` }] : []),
            { title: 'Data export', path: `${url}/data-export` },
          ],
        },
      ]
    : []),
  ...(isNetworkAdmin && hasJobBoard
    ? [
        {
          title: 'Job board',
          items: [
            { title: 'Appearance', path: `${url}/job-board-appearance` },
            { title: 'Filters', path: `${url}/job-board-filters` },
            { title: 'Privacy policy', path: `${url}/job-board-privacy-policy` },
            { title: 'Job rules', path: `${url}/job-board-rules` },
            { title: 'Talent network', path: `${url}/job-board-talent-network` },
          ],
        },
      ]
    : []),
  {
    title: 'My account',
    items: [
      ...(isNotOnlyCompanyAdmin
        ? [
            { title: 'My networks', path: `${url}/my-networks` },
            { title: 'Integrations', path: `${url}/integrations` },
          ]
        : []),
      { title: 'Profile', path: `${url}/account-my-profile` },
      ...(showAccountNotifications ? [{ title: 'Notifications', path: `${url}/account-notifications` }] : []),
      { title: 'Account', path: `${url}/account-my-account` },
    ],
  },
];
