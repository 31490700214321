import { MoreHorizontal } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { forwardRef, Fragment } from 'react';
import { Flex, Box } from 'rebass/styled-components';
import { Dropdown } from 'components/molecules/dropdown';
import { Card } from 'components/atoms/Card';

const AdminDataTableActionItem = ({ actions }, ref) => {
  if (!actions.length) return null;

  return (
    <Flex justifyContent="flex-end" width="100%">
      <Dropdown
        ref={ref}
        placement="left-start"
        triggerProps={{
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        }}
        trigger={
          <Flex
            data-testid="dropdown-trigger"
            sx={{
              cursor: 'pointer',
              alignItems: 'baseline',
              justifyContent: 'flex-end',
              p: '0',
              pt: '4px',
              maxWidth: '24px',
              width: '16px',
              height: '16px',
            }}
          >
            <Box as={MoreHorizontal} height="16px" width="16px" strokeWidth="1.5" aria-hidden="true" />
          </Flex>
        }
      >
        <Card width="240px" height="100%" p="8px 16px" bg="neutral.0">
          {actions.map((item, index) => (
            <Fragment key={item.id}>
              {actions.length > 1 && actions.length - 1 === index && (
                <Box sx={{ height: '1px', bg: 'border.subtle', my: '8px' }} />
              )}
              <Box
                sx={{
                  color: 'text.main',
                  textDecoration: 'none',
                  fontSize: '14px',
                  lineHeight: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  py: '4px',
                  cursor: 'pointer',
                  '&:hover': {
                    bg: 'neutral.10',
                    opacity: 0.8,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();

                  if (item.onClick && !item.isDisabled) {
                    item.onClick();
                  }

                  ref?.current?.closeDropdown();
                }}
              >
                <Box as={item.icon} size="16px" />
                <Box as="span">{item.title}</Box>
              </Box>
            </Fragment>
          ))}
        </Card>
      </Dropdown>
    </Flex>
  );
};

AdminDataTableActionItem.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      icon: PropTypes.elementType,
      onClick: PropTypes.func,
    }),
  ).isRequired,
};

export default forwardRef(AdminDataTableActionItem);
