import React from 'react';
import { Button, Modal } from '@getro/rombo';
import { Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

const ShareContacts = ({ isSubmitting, onCancel, onShareContacts, networkName }) => (
  <Modal
    title={`Share your contacts with ${networkName}`}
    isOpen
    onCancel={onCancel}
    actions={
      <Flex justifyContent={['flex-end']} sx={{ gap: '8px' }}>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <Button loading={isSubmitting} onClick={onShareContacts}>
          Share contacts
        </Button>
      </Flex>
    }
    data-testid="share-linkedin-sync"
  >
    <Text>Network admins of {networkName} will be able to see your contacts and send you intro requests.</Text>
  </Modal>
);

ShareContacts.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onShareContacts: PropTypes.func.isRequired,
  networkName: PropTypes.string.isRequired,
};

ShareContacts.defaultProps = {
  isSubmitting: false,
};

export default ShareContacts;
