export const mapRoleToPermissions = (role) => {
  switch (role) {
    case 'authenticated':
      return {
        routes: ['all', 'user', 'job_board'],
        home: '/',
      };
    case 'unauthenticated':
      return {
        routes: ['all', 'public'],
        home: '/login',
      };
    case 'onboarding':
      return {
        routes: ['all', 'onboarding'],
        home: '/welcome',
      };
    case 'ally':
      return {
        routes: ['all', 'onboarding'],
        home: '/stay-tuned',
      };
    default:
      return [['all'], '/'];
  }
};

export const setRole = ({ authenticated, onboardingCompleted, isOnlyAlly }) => {
  if (!authenticated) return 'unauthenticated';
  if (isOnlyAlly && !onboardingCompleted) return 'ally';
  if (!onboardingCompleted) return 'onboarding';
  return 'authenticated';
};
