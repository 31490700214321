import React from 'react';
import { ChevronLeft, SidebarOpen } from 'lucide-react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Logo } from '@getro/rombo';
import { toggleMenu } from '../../../features/settingsMenu';
import { SettingsLayoutMenu } from '../../molecules/settingsLayoutMenu';
import { activeNetworkSelector, userSelector } from '../../../redux/selectors';

export const SettingsLayout = ({ children, containerProps }) => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  return (
    <Flex>
      <SettingsLayoutMenu />
      <Box ml={[0, 0, '240px']} flex="1" sx={{ transition: 'none' }}>
        <Box
          display={['flex', 'flex', 'none']}
          height="48px"
          sx={{
            px: '8px',
            alignItems: 'center',
            color: 'text.dark',
            borderBottom: 1,
            borderBottomColor: 'border.subtle',
            gap: '8px',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bg: 'neutral.0',
          }}
        >
          {user.isOnlyAlly && (
            <Flex
              sx={{
                gap: '16px',
                pl: '32px',
              }}
              justifyContent="space-between"
              alignItems="center"
              pt="0"
            >
              <Flex
                sx={{
                  cursor: 'pointer',
                }}
                className="main-menu-open-icon"
                alignItems="center"
                justifyContent="center"
                bg="neutral.0"
                onClick={() => dispatch(toggleMenu())}
              >
                <Box as={SidebarOpen} width="16px" height="16px" aria-hidden="true" flex="0 0 auto" />
              </Flex>
              <Logo color="primary" width="96px" height="20px" />
            </Flex>
          )}
          {!user.isOnlyAlly && (
            <>
              <NavLink to={`/networks/${activeNetwork?.slug}/`}>
                <Box height="32px" width="32px" p="8px" color="text.dark">
                  <Box as={ChevronLeft} size="16px" aria-hidden="true" />
                </Box>
              </NavLink>
              <Flex alignItems="center">
                <Flex
                  className="main-menu-open-icon"
                  alignItems="center"
                  justifyContent="center"
                  width="32px"
                  height="32px"
                  bg="neutral.0"
                  onClick={() => dispatch(toggleMenu())}
                >
                  <Box as={SidebarOpen} width="16px" height="16px" aria-hidden="true" flex="0 0 auto" />
                </Flex>
                <Text fontSize="16px" fontWeight="medium">
                  Settings
                </Text>
              </Flex>
            </>
          )}
        </Box>
        <Flex
          mt={['48px', '48px', 0]}
          minHeight="calc(100vh - 64px)"
          pl={['16px', '16px', '80px']}
          pr={['16px', '16px', 0]}
          pb="40px"
          pt="24px"
          flexDirection="column"
          {...containerProps}
        >
          {children}
        </Flex>
      </Box>
    </Flex>
  );
};

SettingsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  containerProps: PropTypes.object,
};

SettingsLayout.defaultProps = {
  containerProps: {},
};
