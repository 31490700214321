import React, { useRef } from 'react';
import { Modal, Button, FormSelect } from '@getro/rombo';
import { Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { roles } from 'helpers/roles';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

export const EditRole = ({ closeModal, isSubmitting, email, role, updateRole }) => {
  const formRef = useRef(null);

  return (
    <Modal
      title="Edit role"
      isOpen
      onCancel={closeModal}
      actions={
        <Flex justifyContent={['flex-end']} sx={{ gap: '16px' }}>
          <Button onClick={closeModal} variant="tertiary">
            Cancel
          </Button>
          <Button onClick={() => formRef.current.handleSubmit()} loading={isSubmitting}>
            Update role
          </Button>
        </Flex>
      }
    >
      <Flex flexDirection="column" sx={{ gap: '24px' }}>
        <Text fontSize="15px">Select the new role for {email}.</Text>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{ role: roles.find((r) => r.value === role) }}
          validationSchema={Yup.object().shape({
            role: Yup.object().shape({
              value: Yup.string().required('Required'),
              label: Yup.string().required('Required'),
            }),
          })}
          onSubmit={updateRole}
        >
          {({ values, errors }) => (
            <Form>
              <Flex sx={{ flexDirection: 'column', gap: '24px' }}>
                <Field
                  required
                  name="role"
                  error={values.role && errors.role}
                  value={values.role}
                  type="text"
                  label="Role"
                  anchorToBody
                  options={roles}
                  component={FormSelect}
                  labelExtra="Required"
                />
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </Modal>
  );
};

EditRole.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  updateRole: PropTypes.func.isRequired,
};
