import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCookie } from 'nookies';

import { identify as analyticsIdentify, trackEvent } from '../../../helpers/analytics';
import { storeItem } from '../../../helpers/store';
import { identifyUser } from '../../../helpers/supportChat';
import { getDomainName } from '../../../helpers/urlHelpers';
import { setNetworks } from '../../../actions/networksActions';
import { setUser } from '../../../actions/userActions';
import { useSignUpMutation } from '../../../services/user';

export const useSignUp = ({ routePath, sharedList }) => {
  const [userSignUp, { isLoading }] = useSignUpMutation();
  const [apiError, setApiError] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const signUp = (data) => {
    setApiError(null);
    userSignUp({
      ...data,
      collectionId: sharedList?.collection?.id,
      sharedListId: sharedList?.id,
      role: 'ally',
    })
      .unwrap()
      .then((user) => {
        storeItem('authToken', user.authenticationToken);
        setCookie(null, 'authToken', user.authenticationToken, {
          path: '/',
          domain: getDomainName(),
          maxAge: 365 * 24 * 60 * 60,
        });

        analyticsIdentify(user);
        identifyUser(user);
        trackEvent('user_logged_in');

        dispatch(setUser(user));
        dispatch(setNetworks(user));

        return user;
      })
      .then((user) => {
        trackEvent('shared_list:account_created', {
          collectionId: sharedList?.collection?.id,
          listSlug: sharedList?.slug,
          objectType: 'companies',
        });

        return user;
      })
      .then(() => {
        history.push(`${routePath}${routePath.endsWith('/') ? '' : '/'}install-extension`);
      })
      .catch((error) => {
        if (error?.status === 422) {
          setApiError('This email is already linked to an account. Please log in to access your account');
        }
      });
  };

  return { signUp, isLoading, apiError };
};
