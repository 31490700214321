import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

import { DataTable } from '../../../../components/molecules/dataTable';
import { useCompanyTable } from '../../hooks/useCompanyTable';
import { ContactsSelector } from '../contactsSelector';
import { ShareYourContacts } from '../shareYourContacts';

export const Table = ({ sharedList, user }) => {
  const {
    companiesColumns,
    isLoading,
    isFetching,
    queryParams,
    tableRows,
    totalItems,
    hasMore,
    initialSortBy,
    networkOptions,
    selectedNetwork,
    onLoadMore,
    onChangeContactsSelector,
  } = useCompanyTable({ sharedList, user });

  return (
    <Box>
      <DataTable
        itemName="company"
        fixedColumn="last"
        isInitialized={!isLoading}
        overscan={20}
        canSort={false}
        initialSortBy={initialSortBy}
        hasMore={hasMore}
        loadMore={onLoadMore}
        items={tableRows}
        totalItems={totalItems}
        columnDefinition={companiesColumns}
        isLoadingMore={isFetching && queryParams.page !== 1}
        persistColumnKey={`${sharedList.id}-companies-columns`}
        tableSx={{
          minWidth: '1360px',
          height: 'fit-content!important',
        }}
        sx={{
          height: 'calc(100vh - 124px)!important',
        }}
        rightActions={
          <Box mr="-32px" display={['none', 'none', 'block']}>
            <ShareYourContacts sharedList={sharedList} user={user} />
          </Box>
        }
        leftActions={
          networkOptions?.length > 1 ? (
            <Box mx={['16px', '16px', '0']}>
              <ContactsSelector options={networkOptions} value={selectedNetwork} onChange={onChangeContactsSelector} />
            </Box>
          ) : null
        }
      />
    </Box>
  );
};

Table.propTypes = {
  sharedList: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
