import { Message } from '@getro/rombo';
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Link } from 'rebass/styled-components';

export const ContactsReconnectBanner = ({ network, ...rest }) => (
  <Message type="warning" {...rest}>
    Your LinkedIn sync has stopped working.{' '}
    <Link color="text.main" as={NavLink} to={`/networks/${network.slug}/linkedin/reconnect`}>
      Reconnect now
    </Link>{' '}
    to keep your new connections synced in Getro.
  </Message>
);

ContactsReconnectBanner.propTypes = {
  network: PropTypes.object.isRequired,
};
