import { createEntityAdapter } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import deserialize from '../helpers/getroPropertyMapper';
import { baseQuery } from './queries';
import { sharedCompanyListV2Api } from './sharedCompanyListV2';

const itemsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const itemsSelector = itemsAdapter.getSelectors();

export const introsV2Api = createApi({
  reducerPath: 'intros',
  baseQuery: (args, api, extraOptions) => baseQuery({ ...args, version: 'v2' }, api, extraOptions),
  tagTypes: ['leaderboard'],
  endpoints: (builder) => ({
    offerIntro: builder.mutation({
      query: ({ networkId, ...values }) => ({
        url: `/collections/${networkId}/intros`,
        method: 'POST',
        data: values,
      }),
      invalidatesTags: ['leaderboard'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const raw = await queryFulfilled;
          const result = deserialize(raw.data);

          dispatch(
            sharedCompanyListV2Api.util.updateQueryData('getContacts', { networkId: args.networkId }, (draft) => {
              const company = itemsSelector.selectById(draft, args.companyId);
              const contacts = company.contacts.map((contact) => {
                if (contact.id === args.contactId) {
                  return {
                    ...contact,
                    intros: [...contact.intros, result],
                  };
                }
                return contact;
              });

              itemsAdapter.updateOne(draft, {
                id: args.companyId,
                changes: {
                  contacts,
                },
              });
            }),
          );
        } catch (error) {
          // Do nothing
        }
      },
    }),
    getLeaderboard: builder.query({
      query: ({ networkId }) => ({
        url: `/collections/${networkId}/intros/offers_leaderboard`,
        method: 'GET',
      }),
      providesTags: ['leaderboard'],
    }),
  }),
});

export const { useOfferIntroMutation, useGetLeaderboardQuery } = introsV2Api;
