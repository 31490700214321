import React from 'react';
import { Box, Flex, Image, Text } from 'rebass/styled-components';

import Refreshing from '../../../../assets/icon-refreshing.svg';
import { useImportBanner } from '../../hooks/useImportBanner';

export const ImportBanner = () => {
  const { progress } = useImportBanner();

  return (
    <Flex
      alignItems="center"
      flexDirection={['column', 'column', 'row']}
      bg="rgba(0, 0, 46, 0.80)"
      mt="24px"
      mx={['16px', '16px', '0']}
      p="16px"
      sx={{
        borderRadius: 'default',
        justifyContent: 'space-between',
      }}
    >
      <Flex alignItems="center" sx={{ gap: '16px' }}>
        <Image src={Refreshing} width="48px" height="48px" />
        <Box>
          <Text color="neutral.0" fontSize="14px" fontWeight="semibold">
            {`We're processing your contacts.`}
          </Text>
          <Text color="neutral.0" fontSize="14px">
            This usually takes around 20 minutes, we will email you when they are ready.
          </Text>
        </Box>
      </Flex>
      <Flex flexDirection="column" alignItems="flex-end" mt={['24px', '24px', '0']}>
        <Text color="neutral.0" fontSize="12px" fontWeight="medium">
          {`${Math.floor(progress)}%`}
        </Text>
        <Box
          mt="8px"
          sx={{
            width: '320px',
            height: '8px',
            bg: 'purple.100',
            borderRadius: 'circle',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              width: `${Math.floor(progress)}%`,
              background: 'linear-gradient(90deg, #B127FF 0%, #FF9F8A 100%)',
              borderRadius: 'circle',
            }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
