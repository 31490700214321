import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Button, FormInput, FormSelect, Message, Modal } from '@getro/rombo';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { roles } from 'helpers/roles';
import { emailRegEx } from '../../../helpers/regExs';

export const InviteMemberModal = ({ isSubmitting, onAdminInvite, setModalOpen, adminsEmails, error }) => {
  const formRef = useRef(null);
  const inviteAdminFormikSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string(),
    email: Yup.string().trim().matches(emailRegEx, 'Not a valid email').required('Required'),
    role: Yup.object().shape({
      value: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
    }),
  });

  const inviteAdminInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: roles[0],
  };

  const isEmailAlreadyInvited = (email) => adminsEmails.includes(email);

  return (
    <Modal pauseFocusTrap title="Invite member" isOpen onCancel={() => setModalOpen(false)} data-testid="invite-admin">
      <Flex flexDirection="column" sx={{ gap: '24px' }}>
        <Text fontSize="15px">
          Invite Network admins and Allies to join your network. You can invite Company admins directly from their
          company profiles.
        </Text>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={inviteAdminInitialValues}
          validationSchema={inviteAdminFormikSchema}
          onSubmit={(values) => {
            if (isEmailAlreadyInvited(values.email)) {
              return;
            }

            onAdminInvite({
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              role: values.role.value,
            });
          }}
        >
          {({ values, errors }) => (
            <Form>
              <Flex sx={{ flexDirection: 'column', gap: '24px' }}>
                <Box>
                  <Field
                    required
                    name="firstName"
                    error={values.firstName && errors.firstName}
                    value={values.firstName}
                    type="text"
                    label="Name"
                    component={FormInput}
                    labelExtra="Required"
                  />
                </Box>
                <Box>
                  <Field
                    name="lastName"
                    error={errors.lastName}
                    value={values.lastName}
                    type="text"
                    label="Last name"
                    component={FormInput}
                  />
                </Box>
                <Box width={1}>
                  <Field
                    required
                    name="email"
                    error={values.email && errors.email}
                    value={values.email}
                    type="text"
                    label="Email"
                    labelExtra="Required"
                    component={FormInput}
                  />
                </Box>
                <Box>
                  <Field
                    required
                    name="role"
                    error={values.role && errors.role}
                    value={values.role}
                    type="text"
                    label="Role"
                    anchorToBody
                    options={roles}
                    component={FormSelect}
                    labelExtra="Required"
                  />
                </Box>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    gap: '16px',
                    borderTop: '1px solid',
                    pt: '16px',
                    borderColor: 'neutral.100',
                  }}
                >
                  {isEmailAlreadyInvited(values.email) && (
                    <Message content={`${values.email} is already a team member.`} />
                  )}
                  {error && (
                    <Message type="error" content="There was an unexpected error, please try again or contact us." />
                  )}
                  <Flex justifyContent={['flex-end']}>
                    <Button mr={[2]} onClick={() => setModalOpen(false)} variant="tertiary">
                      Cancel
                    </Button>
                    <Button ml={[2]} loading={isSubmitting} type="submit">
                      Invite member
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </Modal>
  );
};

InviteMemberModal.propTypes = {
  onAdminInvite: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  adminsEmails: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.string,
};

InviteMemberModal.defaultProps = {
  isSubmitting: false,
  adminsEmails: [],
  error: '',
};
