import React, { useState } from 'react';
import { Button, Checkbox, FormError, FormInput, Message } from '@getro/rombo';
import { Formik, Form, Field } from 'formik';
import { ChevronRight, EyeOff, ShieldCheck } from 'lucide-react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { useSignUp } from '../../hooks/useSignUp';
import { useSignIn } from '../../hooks/useSignIn';

export const SignUp = ({ routePath, sharedList }) => {
  const { signUp, isLoading: isLoadingSignUp, apiError: apiErrorSignUp } = useSignUp({ routePath, sharedList });
  const { signIn, isLoading: isLoadingSignIn, apiError: apiErrorSignIn } = useSignIn({ routePath, sharedList });
  const [showSignIn, setShowSignIn] = useState(false);
  const history = useHistory();

  const formSchemaSignUp = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    agree: Yup.boolean().oneOf([true], 'You must agree to the terms of service and privacy policy'),
  });

  const formSchemaSignIn = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const onSubmit = ({ email, password, firstName, lastName }) => {
    if (showSignIn) {
      signIn({ email, password });
    } else {
      signUp({ email, password, firstName, lastName });
    }
  };

  return (
    <Flex
      bg="neutral.0"
      flexDirection={['column', 'column', 'row']}
      sx={{
        gap: '24px',
        boxShadow: 'medium',
        borderRadius: 'default',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 5,
      }}
    >
      <Box p="24px" maxWidth="462px">
        <Text fontSize="22px" fontWeight="semibold" color="text.dark">
          Find who you know at these companies
        </Text>
        <Text mt="16px" mb="32px">
          Connect your LinkedIn to easily find warm intros and offer help.
        </Text>
        {showSignIn ? (
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={formSchemaSignIn}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            <Box as={Form} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <Field
                name="email"
                component={FormInput}
                type="email"
                label="Your work email"
                placeholder="Enter your email address"
              />
              <Field
                name="password"
                component={FormInput}
                type="password"
                label="Password"
                placeholder="Create a new password"
              />
              <Button
                width="100%"
                justifyContent="center"
                type="submit"
                loading={isLoadingSignIn}
                icon={ChevronRight}
                iconPosition="right"
              >
                Log in
              </Button>
            </Box>
          </Formik>
        ) : (
          <Formik
            initialValues={{ email: '', password: '', agree: false, firstName: '', lastName: '' }}
            validationSchema={formSchemaSignUp}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, errors }) => (
              <Box as={Form} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Field name="firstName" component={FormInput} type="text" label="First name" />
                <Field name="lastName" component={FormInput} type="text" label="Last name" />
                <Field
                  name="email"
                  component={FormInput}
                  type="email"
                  label="Your work email"
                  placeholder="Enter your email address"
                />
                <Field
                  name="password"
                  component={FormInput}
                  type="password"
                  label="Password"
                  placeholder="Create a new password"
                />
                <Flex>
                  <Checkbox checked={values.agree} onChange={() => setFieldValue('agree', !values.agree)} />
                  <Text
                    fontSize="14px"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setFieldValue('agree', !values.agree)}
                  >
                    I accept the{' '}
                    <Box as="a" href="https://www.getro.com/terms" target="_blank" rel="noopener noreferrer">
                      terms of service
                    </Box>{' '}
                    and{' '}
                    <Box as="a" href="https://www.getro.com/privacy" target="_blank" rel="noopener noreferrer">
                      privacy policy
                    </Box>
                  </Text>
                </Flex>
                {errors.agree && <FormError mt="0">{errors.agree}</FormError>}
                <Button
                  width="100%"
                  justifyContent="center"
                  type="submit"
                  loading={isLoadingSignUp}
                  icon={ChevronRight}
                  iconPosition="right"
                >
                  Sign up
                </Button>
                {!!apiErrorSignUp && <Message type="info" content={apiErrorSignUp} />}
                {!!apiErrorSignIn && <Message type="info" content={apiErrorSignIn} />}
              </Box>
            )}
          </Formik>
        )}
        {showSignIn ? (
          <Text as="p" mt="32px" fontSize="14px" width="100%" sx={{ textAlign: 'center' }}>
            <Text
              as="span"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => setShowSignIn(false)}
            >
              Sign up.
            </Text>
            <Text as="span">{` · `}</Text>
            <Text
              as="span"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => history.push('/recover-password')}
            >
              Recover password
            </Text>
          </Text>
        ) : (
          <Text as="p" mt="32px" fontSize="14px" width="100%" sx={{ textAlign: 'center' }}>
            Already a Getro user?{' '}
            <Text as="span" sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setShowSignIn(true)}>
              Log in
            </Text>
          </Text>
        )}
      </Box>
      <Box p="24px" bg="neutral.20" maxWidth={['100%', '100%', '320px']}>
        <Text
          mt="16px"
          fontSize="12px"
          fontWeight="medium"
          lineHeight="140%"
          sx={{ letterSpacing: '1px', textTransform: 'uppercase' }}
        >
          How it works
        </Text>
        <Box mt="16px">
          <Flex as="ul" flexDirection="column" sx={{ listStyle: 'none', padding: 0, margin: 0, gap: '16px' }}>
            <Flex
              alignItems="flex-start"
              sx={{
                gap: '8px',
                position: 'relative',
                '&::after': {
                  content: "''",
                  backgroundColor: 'neutral.300',
                  width: '2px',
                  height: 'calc(100% - 24px + 16px)',
                  position: 'absolute',
                  left: '11px',
                  top: '24px',
                },
              }}
            >
              <Flex
                width="24px"
                height="24px"
                bg="neutral.300"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: 'circle' }}
                flex="0 0 auto"
              >
                <Text fontSize="14px" fontWeight="medium" color="neutral.0">
                  1
                </Text>
              </Flex>
              <Box>
                <Text fontSize="14px" fontWeight="medium" color="text.dark">
                  Connect your LinkedIn
                </Text>
                <Text as="p" fontSize="14px" mt="4px">
                  Sync your contacts privately–only you can see them.
                </Text>
              </Box>
            </Flex>
            <Flex
              alignItems="flex-start"
              sx={{
                gap: '8px',
                position: 'relative',
                '&::after': {
                  content: "''",
                  backgroundColor: 'neutral.300',
                  width: '2px',
                  height: 'calc(100% - 24px + 16px)',
                  position: 'absolute',
                  left: '11px',
                  top: '24px',
                },
              }}
            >
              <Flex
                width="24px"
                height="24px"
                bg="neutral.300"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: 'circle' }}
                flex="0 0 auto"
              >
                <Text fontSize="14px" fontWeight="medium" color="neutral.0">
                  2
                </Text>
              </Flex>
              <Box>
                <Text fontSize="14px" fontWeight="medium" color="text.dark">
                  Find opportunities
                </Text>
                <Text fontSize="14px" mt="4px">
                  Identify key connections.
                </Text>
              </Box>
            </Flex>
            <Flex alignItems="flex-start" sx={{ gap: '8px' }}>
              <Flex
                width="24px"
                height="24px"
                bg="neutral.300"
                alignItems="center"
                justifyContent="center"
                sx={{ borderRadius: 'circle' }}
                flex="0 0 auto"
              >
                <Text fontSize="14px" fontWeight="medium" color="neutral.0">
                  3
                </Text>
              </Flex>
              <Box>
                <Text fontSize="14px" fontWeight="medium" color="text.dark">
                  Make intros
                </Text>
                <Text fontSize="14px" mt="4px">
                  {`Choose the best opportunities and support ${sharedList.collection?.name}.`}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Box>
        <Text
          mt="40px"
          fontSize="12px"
          fontWeight="medium"
          lineHeight="140%"
          sx={{ letterSpacing: '1px', textTransform: 'uppercase' }}
        >
          Your data is safe and private
        </Text>
        <Flex as="ul" mt="16px" flexDirection="column" sx={{ listStyle: 'none', padding: 0, margin: 0, gap: '16px' }}>
          <Flex as="li" alignItems="flex-start" sx={{ gap: '8px', color: 'neutral.500' }}>
            <Box as={EyeOff} width="24px" height="24px" aria-hidden="true" flex="0 0 auto" />
            <Text fontSize="14px" color="text.main">
              Your contacts stay visible only to you unless you choose to share them.
            </Text>
          </Flex>
          <Flex as="li" alignItems="flex-start" sx={{ gap: '8px', color: 'neutral.500' }}>
            <Box as={ShieldCheck} width="24px" height="24px" aria-hidden="true" flex="0 0 auto" />
            <Text fontSize="14px" color="text.main">
              We only collect who you are connected to, nothing else.
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

SignUp.propTypes = {
  routePath: PropTypes.string.isRequired,
  sharedList: PropTypes.object.isRequired,
};
