import React from 'react';
import { connect } from 'react-redux';
import { Flex } from 'rebass/styled-components';
import pageHoc from 'hocs/pageHoc';

import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import MainLayout from '../../components/layout/Main';
import { activeNetworkSelector } from '../../redux/selectors';
import compose from '../../helpers/compose';
import NoAccess from './NoAccess';
import Sidebar from './Sidebar';
import Content from './Content';
import AnalyticsContext from './context';
import NetworkSchema from '../../schemas/network';

const pageTitle = 'Job board analytics';

const Analytics = ({ activeNetwork }) => (
  <MainLayout>
    <MainLayoutHeader title={pageTitle} />
    <Flex pt="24px" mx="auto" width="100%" maxWidth="1600px" flexDirection={['column', 'column', 'row']}>
      {activeNetwork.isManager ? (
        <AnalyticsContext>
          <Sidebar activeNetwork={activeNetwork} />
          <Content activeNetwork={activeNetwork} />
        </AnalyticsContext>
      ) : (
        <Flex flex="1" justifyContent="center">
          <NoAccess />
        </Flex>
      )}
    </Flex>
  </MainLayout>
);

Analytics.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
};

const mapStateToProps = (state) => ({
  activeNetwork: activeNetworkSelector(state),
});

const enhance = compose(pageHoc({ title: pageTitle }), connect(mapStateToProps));
export default enhance(Analytics);
