import React, { useEffect } from 'react';
import { FormInput, AsyncButton, Message, Checkbox, FormError } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useUpdateUserMutation } from 'services/user';
import { trackEvent } from 'helpers/analytics';
import { useShareContactsMutation } from 'services/userV2';
import AllySignupLayout from './components/AllySignupLayout';
import { userSelector } from '../../redux/selectors';
import UserAndCompanyProfile from './components/UserAndCompanyProfile';

export const AllySignup = () => {
  const user = useSelector(userSelector);
  const { networkSlug } = useParams();
  const network = user?.allyingCollections?.find((nc) => nc.slug === networkSlug);
  const [updateUser, { isLoading, isError }] = useUpdateUserMutation();
  const [shareContacts, { isLoading: isShareContactsLoading }] = useShareContactsMutation();

  const history = useHistory();

  useEffect(() => {
    if (network) {
      trackEvent('ally_onboarding:start', {
        collection_id: network?.id,
      });
    }
  }, [user, history, network]);

  if (!network) {
    return <Redirect to="/stay-tuned" />;
  }

  const step = [
    {
      title: 'Connect your LinkedIn (less than 2 minutes)',
      description: 'Your new and existing connections will stay automatically up to date.',
    },
    {
      title: `${network.name} finds meaningful intros`,
      description: `The ${network.name} team can search your contacts and send intro requests to you.`,
    },
    {
      title: 'Make the intro',
      description: `You choose which intros to make, helping both ${network.name} and your contacts.`,
    },
  ];

  const formSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    agree: Yup.boolean().oneOf([true], 'You must agree to the terms of service and privacy policy'),
  });

  const handleShareContacts = async () => {
    const { data } = await shareContacts({
      collectionId: network.id,
      share: true,
    });

    if (data) {
      history.push(`/onboarding/${network.slug}/linkedin/sync?ally=true`);
    }
  };

  return (
    <AllySignupLayout
      isShareContactsLoading={isShareContactsLoading}
      onShareContacts={handleShareContacts}
      user={user}
      network={network}
      isLoggedIn={user && user.onboardingCompleted}
      justifyContent="center"
      alignItems="center"
      height="100vh"
      steps={step}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '160px',
          flex: 1,
          height: '100%',
        }}
      >
        <Box maxWidth="400px" m="0px auto" pb="40px">
          <Flex sx={{ flexDirection: 'column', gap: '40px' }}>
            <Flex sx={{ flexDirection: 'column', gap: '16px', flex: 1 }}>
              {network && user && <UserAndCompanyProfile network={network} user={user} />}
              <Text sx={{ fontWeight: 600, fontSize: '22px', color: 'text.dark', lineHeight: '1.4' }}>
                Start collaborating with {network.name}
              </Text>
              <Text>
                Share your contacts with them on Getro, so they can find high-impact introductions between them and
                their network.
              </Text>
            </Flex>
            <Box>
              <Formik
                initialValues={{
                  email: user.email,
                  password: '',
                  agree: false,
                  firstName: user.firstName,
                  lastName: user.lastName,
                }}
                validationSchema={formSchema}
                enableReinitialize={false}
                onSubmit={async (data) => {
                  const res = await updateUser({
                    password: data.password,
                    passwordConfirmation: data.password,
                    currentPassword: data.password,
                    email: data.email,
                    onboardingCompleted: true,
                    termsPolicyVersion: '1.1',
                    privacyPolicyVersion: '1.1',
                    firstName: data.firstName,
                    lastName: data.lastName,
                  });

                  if (res.data) {
                    trackEvent('ally_onboarding:account_created', {
                      collection_id: network?.id,
                    });
                    history.push(`/onboarding/${network.slug}/linkedin/sync?ally=true`);
                  }
                }}
              >
                {({ values, setFieldValue, errors }) => (
                  <Box as={Form} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Field
                      component={FormInput}
                      data-cy="login-first-name"
                      name="firstName"
                      label="First name"
                      size="medium"
                      value={values.firstName}
                    />
                    <Field
                      component={FormInput}
                      data-cy="login-last-name"
                      name="lastName"
                      label="Last name"
                      size="medium"
                      value={values.lastName}
                    />
                    <Field
                      component={FormInput}
                      data-cy="login-email"
                      type="email"
                      name="email"
                      label="Email address"
                      placeholder="Enter your email address"
                      size="medium"
                      value={values.email}
                      disabled
                    />
                    <Field
                      component={FormInput}
                      data-cy="login-password"
                      type="password"
                      name="password"
                      label="Password"
                      placeholder="Create a new password"
                      value={values.password}
                      size="medium"
                    />
                    <Flex flexDirection="column" sx={{ gap: '8px' }}>
                      <Flex>
                        <Checkbox checked={values.agree} onChange={() => setFieldValue('agree', !values.agree)} />
                        <Text
                          sx={{ cursor: 'pointer' }}
                          fontSize="14px"
                          onClick={() => setFieldValue('agree', !values.agree)}
                        >
                          I agree to the{' '}
                          <Box
                            as="a"
                            href="https://www.getro.com/terms"
                            onClick={(e) => e.stopPropagation()}
                            target="_blank"
                          >
                            Terms of Service
                          </Box>{' '}
                          and{' '}
                          <Box
                            as="a"
                            href="https://www.getro.com/privacy"
                            onClick={(e) => e.stopPropagation()}
                            target="_blank"
                          >
                            Privacy Policy
                          </Box>
                        </Text>
                      </Flex>
                      {errors.agree && <FormError m="0px">{errors.agree}</FormError>}
                    </Flex>
                    <AsyncButton
                      fontWeight="semibold"
                      width="100%"
                      justifyContent="center"
                      type="submit"
                      variant="primary"
                      loading={isLoading}
                      size="medium"
                    >
                      Create account
                    </AsyncButton>
                    {isError && (
                      <Message
                        type="error"
                        className="Login__error-message"
                        content="There was an unexpected error, please try again or contact help@getro.com."
                      />
                    )}
                  </Box>
                )}
              </Formik>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </AllySignupLayout>
  );
};

export default AllySignup;
