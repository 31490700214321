import React from 'react';
import { Button } from '@getro/rombo';
import { ExternalLink } from 'lucide-react';
import { Flex } from 'rebass/styled-components';

import { EXTENSION_URL } from '../../services/chromeExtension';

export const InstallChromeExtension = () => (
  <Flex data-testid="install-chrome-extension" flexDirection="column" sx={{ gap: '16px', borderRadius: '8px' }}>
    <Button
      iconGap="8px"
      onClick={() => window.open(EXTENSION_URL)}
      iconPosition="right"
      width="100%"
      justifyContent="center"
      icon={ExternalLink}
    >
      Install extension and add your contacts
    </Button>
  </Flex>
);
