import React from 'react';
import { Logo, ProfilePicture } from '@getro/rombo';
import { Box, Flex, Link, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import { UserMenu } from '../userMenu';
import { HowItWorks } from '../howItworks';

export const Header = ({ sharedList }) => (
  <Box as="header" px={['16px', '16px', '0']}>
    <Flex justifyContent="space-between" flexDirection={['column-reverse', 'column-reverse', 'row']}>
      <Flex
        alignItems={['flex-start', 'flex-start', 'baseline']}
        flexGrow="1"
        flexDirection={['column', 'column', 'row']}
        mt={['40px', '40px', '0']}
        sx={{ gap: '8px' }}
      >
        <Text fontSize="28px" fontWeight="bold" color="text.dark">
          {sharedList.name}
        </Text>
        <Text fontSize="18px" color="text.subtle">
          {`${sharedList.smartSharedList?.filters?.companyIds?.length || 0} ${pluralize(
            'company',
            sharedList.smartSharedList?.filters?.companyIds?.length || 0,
          )}`}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection={['row-reverse', 'row-reverse', 'row']}
        sx={{ gap: '16px' }}
      >
        <Flex alignItems="center" sx={{ gap: '16px' }}>
          <HowItWorks pointOfContact={sharedList.pointOfContact} networkName={sharedList.collection?.name} />
          <UserMenu />
        </Flex>
        <Box display={['none', 'none', 'block']} height="16px" width="1px" bg="border.subtle" />
        <Link href="https://www.getro.com" target="_blank" sx={{ position: 'relative', top: '-3px' }}>
          <Logo width="96px" />
        </Link>
      </Flex>
    </Flex>
    <Flex mt="16px" sx={{ gap: '16px' }}>
      <Box sx={{ position: 'relative' }} flex="0 0 auto">
        <ProfilePicture
          size="48px"
          variant={sharedList.pointOfContact?.avatarUrl ? 'default' : 'square'}
          name={sharedList.pointOfContact?.fullName}
          imageUrl={sharedList.pointOfContact?.avatarUrl || sharedList.collection?.logoUrl}
        />
        {sharedList.pointOfContact?.avatarUrl && (
          <Box sx={{ position: 'absolute', bottom: '-8px', right: '-8px' }}>
            <ProfilePicture
              size="24px"
              variant="square"
              name={sharedList.collection?.name}
              imageUrl={sharedList.collection?.logoUrl}
            />
          </Box>
        )}
      </Box>
      <Box>
        <Text as="p">
          <Text as="span" fontWeight="medium" color="text.dark">
            {sharedList.pointOfContact?.fullName}
          </Text>{' '}
          from{' '}
          <Text as="span" fontWeight="medium" color="text.dark">
            {sharedList.collection?.name}
          </Text>
        </Text>
        <Text mt="4px">{`Hey, we're looking to connect with these companies and we'd really appreciate a warm intro! Connect your LinkedIn to easily find and offer intros.`}</Text>
      </Box>
    </Flex>
  </Box>
);

Header.propTypes = {
  sharedList: PropTypes.object,
};

Header.defaultProps = {
  sharedList: {},
};
