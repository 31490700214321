import { urlWithoutProtocolRegEx } from './regExs';

export const validateDomains = {
  name: 'domains',
  skipAbsent: true,
  test(value, ctx) {
    const getInvalidDomains = (domains) =>
      domains
        .filter((domain) => domain && domain.length)
        .filter((domain) => !RegExp(urlWithoutProtocolRegEx).test(domain));
    const invalidDomains = getInvalidDomains(value.split(/[\n,]+/));

    if (invalidDomains.length) {
      return ctx.createError({
        message: `Please review the format of the following websites: ${invalidDomains.join(', ')}`,
      });
    }

    return true;
  },
};
