export const roles = [
  {
    label: 'Ally',
    value: 'ally',
    description: 'Can share their contacts with your network, no access to other features.',
  },
  {
    label: 'Network admin',
    description: 'Has full access, including managing data, network settings, billing, and team members.',
    value: 'network_admin',
  },
];
