import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass/styled-components';

import { ScrollBox } from '../../../../components/atoms/ScrollBox';
import { useFilters } from '../../hooks/useFilters';

export const Filters = ({ filters }) => {
  const { filters: parsedFilters } = useFilters(filters);

  return (
    <Box width="100%" p="16px" height="200px" bg="neutral.20" sx={{ position: 'relative', borderRadius: 'default' }}>
      <Text fontWeight="medium" color="black">
        Profiles I want to connect with
      </Text>
      <ScrollBox mt="16px" height="138px">
        {parsedFilters.length ? (
          <>
            {parsedFilters.map((filter) => (
              <Box key={filter.label} mb="8px">
                <Text fontSize="14px" color="text.subtle">
                  {filter.label}
                </Text>
                <Text fontSize="14px" fontWeight="medium">
                  {filter.value}
                </Text>
              </Box>
            ))}
          </>
        ) : (
          <Text fontSize="14px" fontStyle="italic">
            Anyone at the company
          </Text>
        )}
      </ScrollBox>
      <Box
        width="100%"
        height="24px"
        sx={{
          position: 'absolute',
          bottom: '8px',
          left: '0',
          background: 'linear-gradient(0deg, #F7FAFC 0%, rgba(247, 250, 252, 0.00) 100%)',
        }}
      />
    </Box>
  );
};

Filters.propTypes = {
  filters: PropTypes.object,
};

Filters.defaultProps = {
  filters: {},
};
