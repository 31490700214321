import ClaimYourProfile from '../pages/ClaimYourProfile';
import EmployerOnboarding from '../pages/EmployerOnboarding';

export const onboardingEmployersRoutes = {
  selectYourCompany: {
    path: '/claim-your-profile/:networkSlug',
    name: 'Your company',
    component: ClaimYourProfile,
    visibility: ['public', 'onboarding', 'user'],
  },
  claimYourProfile: {
    path: '/claim-your-profile/:networkSlug/company/:employerSlug',
    name: 'Claim your profile',
    component: ClaimYourProfile,
    visibility: ['public', 'onboarding', 'user'],
  },
  onboardingEmployer: {
    path: '/onboarding/:networkSlug/company/:employerSlug',
    name: 'Company onboarding',
    component: EmployerOnboarding,
    visibility: ['public', 'onboarding', 'user'],
  },
};

export const allySignupRoute = {
  path: '/onboarding/:networkSlug/ally-signup',
  name: 'Ally signup',
};
