import { Briefcase, Contact2, Gauge, HeartHandshake, Home, LayoutList, List, Users } from 'lucide-react';
import { useSelector } from 'react-redux';
import { hasContactsSelector, hasImpactAnalyticsEnabledSelector } from 'redux/selectors';

export const useMainMenu = (activeNetwork) => {
  const menuItems = [];
  const { isManager, isOrganizationManager, features, tnHidden } = activeNetwork;
  const managingOrganizationIds = Object.keys(isOrganizationManager);
  const showConnect = useSelector(hasContactsSelector);
  const isImpactAnalyticsEnabled = useSelector(hasImpactAnalyticsEnabledSelector);
  const isJobBoardEnabled = features && features.includes('job_board');

  if (isImpactAnalyticsEnabled && isJobBoardEnabled) {
    menuItems.push({ icon: Home, label: 'Home', path: `/networks/${activeNetwork.slug}/home` });
  }

  if (isManager && !showConnect && features.includes('tn_onboarding_v2')) {
    menuItems.push({ icon: Contact2, label: 'Contacts', path: `/networks/${activeNetwork.slug}/contacts` });
  }

  if (showConnect) {
    menuItems.push({ icon: Contact2, label: 'Contacts', path: `/networks/${activeNetwork.slug}/contacts` });
  }

  if (showConnect) {
    menuItems.push({ icon: List, label: 'Lists', path: `/networks/${activeNetwork.slug}/lists` });
  }

  if (features.includes('talent_network') && !(!isManager && isOrganizationManager && tnHidden)) {
    menuItems.push({ icon: Users, label: 'Members', path: `/networks/${activeNetwork.slug}/members` });
  }

  if (!isManager && managingOrganizationIds.length === 1) {
    const company = isOrganizationManager[managingOrganizationIds[0]];

    menuItems.push({
      icon: Briefcase,
      path: `/networks/${activeNetwork.slug}/companies/${managingOrganizationIds[0]}-${company.slug}`,
      label: `${company.name} profile`,
    });
  }

  if (isManager || Object.keys(isOrganizationManager).length >= 2) {
    menuItems.push({ icon: Briefcase, label: 'Companies', path: `/networks/${activeNetwork.slug}/companies` });
  }

  if (isManager || isOrganizationManager) {
    menuItems.push({
      icon: LayoutList,
      label: 'Jobs',
      path: `/networks/${activeNetwork.slug}/jobs`,
    });
  }

  if (isManager) {
    menuItems.push({ icon: Gauge, label: 'Analytics', path: `/networks/${activeNetwork.slug}/analytics` });
  }

  if (showConnect) {
    menuItems.push({ icon: HeartHandshake, label: 'Hires', path: `/networks/${activeNetwork.slug}/hires` });
  }

  const jobMenu = new Set(['Companies', 'Jobs', 'Analytics', 'Hires', 'Settings']);

  const jobItems = menuItems.filter((item) => jobMenu.has(item.label));

  jobItems.push({
    icon: null,
    label: 'Settings',
    path: `/networks/${activeNetwork.slug}/settings/job-board-appearance`,
  });

  return { menuItems: isManager ? menuItems.filter((item) => !jobMenu.has(item.label)) : menuItems, jobItems };
};
