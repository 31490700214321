/* eslint-disable no-param-reassign */
import { Button } from '@getro/rombo';
import { Stars, X } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useLazySearchWithAiQuery } from 'services/contactsV2';
import { useHistory } from 'react-router-dom';
import { useContactsSearchParam } from 'pages/contactsExtended/hooks/useContactsSearchParam';
import { reverseParsedFilters } from 'services/contacts/filters';
import PropTypes from 'prop-types';
import { defaultFilters } from 'components/organisms/contactsFilters/defaultFilters';
import { trackEvent } from 'helpers/analytics';
import { useSelector } from 'react-redux';
import { activeNetworkSelector } from 'redux/selectors';

export const AIFilters = ({ setFilters }) => {
  const [value, setValue] = useState(new URLSearchParams(window.location.search).get('aiFilters') || '');
  const [showInput, setShowInput] = useState(!!value);
  const [searchWithAi, { isFetching }] = useLazySearchWithAiQuery();
  const history = useHistory();
  const [queryParams] = useContactsSearchParam();
  const [hasError, setHasError] = useState(false);
  const { id: collectionId } = useSelector(activeNetworkSelector);

  const onEnter = async () => {
    setHasError(false);
    const search = window.location.search
      .replace(/^\?/, '')
      .split('&')
      .filter((param) => param.split('=')[0] !== 'aiFilters')
      .join('&');

    history.push({
      search: value ? `${search}${search ? '&' : '?'}page=1&aiFilters=${value}` : search,
    });

    trackEvent('contacts:natural_language_query', {
      query: value,
      collectionId,
    });

    const { data, error } = await searchWithAi({ query: value, queryParams });

    if (error || !Object.keys(data?.filters || {}).length) {
      setHasError(true);
      return;
    }
    const filters = reverseParsedFilters(data.filters);

    dispatchEvent(new Event('openFiltersPreferences'));
    const preferenceFilters = new Set([
      'signupHasResume',
      'signupDaysSinceJoined',
      'signupJobSearchStatuses',
      'signupSeniorities',
      'signupWorkEnvironment',
      'signupLocations',
      'signupJobSearchStatuses',
    ]);

    const hasPreferenceFilters = Object.keys(filters).some((key) => preferenceFilters.has(key));

    if (hasPreferenceFilters) {
      dispatchEvent(new Event('openFiltersPreferences'));
    }

    setFilters(filters, { resetPreviousFilters: true });
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }

    window.addEventListener('resetFilters', () => {
      setValue('');
      setHasError(false);
      history.push({
        search: '',
      });
    });
  }, [history]);

  return (
    <Flex
      sx={{
        input: {
          p: '8px',
          fontSize: '14px',
        },
        flexDirection: 'column',
        gap: '8px',
        padding: '8px',
        border: '1px solid',
        borderColor: 'border.subtle',
        borderRadius: '4px',
      }}
    >
      <Flex sx={{ flexDirection: 'column', gap: '8px', width: '100%' }}>
        <Flex
          data-testid="filter-title-trigger"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            cursor: 'pointer',
            ':hover': {
              p: {
                color: 'text.dark',
              },

              '& svg:last-child': {
                color: 'text.dark',
              },
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowInput(!showInput);
          }}
        >
          <Flex sx={{ alignItems: 'center', gap: '8px' }}>
            <Box
              onClick={(e) => {
                e.stopPropagation();
              }}
              size="16px"
              sx={{
                color: 'purple.300',
              }}
              as={Stars}
            />
            <Text as="p" fontSize="14px" fontWeight="500" lineHeight="1.4" color="text.main">
              Start filtering with AI
            </Text>
          </Flex>
          {showInput && (
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setShowInput(false);
                setValue('');
                history.push({
                  search: '',
                });
                setFilters(defaultFilters);
              }}
              size="16px"
              sx={{
                color: 'text.subtle',
              }}
              as={X}
            />
          )}
        </Flex>
      </Flex>
      {showInput && (
        <>
          <Text fontSize="14px" color="text.subtle" lineHeight="1.4">
            Describe what you need and let our AI set the right filters.
          </Text>
          <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
            <Flex
              ref={inputRef}
              placeholder="Example: Product managers working in the AI department"
              as="textarea"
              value={value}
              autoFocus
              data-testid="search-with-ai-filter-input"
              onChange={({ target }) => {
                setValue(target.value);
                if (target.value.length > 0) {
                  target.style.height = `${target.scrollHeight}px`;
                } else {
                  target.style.height = '56px';
                }
              }}
              onKeyDown={({ target, key }) => {
                if (key === 'Enter' && target.value.trim()) {
                  onEnter();
                }
              }}
              wrap="soft"
              sx={{
                '&::placeholder': {
                  color: 'text.subtle',
                  fontSize: '14px',
                  lineHeight: '19.6px',
                },
                fontSize: '14px',
                padding: '8px',
                color: 'text.main',
                outline: 'none',
                borderColor: 'neutral.400',
                borderRadius: '4px',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                height: '56px',
                minHeight: '56px',
                resize: 'none',
                overflow: 'hidden',
              }}
            />
          </Flex>

          <Button
            size="small"
            loading={isFetching}
            disabled={!value}
            onClick={() => onEnter()}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',

              '&:disabled:not(.ladda-button)': {
                cursor: 'not-allowed',
                bg: 'neutral.300',
              },
            }}
          >
            Search with AI
          </Button>
          {hasError && (
            <Box sx={{ color: 'text.error', fontSize: '14px', lineHeight: '1.4' }}>
              Sorry, we didn’t get that. Try rephrasing your search or set the filters below.
            </Box>
          )}
        </>
      )}
    </Flex>
  );
};

AIFilters.propTypes = {
  setFilters: PropTypes.func.isRequired,
};
