import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import FundFilterGrowth from 'components/atoms/FundFilterGrowth';
import { AIFilters } from 'components/molecules/filters/aiFilters';
import OptionsFilter from '../../atoms/OptionsFilter';
import KeywordFilter from '../../atoms/KeywordFilter';
import MultiSelect from '../../atoms/MultiSelect';
import { ApiFacet } from '../../molecules/filters/apiFacet';
import { LocationFilter } from '../../molecules/filters/location';
import { ContactOfFilter } from '../../molecules/filters/contactOf';
import { JobTitle } from '../../molecules/filters/jobTitle';
import YearsOfExperienceFilter from '../../molecules/filters/yearsOfExperience';
import { SharedList } from '../../molecules/filters/sharedListFilters';
import { TalentNetworkSignupFilter } from './talentNetworkSignup';
import { FiltersPreferences } from './filtersPreferences';
import { InPortcoFilter } from './inPortco';
import { CompanyFilter } from './company';
import { seniorityOptions } from './defaultFilters';

export const Filters = ({
  sharedList,
  filters,
  filterCount,
  setFilters,
  extendedMode,
  isTalentNetworkEnabled,
  showListFilters,
  clearFundFilters,
  isEdit,
}) => {
  const companySizeOptions = [
    { label: '1 - 10', value: 1 },
    { label: '11 - 50', value: 2 },
    { label: '51 - 200', value: 3 },
    { label: '201 - 1,000', value: 4 },
    { label: '1,001 - 5,000', value: 5 },
    { label: '5,000+', value: 6 },
  ];

  return (
    <Flex
      flexGrow="1"
      alignItems="flex-end"
      pb={filterCount > 0 ? '72px' : 0}
      sx={{
        gap: '4px',
        flexWrap: 'wrap',
        '& > div': {
          py: '8px',
        },
      }}
    >
      {showListFilters && (
        <Box width="100%" data-testid="contact-filters-ai-filters">
          <AIFilters value={filters.aiFilters} setFilters={setFilters} />
        </Box>
      )}
      <Flex width="100%" flexDirection="column" sx={{ gap: '8px' }} data-testid="contact-filters-job-titles">
        <JobTitle filters={filters} setFilters={setFilters} sharedList={sharedList} showListFilters={showListFilters} />
      </Flex>
      <Box width="100%" data-testid="contact-filters-location">
        <LocationFilter
          locationRadius={filters.locationRadius}
          value={filters.locationIds}
          setFilters={setFilters}
          showRadius
        />
      </Box>
      <Box width="100%">
        <MultiSelect
          title="Seniority"
          options={seniorityOptions}
          selected={filters.seniorities ?? []}
          onSelect={(values) => setFilters({ seniorities: values })}
          showSearch={false}
          dropdownSx={{ maxHeight: '300px' }}
        />
      </Box>
      <Flex width="100%" flexDirection="column" sx={{ gap: '8px' }} data-testid="contact-filters-company-name">
        <CompanyFilter
          sharedList={sharedList}
          useShareListFilter={!showListFilters}
          filters={filters}
          setFilters={setFilters}
        />
      </Flex>
      <Flex width="100%" flexDirection="column" sx={{ gap: '8px' }} data-testid="contact-filters-company-name">
        <FundFilterGrowth
          onSelect={(stage, value) => {
            if (stage === 'clear') {
              clearFundFilters();
              return;
            }

            let key = stage === 'current' ? 'activePositionsOrganizationStages' : 'stageTransitions';
            if (stage === 'early') {
              key = 'companyStages';
            }

            setFilters({
              [key]: value.filter((item) => item.stage === stage),
            });
          }}
          selected={[
            ...(filters?.activePositionsOrganizationStages ?? []),
            ...(filters?.stageTransitions ?? []),
            ...(filters?.companyStages ?? []),
          ]}
        />
      </Flex>
      <Box width="100%" data-testid="contact-filters-company-size">
        <MultiSelect
          title="Current company size"
          options={companySizeOptions}
          selected={filters.currentCompanySizes ?? []}
          onSelect={(values) => setFilters({ currentCompanySizes: values })}
          showSearch={false}
          dropdownFooter="Not all companies are covered in our company size data."
        />
      </Box>
      <Flex width="100%" data-testid="contact-filters-industry-tags" flexDirection="column" sx={{ gap: '8px' }}>
        <ApiFacet
          name="industry_tags"
          label="Industry"
          value={filters.industryTags}
          onChange={(value) => setFilters({ industryTags: value })}
          notFoundText="No contacts with experience in"
          orderBy="alpha"
          sharedList={sharedList}
          useInitialOpenDropDown
        />
        {!!filters.industryTags.length && (
          <OptionsFilter
            placeholder="2 or more years"
            options={[
              { label: '2 or more years', value: 2 },
              { label: '5 or more years', value: 5 },
              { label: '10 or more years', value: 10 },
            ]}
            selected={filters.industryTagsYears}
            onSelect={(values) => setFilters({ industryTagsYears: values })}
          />
        )}
      </Flex>
      <Box width="100%" data-testid="contact-filters-skills">
        <ApiFacet
          name="skills"
          label="Skills"
          value={filters.skills}
          onChange={(value) => setFilters({ skills: value })}
          notFoundText="No contacts with skill"
          sharedList={sharedList}
          useShareListFilter={!showListFilters}
        />
      </Box>
      {showListFilters && (
        <Box width="100%" data-testid="contact-filters-shared-lists-ids">
          <SharedList value={filters.sharedListIds} onChange={(value) => setFilters({ sharedListIds: value })} />
        </Box>
      )}
      {extendedMode && (
        <Box width="100%" data-testid="contact-filters-tags">
          <ApiFacet
            name="tags"
            label="Tags"
            value={filters.tags}
            onChange={(value) => setFilters({ tags: value })}
            notFoundText="No tag"
            orderBy="alpha"
            sharedList={sharedList}
            useShareListFilter={!showListFilters}
            useInitialOpenDropDown
          />
        </Box>
      )}
      <Box width="100%" data-testid="contact-filters-importer-ids">
        <ContactOfFilter value={filters.importerIds} onChange={(value) => setFilters({ importerIds: value })} />
      </Box>
      <Box width="100%" data-testid="contact-filters-years-experience">
        <YearsOfExperienceFilter
          label="Years of experience"
          name="years_experience"
          min={0}
          max={41}
          yearsOfExperience={filters.yearsOfExperience}
          onYearsOfExperienceChange={(value) => setFilters({ yearsOfExperience: value })}
        />
      </Box>
      <Box width="100%" data-testid="contact-filters-years-in-current-company">
        <YearsOfExperienceFilter
          label="Years in current company"
          name="years_in_current_company"
          min={0}
          max={41}
          yearsOfExperience={filters.yearsInCurrentCompany}
          onYearsOfExperienceChange={(value) => setFilters({ yearsInCurrentCompany: value })}
        />
      </Box>
      <Box width="100%" data-testid="contact-filters-keywords">
        <KeywordFilter selected={filters.keywords} onEnter={(value) => setFilters({ keywords: value })} />
      </Box>

      {extendedMode && (
        <>
          <Box width="100%" data-testid="contact-filters-in-portco">
            <InPortcoFilter value={filters.inPortco} onChange={(value) => setFilters({ inPortco: value })} />
          </Box>
          {isTalentNetworkEnabled && (
            <>
              <Box width="100%" data-testid="contact-filters-has-signup-info">
                <TalentNetworkSignupFilter
                  value={filters.hasSignupInfo}
                  onChange={(value) => setFilters({ hasSignupInfo: value })}
                />
              </Box>
              <FiltersPreferences isEdit={isEdit} sharedList={sharedList} filters={filters} setFilters={setFilters} />
            </>
          )}
        </>
      )}
    </Flex>
  );
};

Filters.propTypes = {
  sharedList: PropTypes.object,
  filters: PropTypes.object.isRequired,
  filterCount: PropTypes.number,
  setFilters: PropTypes.func.isRequired,
  extendedMode: PropTypes.bool,
  isTalentNetworkEnabled: PropTypes.bool,
  showListFilters: PropTypes.bool.isRequired,
  clearFundFilters: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

Filters.defaultProps = {
  filterCount: 0,
  sharedList: null,
  extendedMode: false,
  isTalentNetworkEnabled: false,
  isEdit: false,
};
