import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setPageTitle } from '../actions/pageActions';
import { trackPage } from '../helpers/analytics';

const usePageHook = (title, options = {}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (title) {
      dispatch(setPageTitle(title, options));
    }

    return () => dispatch(setPageTitle(''));
  }, [dispatch, title, options.networkName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    trackPage();
  }, []);
};

export default usePageHook;
