import { useMemo } from 'react';

import { mapCompanyFundType } from '../../../services/organizations/organizationFundTypes';

export const useFilters = (filters) => {
  const newFilters = useMemo(
    () => ({
      ...filters,
      ...(filters.yearsExperienceGteq >= 0
        ? { yearsOfExperience: { min: filters.yearsExperienceGteq, max: filters.yearsExperienceLteq } }
        : undefined),
      ...(filters.yearsInCurrentCompanyGteq >= 0
        ? { yearsInCurrentCompany: { min: filters.yearsInCurrentCompanyGteq, max: filters.yearsInCurrentCompanyLteq } }
        : undefined),
    }),
    [filters],
  );

  const getYearsOfExperienceLabel = (value, min = 0, max = 41) =>
    `${value.min <= min && !(value.max >= max) ? 'Up' : value.min} ${value.max >= max ? 'or' : 'to'} ${
      value.max >= max ? 'more' : value.max
    }`;

  const parsedFilters = useMemo(
    () =>
      Object.entries(newFilters).reduce((acc, [key, value]) => {
        if (key === 'keywords' && value.length) {
          acc.push({
            label: 'Keyword',
            value: value.join(' · '),
          });
        } else if (key === 'jobTitles' && value.length) {
          acc.push({
            label: `Job title${newFilters?.jobTitlesIncluded === 'all' ? ' (current or past)' : ''}`,
            value: value.join(' · '),
          });
        } else if (key === 'locationIds' && value.length) {
          acc.push({
            label: 'Location',
            value: value.map((location) => location.title).join(' · '),
          });
        } else if (key === 'companyStages' && value.length) {
          acc.push({
            label: 'Company funding stage',
            value: value.map(({ stage }) => mapCompanyFundType(stage)).join(' · '),
          });
        } else if (key === 'industryTags' && value.length) {
          acc.push({
            label: 'Industry',
            value: value.map(({ industryTag }) => industryTag).join(' · '),
          });
        } else if (key === 'yearsOfExperience') {
          acc.push({
            label: 'Years of experience',
            value: getYearsOfExperienceLabel(value),
          });
        } else if (key === 'yearsInCurrentCompany') {
          acc.push({
            label: 'Years in current company',
            value: getYearsOfExperienceLabel(value),
          });
        }

        return acc;
      }, []),
    [newFilters],
  );

  return {
    filters: parsedFilters,
  };
};
