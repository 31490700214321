import React, { useMemo } from 'react';
import { AsyncButton, Button, Modal } from '@getro/rombo';
import { Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import UserSchema from '../../../schemas/user';
import NetworkSchema from '../../../schemas/network';

const RemoveAdminModal = ({ user, currentUser, network, isSubmitting, modalOpen, onCancel, onDeleteAdmin }) => {
  const userName = useMemo(() => {
    const { firstName, lastName, email } = user || {};

    if (!user) return '';
    if (!firstName && !lastName) return email;

    return `${firstName ?? ''} ${lastName ?? ''}`.trim();
  }, [user]);
  const title = useMemo(() => {
    if (user?.id === currentUser?.id) {
      return 'Are you sure you want to leave the network?';
    }

    return `Remove ${userName}`;
  }, [currentUser, user, userName]);

  const cancelLabel = useMemo(() => {
    if (user?.id === currentUser?.id) {
      return 'No, stay part of the network';
    }

    return 'Cancel';
  }, [currentUser, user]);

  const proceedLabel = useMemo(() => {
    if (user?.id === currentUser?.id) {
      return 'Yes, leave network';
    }

    return 'Remove';
  }, [currentUser, user]);

  return (
    <Modal
      title={title}
      isOpen={modalOpen}
      onCancel={onCancel}
      actions={
        <Flex justifyContent={['flex-end']}>
          <Button mr={[2]} onClick={onCancel} variant="tertiary">
            {cancelLabel}
          </Button>
          <AsyncButton
            ml={[2]}
            loading={isSubmitting}
            onClick={() => {
              onDeleteAdmin(user);
            }}
            variant="danger"
          >
            {proceedLabel}
          </AsyncButton>
        </Flex>
      }
      data-testid="remove-admin"
    >
      {user?.id !== currentUser?.id && (
        <Text width={1} mb={2}>
          {userName} will no longer have access to the {network.name} network.
        </Text>
      )}

      {user?.id === currentUser?.id && (
        <>
          <Text width={1} mb={2}>
            We&apos;re sorry you&apos;re thinking of leaving the {network.name} network.
          </Text>
          <Text width={1} my={2}>
            By leaving, you will no longer have access to the network and will stop receiving network notifications.
          </Text>
          <Text width={1} my={2}>
            Another admin can add you to the network if you change your mind.
          </Text>
        </>
      )}
    </Modal>
  );
};

RemoveAdminModal.propTypes = {
  user: UserSchema,
  currentUser: UserSchema.isRequired,
  network: NetworkSchema.isRequired,
  modalOpen: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onDeleteAdmin: PropTypes.func.isRequired,
};

RemoveAdminModal.defaultProps = {
  user: null,
  modalOpen: false,
  isSubmitting: false,
};

export default RemoveAdminModal;
