import React from 'react';
import { Select } from '@getro/rombo';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

export const ContactsSelector = ({ options, onChange, value }) => {
  const onCurrentChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <Box width="172px" sx={{ position: 'relative', zIndex: 3 }}>
      <Select size="small" options={options} value={value} onChange={onCurrentChange} />
    </Box>
  );
};

ContactsSelector.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};
