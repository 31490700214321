import { createEntityAdapter } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import isEqual from 'lodash.isequal';
import queryString from 'query-string';

import { parseFiltersData } from 'services/contacts/filters';
import { baseQuery } from './queries';
import { mapContactsV2ToV1 } from './contactsV2/formatter';

const itemsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const itemsSelector = itemsAdapter.getSelectors();

export const sharedCompanyListV2Api = createApi({
  reducerPath: 'sharedCompanyListV2Api',
  baseQuery: (args, api, extraOptions) => baseQuery({ ...args, version: 'v2' }, api, extraOptions),
  tagTypes: ['shared-company-list-contacts'],
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: ({ networkId, ...rest }) => ({
        url: `/collections/${networkId}/contacts`,
        params: rest,
      }),
      transformResponse: (response, _, arg) => ({
        meta: response.meta,
        page: arg.page,
        ...itemsAdapter.addMany(itemsAdapter.getInitialState(), mapContactsV2ToV1(response)),
      }),
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const filters = queryString.stringify(parseFiltersData(queryArgs?.filters || {}, true), {
          arrayFormat: 'bracket',
        });

        return queryArgs?.listId ? { listId: queryArgs?.listId?.toString() } : `${endpointName}(${filters})`;
      },
      merge: (currentState, incomingState) => {
        if (incomingState.meta.queryTotal === 0) {
          itemsAdapter.removeAll(currentState);
        } else if (currentState.page < incomingState.page) {
          itemsAdapter.setMany(currentState, itemsSelector.selectAll(incomingState));
        } else {
          itemsAdapter.removeAll(currentState);
          itemsAdapter.addMany(currentState, itemsSelector.selectAll(incomingState));
        }

        Object.assign(currentState, {
          meta: incomingState.meta,
        });
      },
      providesTags: ['shared-company-list-contacts'],
    }),
  }),
});

export const { useGetContactsQuery, useLazyGetContactsQuery } = sharedCompanyListV2Api;
