import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { NavLink, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { RefreshCw } from 'lucide-react';
import { Button, Tooltip } from '@getro/rombo';

import { MainLayoutHeader } from 'components/atoms/mainLayoutHeader';
import MainLayout from '../../components/layout/Main';
import { CreateList } from '../list/createList';
import { ImportButton } from './importButton';
import { useContactsExtended } from './hooks/useContactsExtended';
import { ExtendedContact } from './extendedContact';

export const ContactsExtended = () => {
  const {
    listId,
    queryParams,
    selectedRows,
    setSelectedRows,
    data,
    isLoading,
    isFetching,
    filtersCount,
    meta,
    showAllContactsEmptyState,
    onLoadMore,
    onChangeSelectedContact,
    onSetFilters,
    activeNetwork,
    isLoadingAI,
  } = useContactsExtended({ allList: true });
  const history = useHistory();
  return (
    <MainLayout
      containerProps={{
        pb: 0,
      }}
    >
      <MainLayoutHeader
        title="Contacts"
        actions={[
          activeNetwork?.linkedinSyncStatus === 'not_completed' ? (
            <Tooltip
              position="top"
              sx={{
                mb: '8px',
              }}
              content={
                <Box as="p" color="neutral.0" fontSize="14px" lineHeight="1.4" fontWeight="500">
                  Connect your LinkedIn account to sync all your current and future connections.
                </Box>
              }
              trigger={
                <Button
                  as={NavLink}
                  size="small"
                  variant="secondary"
                  icon={RefreshCw}
                  to={`/networks/${activeNetwork.slug}/linkedin/sync`}
                >
                  Connect LinkedIn
                </Button>
              }
            />
          ) : null,
          <ImportButton importProgress={meta?.importProgress || 0} />,
        ]}
      />
      <Flex flex="1" flexDirection="column" width="100%" maxWidth="1600px" mx="auto" px={['16px', '16px', 0]}>
        <ExtendedContact
          onSetFilters={onSetFilters}
          queryParams={queryParams}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onChangeSelectedContact={onChangeSelectedContact}
          onLoadMore={onLoadMore}
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          meta={meta}
          filtersCount={filtersCount}
          activeNetwork={activeNetwork}
          showAllContactsEmptyState={showAllContactsEmptyState}
          listId={listId}
          list={{ id: listId }}
          emptyStateUrl={`/networks/${activeNetwork.slug}/contacts`}
          showListFilters
          page="all_contacts"
          showFiltersBottomBar
          isLoadingAI={isLoadingAI}
          filtersTitle="Filters"
        />
        <Switch>
          <Route exact path={`/networks/${activeNetwork.slug}/contacts/lists/create-list`}>
            <CreateList
              goBack={() => {
                history.push(`/networks/${activeNetwork.slug}/contacts${history.location.search}`);
              }}
            />
          </Route>
          <Route
            path={`/networks/${activeNetwork.slug}/contacts/linkedin/:action`}
            render={({ match }) => <Redirect to={`/networks/${activeNetwork.slug}/linkedin/${match.params.action}`} />}
          />
        </Switch>
      </Flex>
    </MainLayout>
  );
};
