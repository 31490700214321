import React from 'react';
import { Button } from '@getro/rombo';
import { Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import UserAndCompanyProfile from './UserAndCompanyProfile';

export const LoggedInAlly = ({ network, user, onShareContacts, isShareContactsLoading }) => (
  <Flex sx={{ flexDirection: 'column', gap: '40px', flex: 1, maxWidth: '400px', width: '100%', m: '0px auto' }}>
    <Flex sx={{ flexDirection: 'column', gap: '16px', width: '100%' }}>
      {network && user && <UserAndCompanyProfile network={network} user={user} />}

      <Text sx={{ fontWeight: 600, fontSize: '22px', color: 'text.dark', lineHeight: '1.4' }}>
        Start collaborating with {network.name}
      </Text>
      <Text sx={{ fontSize: '16px', color: 'text.main', lineHeight: '1.4' }}>
        Help {network.name} find high-impact introductions from your contacts by sharing them on Getro.
      </Text>
    </Flex>
    <Button
      onClick={onShareContacts}
      loading={isShareContactsLoading}
      size="medium"
      sx={{ justifyContent: 'center', width: '100%' }}
    >
      Share my contacts
    </Button>
  </Flex>
);

LoggedInAlly.propTypes = {
  network: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onShareContacts: PropTypes.func.isRequired,
  isShareContactsLoading: PropTypes.bool.isRequired,
};
