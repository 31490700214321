export const getCompanyColumns = (columnsToHide) =>
  [
    {
      Header: 'Name',
      accessor: 'name',
      width: '200px',
    },
    {
      Header: 'Number of contacts',
      tooltip: 'Number of contacts at company',
      accessor: 'contactsCount',
      width: '120px',
    },
    {
      Header: 'Contact name',
      accessor: 'contactName',
      width: '240px',
      disableSortBy: true,
      disablePadding: true,
    },
    {
      Header: 'Contact job title',
      accessor: 'jobTitle',
      width: '240px',
      disableSortBy: true,
      disablePadding: true,
    },
    {
      Header: 'Contact seniority',
      accessor: 'seniority',
      width: '200px',
      disableSortBy: true,
      disablePadding: true,
    },
    {
      Header: 'Contact years in company',
      tooltip: 'Contact years in company',
      accessor: 'yearsInCompany',
      width: '120px',
      disableSortBy: true,
      disablePadding: true,
    },
    {
      Header: 'Contact of',
      accessor: 'contactOf',
      width: '240px',
      disableSortBy: true,
      disablePadding: true,
    },
    {
      Header: 'Intros offered by',
      accessor: 'intros',
      width: '240px',
      disableSortBy: true,
      disablePadding: true,
    },
    {
      Header: 'Domain',
      accessor: 'domain',
      width: '120px',
      disableSortBy: true,
    },
    {
      Header: 'Location',
      accessor: 'location',
      width: '240px',
      disableSortBy: true,
    },
    {
      Header: 'Industry',
      accessor: 'industry',
      width: '240px',
      disableSortBy: true,
    },
    {
      Header: 'Size',
      accessor: 'approxEmployees',
      width: '120px',
    },
    {
      Header: 'Funding stage',
      accessor: 'stage',
      width: '160px',
    },
    {
      Header: 'Funding year',
      tooltip: 'Funding year',
      accessor: 'yearFounded',
      width: '136px',
      disableSortBy: true,
    },
    {
      Header: 'Lists',
      accessor: 'lists',
      width: '240px',
      disableSortBy: true,
    },
  ].filter((column) => !columnsToHide.has(column.accessor));
