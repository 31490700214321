import React from 'react';
import { ProfilePicture } from '@getro/rombo';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';

const UserAndCompanyProfile = ({ network }) => (
  <Box sx={{ position: 'relative', width: '56px', height: '72px' }}>
    <Box sx={{ width: '56px', height: '56px' }}>
      <ProfilePicture variant="square" name={network.name} imageUrl={network.logoUrl} />
    </Box>
  </Box>
);

UserAndCompanyProfile.propTypes = {
  network: PropTypes.object.isRequired,
};

export default UserAndCompanyProfile;
