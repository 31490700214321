import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetMatchListByIdQuery } from 'services/matches';
import * as Yup from 'yup';
import { getCompanyFundTypeFormOptions } from 'services/organizations/organizationFundTypes';
import { getCompanySize } from 'helpers/organizations';
import strings from 'strings';
import { useSearchGoogleLocations } from 'pages/list/hooks/useProjectSearchGoogleLocations';
import { useOrganizationSize } from 'pages/list/hooks/useOrganizationSize';
import { useGetJobQuery } from 'services/jobsV2';
import merge from 'lodash.merge';

export const usePrivateList = ({ onMatchSelected, updatedMatch, matchId, isSettings, noJobBoard }) => {
  const [isCompanyFromPortfolio, setIsCompanyFromPortfolio] = useState(true);
  const locations = useSearchGoogleLocations({ excludeGlobals: true });
  const { data: job, isLoading: isLoadingJob } = useGetJobQuery(
    {
      jobId: updatedMatch?.job?.id,
    },
    {
      skip: !isSettings || matchId || !updatedMatch?.job?.id,
    },
  );

  const params = useParams();
  const isEdit = Boolean(params.listId && matchId);
  const { data, isFetching, isLoading } = useGetMatchListByIdQuery(
    {
      matchListId: matchId,
    },
    {
      skip: !isEdit || !matchId,
    },
  );

  const matchList = useMemo(() => {
    const match = merge({}, { ...updatedMatch, job: null }, data?.matchList);

    if (job) {
      match.job = job;
    }

    return match;
  }, [data, updatedMatch, job]);

  const { options } = useOrganizationSize();

  const employmentTypes = useMemo(
    () => Object.keys(strings.profile.employment).map((k) => ({ value: k, label: strings.profile.employment[k] })),
    [],
  );

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().required(),
        remote: Yup.bool().required(),
        locations: Yup.array()
          .when('remote', {
            is: true,
            then: (schema) => schema.min(0),
            otherwise: (schema) => schema.min(1),
          })
          .min(1),
      }),
    [],
  );

  const formatPayload = (values) => ({
    jobTitle: values.title,
    jobDescription: values.description,
    locationIds: values.locations.map((l) => l.value),
    remotePosition: values.remote,
    ...(values?.company?.value && isCompanyFromPortfolio ? { organizationId: values.company.value } : undefined),
    ...(values?.organizationName ? { organizationName: values.organizationName } : undefined),
    ...(values?.organizationSize?.value ? { organizationSize: values.organizationSize.value } : undefined),
    ...(values?.organizationStage?.value ? { organizationStage: values.organizationStage.value } : undefined),
    ...(values?.organizationIndustryTagIds?.length
      ? { organizationIndustryTagIds: values.organizationIndustryTagIds.map((s) => s.value) }
      : undefined),
  });

  const initialValues = useMemo(() => {
    if (!isLoading && !isLoadingJob && matchList?.job) {
      const companySize = !matchList.organization.headCount ? null : getCompanySize(matchList.organization.headCount);

      return {
        title: matchList.job.title,
        description: matchList.job.description ?? '',
        remote: Object.prototype.hasOwnProperty.call(matchList.job ?? {}, 'remote')
          ? matchList.job.remote
          : !!matchList.job.locations.find(({ placeId }) => placeId === 'remote'),
        locations:
          matchList.job.locations
            .filter(({ placeId }) => placeId !== 'remote')
            .map(({ placeId, name, value, label }) => ({ value: placeId ?? value, label: name ?? label })) ?? [],
        company: matchList.organization
          ? {
              showAvatar: true,
              avatarUrl: matchList.organization.logoUrl,
              icon: matchList.organization.logoUrl,
              value: matchList.organization.id,
              label: matchList.organization.name,
              title: matchList.organization.name,
            }
          : null,
        organizationName: matchList.organization.name,
        organizationSize: options.find((s) => s.value === companySize),
        organizationStage: getCompanyFundTypeFormOptions().find((s) => s.value === matchList.organization.stage),
        organizationIndustryTagIds:
          (matchList?.organization?.industryTags?.length > 0
            ? matchList?.organization.industryTags
            : matchList?.job?.organizationIndustryTagIds
          )?.map(({ id, description }) => ({
            value: id,
            label: description,
          })) ?? [],
      };
    }

    return {
      company: null,
      organizationName: '',
      locations: [],
      description: '',
      remote: false,
      organizationIndustryTagIds: [],

      title: '',
    };
  }, [isLoading, isLoadingJob, matchList, options]);

  const fromPortfolio = noJobBoard ? false : isCompanyFromPortfolio;

  const onSubmit = (value) => {
    onMatchSelected({
      matchType: 'private',
      payload: formatPayload(value),
      organization: fromPortfolio
        ? {
            id: value.company.value,
            name: value.company.label,
            logoUrl: value.company.avatarUrl,
          }
        : {
            id: null,
            name: value.organizationName,
            logoUrl: null,
          },
      isCompanyFromPortfolio: fromPortfolio,
      job: {
        id: matchList?.job?.id,
        title: value.title,
        description: value.description,
        locations: value.locations,
        remote: value.remote,
        organizationIndustryTagIds: value?.organizationIndustryTagIds ?? [],
      },
    });
  };

  useEffect(() => {
    if (isEdit && matchList && !matchList?.organization?.id) {
      setIsCompanyFromPortfolio(false);
    }
  }, [matchList, isEdit]);

  return {
    employmentTypes,
    locations,
    formSchema: fromPortfolio
      ? formSchema.concat(
          Yup.object().shape({
            company: Yup.object().required(),
          }),
        )
      : formSchema,
    isCompanyFromPortfolio: fromPortfolio,
    setIsCompanyFromPortfolio,
    match: matchList,
    initialValues,
    loadingMatch: isLoading || isFetching || isLoadingJob,
    onSubmit,
    isEdit,
  };
};
