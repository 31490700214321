import { useEffect, useMemo, useState } from 'react';
import { useGetContactsQuery } from '../../../services/sharedCompanyListV2';

export const useFetchContacts = ({ contactsSource, sharedList, user }) => {
  const collections = useMemo(() => [...user?.managingCollections, ...user?.allyingCollections], [user]);
  const isMyCollection = useMemo(
    () => collections.some((collection) => collection.id === sharedList?.collection?.id),
    [collections, sharedList?.collection?.id],
  );

  const myContactsDefaultParams = {
    page: 1,
    networkId: isMyCollection ? sharedList?.collection?.id : collections[0]?.id,
    contextualizedToSharedListId: sharedList.id,
    onlyMyContacts: true,
  };
  const [queryParams, setQueryParams] = useState(myContactsDefaultParams);
  const { data = {}, isLoading, isFetching } = useGetContactsQuery(queryParams);

  useEffect(() => {
    if (contactsSource === 'my-contacts') {
      setQueryParams(myContactsDefaultParams);
    } else {
      setQueryParams({
        networkId: contactsSource,
        contextualizedToSharedListId: sharedList.id,
        page: 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsSource]);

  return {
    queryParams,
    setQueryParams,
    data,
    isLoading,
    isFetching,
  };
};
