import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';

import SideModal from '../../components/atoms/sideModal';
import { activeNetworkSelector } from '../../redux/selectors';
import { EditCompanyList } from './components/editCompanyList';
import { AddCompanies } from './components/addCompanies';
import { AskForIntros } from './components/askForIntros';

export const ListSettings = ({ sharedList }) => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const { path, url } = useRouteMatch();
  const { search } = useLocation();
  const history = useHistory();

  return (
    <SideModal
      width="680px"
      onClose={() => history.push(`/networks/${activeNetwork.slug}/list/${sharedList.id}/contacts${search}`)}
    >
      <Switch>
        <Route exact path={path}>
          <EditCompanyList routePath={url} sharedList={sharedList} />
        </Route>
        <Route exact path={`${path}/add-companies`}>
          <AddCompanies activeNetwork={activeNetwork} sharedList={sharedList} />
        </Route>
        <Route exact path={`${path}/ask-for-intros`}>
          <AskForIntros activeNetwork={activeNetwork} sharedList={sharedList} />
        </Route>
      </Switch>
    </SideModal>
  );
};

ListSettings.propTypes = {
  sharedList: PropTypes.object,
};

ListSettings.defaultProps = {
  sharedList: {},
};
