import { useEffect, useState, useMemo, useCallback } from 'react';
import { Check, EyeOff, Lock, RefreshCw } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setUser } from 'actions/userActions';
import { trackEvent } from '../../helpers/analytics';
import { getItem } from '../../helpers/store';
import { activeNetworkSelector, userSelector } from '../../redux/selectors';
import { EXTENSION_ID, checkExtensionAvailability } from '../../services/chromeExtension';

export const EXTENSION_MESSAGE_TYPES = {
  IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR: 'IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR',
  SCRAPER_VOYAGER_IMPORT_SUCCESS: 'SCRAPER_VOYAGER_IMPORT_SUCCESS',
};

export const useLinkedInSync = () => {
  const { networkSlug } = useParams();

  const user = useSelector(userSelector);
  let activeNetwork = useSelector(activeNetworkSelector);
  const isAlly = useMemo(() => new URLSearchParams(window.location.search).get('ally') === 'true', []);

  if (isAlly || user.isOnlyAlly) {
    activeNetwork = user.allyingCollections.find((n) => n.slug === networkSlug);
  }

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingVoyager, setIsLoadingVoyager] = useState(false);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const [responseType, setResponseType] = useState();
  const { action = 'sync' } = useParams();
  const dispatch = useDispatch();
  const items = [
    {
      icon: RefreshCw,
      title: 'Automatic',
      description: 'Existing and new connections will be added to Getro.',
    },
    {
      icon: EyeOff,
      title: 'Private',
      description: 'Your connections are only shared with those networks you choose.',
    },
    {
      icon: Lock,
      title: 'Secure',
      description: 'Getro does not have access to or store your LinkedIn password.',
    },
  ];

  const syncItems = [
    {
      icon: Check,
      title: 'Extension installed',
      description: 'You’re all set to sync your contacts and explore all features.',
    },
    {
      icon: Check,
      title: 'Connections fetched',
      description: 'All your LinkedIn connections are now added to Getro.',
    },
    {
      icon: RefreshCw,
      title: 'Contacts are being enriched',
      description:
        'They will appear as they’re ready. This may take up to 3 hours; we’ll email you once it’s complete.',
    },
  ];

  const isLoadingOrLoadingVoyager = isLoading || isLoadingVoyager;
  const syncCompleted = !isLoadingOrLoadingVoyager && user?.linkedinSync?.syncStatus === 'active';
  const synContacts = !isLoadingOrLoadingVoyager && user?.linkedinSync?.syncStatus !== 'active';

  const showSyncImage = !(user?.linkedinSync?.syncStatus === 'active' && isAlly);

  const allySyncItems = [
    { id: 1, title: 'Account', active: false, completed: true },
    { id: 2, title: 'Extension', active: user?.linkedinSync?.syncStatus !== 'active', completed: syncCompleted },
    {
      id: 3,
      title: 'Connections',
      active: false,
      completed: user?.linkedinSync?.syncStatus === 'active',
    },
  ];

  const onConnect = useCallback(() => {
    // eslint-disable-next-line no-undef
    if (chrome?.runtime?.sendMessage) {
      setIsLoadingVoyager(true);
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(
        EXTENSION_ID,
        {
          type: 'SCRAPER_VOYAGER_INITIALIZE',
          auth: getItem('authToken'),
          user: {
            ...user,
            fullName: `${user.firstName} ${user.lastName}`,
          },
          networkId: activeNetwork?.id,
        },
        (response) => {
          setResponseType(response?.type);
          setIsLoadingVoyager(false);

          if (response?.type === EXTENSION_MESSAGE_TYPES.SCRAPER_VOYAGER_IMPORT_SUCCESS) {
            dispatch(
              setUser({
                ...user,
                linkedinSync: {
                  syncStatus: 'active',
                  lastSyncedAt: new Date().toISOString(),
                  contactsSynced: 0,
                  contactsImportInProgress: true,
                },
              }),
            );

            if (isAlly) {
              trackEvent('ally_onboarding:completed', {
                collection_id: activeNetwork?.id,
              });
            }
          }
        },
      );
    }
  }, [user, activeNetwork, dispatch, isAlly]);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        await checkExtensionAvailability();
        clearInterval(interval);
        setIsExtensionInstalled(true);
        onConnect();
      } catch {
        // Do nothing
      } finally {
        if (isLoading) {
          setIsLoading(false);
        }
      }

      return () => clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const eventStart = `linkedin_sync:${action === 'sync' ? 'connect_flow' : 'reconnect'}:start`;
    const eventEnd = `linkedin_sync:${action === 'sync' ? 'connect_flow' : 'reconnect'}:success`;

    trackEvent(user?.linkedinSync?.syncStatus === 'active' ? eventEnd : eventStart, {
      user: user.id,
    });
  }, [action, user]);

  return {
    isLoading,
    isLoadingVoyager,
    isExtensionInstalled,
    activeNetwork,
    action,
    responseType,
    featureList: items,
    onConnect,
    syncItems,
    isAlly,
    isLoadingOrLoadingVoyager,
    syncCompleted,
    synContacts,
    showSyncImage,
    allySyncItems,
    user,
  };
};
