import { FocusLayout } from 'components/layout/focus';
import PropTypes from 'prop-types';
import React from 'react';

export const ConnectSyncLayout = ({ children, activeNetwork, action, isOnlyAlly }) => (
  <FocusLayout
    title="Connect your LinkedIn"
    backUrl={!isOnlyAlly ? `/networks/${activeNetwork?.slug}/contacts` : `/settings/my-networks`}
    headerSx={{ opacity: action === 'sync' ? 1 : 0 }}
  >
    {children}
  </FocusLayout>
);

ConnectSyncLayout.propTypes = {
  children: PropTypes.node.isRequired,
  activeNetwork: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  isOnlyAlly: PropTypes.bool.isRequired,
};
