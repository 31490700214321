import React, { useCallback, useEffect, useState } from 'react';
import { Button, Message } from '@getro/rombo';
import { ExternalLink, EyeOff, Lock, RefreshCw } from 'lucide-react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Flex, Text, Image } from 'rebass/styled-components';
import { useHistory } from 'react-router-dom';

import { setUser } from '../../../../actions/userActions';
import ContactsLinkedInSyncImage from '../../../../assets/contacts-linkedin-sync.png';
import { trackEvent } from '../../../../helpers/analytics';
import { openChat } from '../../../../helpers/supportChat';
import { getItem } from '../../../../helpers/store';
import { EXTENSION_ID, EXTENSION_URL, checkExtensionAvailability } from '../../../../services/chromeExtension';

export const EXTENSION_MESSAGE_TYPES = {
  IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR: 'IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR',
  SCRAPER_VOYAGER_IMPORT_SUCCESS: 'SCRAPER_VOYAGER_IMPORT_SUCCESS',
};

export const InstallExtension = ({ user, sharedList, setIsUserOnboarded }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseType, setResponseType] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const items = [
    {
      icon: RefreshCw,
      title: 'Automatic',
      description: 'Existing and new connections will be added to Getro.',
    },
    {
      icon: EyeOff,
      title: 'Private',
      description: 'Your connections are only shared with those networks you choose.',
    },
    {
      icon: Lock,
      title: 'Secure',
      description: 'Getro does not have access to or store your LinkedIn password.',
    },
  ];

  const onConnect = useCallback(
    () =>
      new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        if (chrome?.runtime?.sendMessage) {
          // eslint-disable-next-line no-undef
          chrome.runtime.sendMessage(
            EXTENSION_ID,
            {
              type: 'SCRAPER_VOYAGER_INITIALIZE',
              auth: getItem('authToken'),
              sharedListId: sharedList.id,
              user: {
                ...user,
                fullName: `${user.firstName} ${user.lastName}`,
              },
              networkId: sharedList.collection?.id,
            },
            (response) => {
              setResponseType(response?.type);

              if (response?.type === EXTENSION_MESSAGE_TYPES.SCRAPER_VOYAGER_IMPORT_SUCCESS) {
                resolve({ type: EXTENSION_MESSAGE_TYPES.SCRAPER_VOYAGER_IMPORT_SUCCESS });
              } else {
                reject(new Error('Failed to import contacts'));
              }
            },
          );
        } else {
          reject(new Error('Chrome extension not installed'));
        }
      }),
    [sharedList, user],
  );

  useEffect(() => {
    let timeoutId;

    const checkExtension = async () => {
      try {
        await checkExtensionAvailability();
        setIsLoading(true);
        const { type } = await onConnect();

        clearTimeout(timeoutId);

        if (type === EXTENSION_MESSAGE_TYPES.SCRAPER_VOYAGER_IMPORT_SUCCESS) {
          dispatch(
            setUser({
              linkedinSync: {
                syncStatus: 'active',
                lastSyncedAt: new Date().toISOString(),
                contactsSynced: 0,
                contactsImportInProgress: true,
              },
            }),
          );

          trackEvent('shared_list:enabled_linkedin_sync', {
            collectionId: sharedList.collection?.id,
            listSlug: sharedList.slug,
            objectType: 'companies',
          });
          history.push('.');
          setIsUserOnboarded(true);
        }

        setIsLoading(false);
      } catch {
        timeoutId = setTimeout(checkExtension, 1000);
      }
    };

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(checkExtension, 1000);

    return () => clearTimeout(timeoutId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex bg="neutral.0" sx={{ gap: '24px', boxShadow: 'medium', borderRadius: 'default', overflow: 'hidden' }}>
      <Box p="24px" width="600px">
        <Box maxWidth="440px" mx="auto">
          <Box>
            <Image mt="24px" src={ContactsLinkedInSyncImage} alt="LinkedIn Sync" />
            <Text mt="24px" fontSize="22px" fontWeight="semibold" color="text.dark">
              Add your contacts and find who you know at these companies
            </Text>
            <Text mt="16px" fontSize="16px" lineHeight="20px">
              Set up once – unleash unlimited warm introductions.
            </Text>
          </Box>
          <Flex as="ul" pl="0" py="32px" flexDirection="column" sx={{ gap: '16px', listStyle: 'none' }}>
            {items.map(({ icon, title, description }) => (
              <Flex key={title} as="li" alignItems="flex-start" sx={{ gap: '16px' }}>
                <Flex flex="0 0 auto" alignItems="center" color="purple.300" sx={{ position: 'relative', top: '3px' }}>
                  <Box as={icon} width="20px" height="20px" aria-hidden="true" />
                </Flex>
                <Box flexGrow="1">
                  <Text fontSize="14px" fontWeight="medium" color="text.dark">
                    {title}
                  </Text>
                  <Text fontSize="14px">{description}</Text>
                </Box>
              </Flex>
            ))}
          </Flex>
          <Button
            iconGap="8px"
            onClick={() => window.open(EXTENSION_URL)}
            iconPosition="right"
            width="100%"
            justifyContent="center"
            icon={ExternalLink}
            loading={isLoading}
          >
            Install extension and add your contacts
          </Button>
          {responseType === EXTENSION_MESSAGE_TYPES.IMPORT_CONTACTS_LINKEDIN_AUTH_ERROR && (
            <Box data-testid="linkedin-cookies-warning" pt="40px" sx={{ '& > div': { alignItems: 'center' } }}>
              <Message
                type="warning"
                content={
                  <Box fontSize="14px" lineHeight="1.4">
                    <Text as="p" fontWeight="500" mb="8px">
                      Make sure you’re logged in to LinkedIn
                    </Text>
                    <Text as="p">
                      Please go to{' '}
                      <a href="https://linkedin.com" target="__blank">
                        www.linkedin.com
                      </a>
                      , log in, and then try again.
                    </Text>
                  </Box>
                }
              />
            </Box>
          )}
          <Text mt="24px" fontSize="14px" fontWeight="400" lineHeight="1.4" color="text.subtle" textAlign="center">
            Come back to this tab after installing it.{' '}
            <Text
              display="inline-block"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => openChat(null, { name: 'Support' })}
            >
              Need help?
            </Text>
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

InstallExtension.propTypes = {
  user: PropTypes.object,
  sharedList: PropTypes.object,
  setIsUserOnboarded: PropTypes.func.isRequired,
};

InstallExtension.defaultProps = {
  sharedList: {},
  user: {},
};
