import React from 'react';
import { Button } from '@getro/rombo';
import { Plus } from 'lucide-react';
import { Box } from 'rebass/styled-components';
import { useHistory } from 'react-router';
import pluralize from 'pluralize';
import addCompanyRoutes from 'router/addCompanyRoutes';
import NetworkSchema from 'schemas/network';

import CompanyNotification from 'components/atoms/CompanyNotification';
import { MainLayoutHeader } from '../../components/atoms/mainLayoutHeader';
import { useSuggestedCompanies } from './hooks/useSuggestedCompanies';
import { useActiveCompanies } from './hooks/useActiveCompanies';
import CompaniesNav from './Companies/CompaniesNav';
import ActiveCompanies from './Companies/ActiveCompanies';
import SuggestedCompanies from './Companies/SuggestedCompanies';
import { useSecondaryActions } from './hooks/useSecondaryActions';

export const Companies = ({ network }) => {
  const { push } = useHistory();
  const { showBanner, isSuggestedTurnedOn, ...suggested } = useSuggestedCompanies();
  const { cleanFilters: filters, ...activeCompanies } = useActiveCompanies();
  const secondaryActions = useSecondaryActions({
    filters,
    meta: activeCompanies.meta,
    isLoading: activeCompanies.loading,
  });

  return (
    <>
      <MainLayoutHeader
        title="Job board companies"
        actions={[
          network.isManager ? (
            <Button
              size="small"
              variant="accent"
              icon={Plus}
              onClick={() => push(`.${addCompanyRoutes.path}`)}
              flex="0 0 auto"
            >
              Add company
            </Button>
          ) : null,
        ]}
      />
      <Box pt="24px" mx="auto" width="100%" maxWidth="1600px">
        {showBanner && suggested.total > 0 && isSuggestedTurnedOn && (
          <CompanyNotification
            onClick={suggested.onClickBanner}
            onClose={suggested.onCloseBanner}
            show={suggested.showBanner}
            title={`${suggested.total} new portfolio ${pluralize('company', suggested.total)} ${pluralize(
              'is',
              suggested.total,
            )} ready to be added`}
            description="Add your last investments to your job board to support them hiring."
            buttonText="See suggested companies"
          />
        )}
        {isSuggestedTurnedOn && (
          <CompaniesNav
            suggestedCount={suggested.total}
            isSuggested={suggested.isSuggested}
            navigate={(path) => {
              if (path === 'suggested') {
                suggested.navigate();
                return;
              }

              activeCompanies.navigate();
            }}
          />
        )}
        {suggested.isSuggested ? (
          <SuggestedCompanies {...suggested} />
        ) : (
          <ActiveCompanies secondaryActions={secondaryActions} {...activeCompanies} />
        )}
      </Box>
    </>
  );
};

Companies.propTypes = {
  network: NetworkSchema.isRequired,
};

export default Companies;
