import React from 'react';
import { Message } from '@getro/rombo';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { useContactsExtended } from 'pages/contactsExtended/hooks/useContactsExtended';
import { ExtendedContact } from 'pages/contactsExtended/extendedContact';
import { ListContactsEmptyState } from './listContactsEmptyState';
import usePageTitle from '../../hooks/usePageTitle';
import { CreateList } from '../list/createList';

export const ContactList = ({ isJobList, sharedList }) => {
  const {
    listId,
    queryParams,
    selectedRows,
    setSelectedRows,
    data,
    isLoading,
    isFetching,
    filtersCount,
    meta,
    showAllContactsEmptyState,
    onLoadMore,
    onChangeSelectedContact,
    onSetFilters,
    activeNetwork,
  } = useContactsExtended({ allList: false });
  const { name, smartOptions, refreshInProgress } = sharedList;
  const autoUpdate = smartOptions?.includes('auto_update');
  const history = useHistory();
  usePageTitle(name);

  return (
    <>
      {refreshInProgress && data?.ids?.length > 0 && (
        <Message mb="32px">Auto-update in progress, come back soon to see updates (it may take a few minutes).</Message>
      )}
      <ExtendedContact
        onSetFilters={onSetFilters}
        queryParams={queryParams}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onChangeSelectedContact={onChangeSelectedContact}
        onLoadMore={onLoadMore}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        meta={meta}
        filtersCount={filtersCount}
        activeNetwork={activeNetwork}
        showAllContactsEmptyState={showAllContactsEmptyState}
        listId={listId}
        emptyStateUrl={`/networks/${activeNetwork.slug}/list/${listId}/add-contacts`}
        emptyState={
          <ListContactsEmptyState
            isJobList={isJobList}
            listId={listId}
            autoUpdate={autoUpdate}
            refreshInProgress={refreshInProgress}
          />
        }
        showListFilters={false}
        deleteContactFromList
        list={sharedList}
        filtersTitle="Filter list"
        bulkActions={['add-to-list', 'add-tag', 'export', 'delete', 'clear-selection']}
        page="list"
      />
      <Route exact path={`/networks/${activeNetwork.slug}/list/:listId/contacts/lists/settings`}>
        <CreateList
          goBack={() => {
            history.push(`/networks/${activeNetwork.slug}/list/${listId}/contacts${history.location.search}`);
          }}
        />
      </Route>
    </>
  );
};

ContactList.propTypes = {
  isJobList: PropTypes.bool.isRequired,
  sharedList: PropTypes.object,
};

ContactList.defaultProps = {
  sharedList: {},
};
