import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import { Logo } from '@getro/rombo';
import UserMenu from 'components/molecules/UserMenu';
import { LoggedInAlly } from './loggedInAlly';
import HomeNetwork from '../../../assets/home-network.svg';

const AllySignupLayout = ({ children, isLoggedIn, network, user, steps, onShareContacts, isShareContactsLoading }) => (
  <Flex justifyContent="center" alignItems="center" height="100vh">
    <Box sx={{ position: 'absolute', top: '24px', left: '24px' }}>
      <Logo color="primary" width="96px" />
    </Box>
    <Box sx={{ position: 'absolute', top: '24px', right: '24px' }}>
      <UserMenu onlyLogout />
    </Box>
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '160px',
        flex: 1,
        height: '100%',
        pt: '120px',
      }}
    >
      {!isLoggedIn && children}
      {isLoggedIn && (
        <LoggedInAlly
          network={network}
          user={user}
          onShareContacts={onShareContacts}
          isShareContactsLoading={isShareContactsLoading}
        />
      )}
    </Flex>
    <Box
      sx={{
        bg: 'neutral.20',
        flexDirection: 'column',
        gap: '48px',
        p: '80px 40px',
        maxWidth: '560px',
        alignItems: 'flex-start',
        display: ['none', 'none', 'flex'],
      }}
      width="100%"
      height="100%"
    >
      <Image src={HomeNetwork} />
      <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
        <Text sx={{ fontWeight: 500, fontSize: '20px', color: 'text.dark', lineHeight: '1.4' }}>What is Getro?</Text>
        <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
          <Text>
            <Box sx={{ fontWeight: 500 }} as="span">
              {network.name}
            </Box>{' '}
            uses Getro to identify high-impact introductions within their expert network.
          </Text>
          <Text>Support them effortlessly by sharing your contacts privately and securely.</Text>
        </Flex>
      </Flex>
      <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
        <Text sx={{ fontWeight: 400, fontSize: '14px', color: 'text.main', lineHeight: '1.4' }}>
          Get started in 3 easy steps:
        </Text>
        {steps.map(({ title, description }, index) => (
          <Flex key={title} sx={{ alignItems: 'flex-start', gap: '16px' }}>
            <Box
              sx={{
                minWidth: '24px',
                minHeight: '24px',
                width: '24px',
                height: '24px',
                bg: 'purple.300',
                borderRadius: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {index + 1}
            </Box>
            <Flex sx={{ flexDirection: 'column', gap: '4px' }}>
              <Text sx={{ fontWeight: 500, fontSize: '14px', color: 'text.dark', lineHeight: '1.4' }}>{title}</Text>
              <Text sx={{ fontWeight: 400, fontSize: '14px', color: 'text.main', lineHeight: '1.4' }}>
                {description}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Box>
  </Flex>
);

AllySignupLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  network: PropTypes.object,
  user: PropTypes.object,
  steps: PropTypes.array,
  onShareContacts: PropTypes.func.isRequired,
  isShareContactsLoading: PropTypes.bool.isRequired,
};

AllySignupLayout.defaultProps = {
  network: null,
  user: null,
  steps: [],
};

export default AllySignupLayout;
