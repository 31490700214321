import { createSelector } from 'reselect';
import { activeNetworkSelector } from '../../redux/selectors';

export const isNetworkAdminSelector = createSelector(
  activeNetworkSelector,
  (activeNetwork) => activeNetwork?.isManager,
);

export const isOrganizationManagerSelector = createSelector(
  activeNetworkSelector,
  (activeNetwork) => activeNetwork?.isOrganizationManager,
);

export const makeIsCompanyAdminSelector = (companySlug) =>
  createSelector(
    activeNetworkSelector,
    (activeNetwork) =>
      activeNetwork && !!Object.values(activeNetwork?.isOrganizationManager)?.find(({ slug }) => slug === companySlug),
  );

export const canManageCompaniesSelector = createSelector(
  activeNetworkSelector,
  (activeNetwork) =>
    activeNetwork?.isManager || (activeNetwork && Object.keys(activeNetwork?.isOrganizationManager)?.length >= 2),
);
