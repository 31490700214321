import React, { useEffect, useRef, useState } from 'react';
import { Button, FormTextarea, FormToggle } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { FormikAutoSubmit } from '../../../components/atoms/formikAutoSubmit';
import SharedListLink from '../../../components/molecules/shareSharedListModal/sharedListLink';
import { trackEvent } from '../../../helpers/analytics';
import { useEditCompanyList } from '../hooks/useEditCompanyList';

export const AskForIntros = ({ activeNetwork, sharedList }) => {
  const initialized = useRef(false);
  const [shareable, setShareable] = useState(sharedList.shareable);
  const [showLeaderboard, setShowLeaderboard] = useState(sharedList.leaderboardEnabled);
  const { initialValues, updateSharedList, isUpdatingList } = useEditCompanyList(sharedList);
  const listUrl = `${window.location.origin}/app/shared-company-lists/${sharedList.slug}`;

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      return;
    }

    trackEvent('lists:toggle_shareable_url', {
      collectionId: sharedList.collection?.id,
      listSlug: sharedList.slug,
      listId: sharedList.id,
      objectType: 'companies',
      action: shareable ? 'enable' : 'disable',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareable]);

  const onSubmit = (values, submitProps) => {
    setShareable(values.shareable);
    setShowLeaderboard(values.showLeaderboard);
    updateSharedList(values).then(() => {
      submitProps.resetForm({ values });
    });
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        as={Link}
        to={`/networks/${activeNetwork.slug}/list/${sharedList.id}/contacts/settings`}
        sx={{ textDecoration: 'none', fontWeight: 'body', position: 'absolute', top: '-20px', left: '0' }}
      >
        <Flex sx={{ alignItems: 'center', color: 'text.subtle', gap: '8px' }}>
          <Box as={ChevronLeft} width="16px" height="16px" aria-hidden="true" sx={{ strokeWidth: 1.5 }} />
          <Text fontSize="12px" fontWeight="medium" lineHeight="20px">
            Back
          </Text>
        </Flex>
      </Box>
      <Text fontSize="20px" fontWeight="semibold" color="text.dark" lineHeight="28px">
        Ask for intros
      </Text>
      <Box mt="32px">
        <Formik
          initialValues={{
            name: initialValues.name,
            shareable,
            leaderboardEnabled: showLeaderboard,
            pointOfContactId: sharedList.pointOfContact.id,
          }}
          onSubmit={onSubmit}
        >
          <Form>
            <FormikAutoSubmit />
            <Field
              name="shareable"
              component={FormToggle}
              label="Shared list view"
              helpText="Unlock more intro opportunities by sharing this list with anyone. They can connect their LinkedIn to automatically surface relevant connections."
              size="large"
            />
            {shareable && (
              <>
                <Box mt="8px">
                  <SharedListLink listUrl={listUrl} />
                </Box>
                <Field
                  name="leaderboardEnabled"
                  component={FormToggle}
                  label="Show top connectors"
                  helpText="Show the networks and allies offering more intros. This encourages your network to take the lead in helping."
                  size="large"
                  containerProps={{ mt: '40px' }}
                />
              </>
            )}
          </Form>
        </Formik>
        {shareable && (
          <Formik
            initialValues={{ name: initialValues.name, blurb: sharedList.blurb }}
            enableReinitialize
            onSubmit={updateSharedList}
          >
            <Form>
              <Box mt="40px">
                <Field
                  name="blurb"
                  component={FormTextarea}
                  label="Blurb"
                  helpText={
                    <>
                      <Text>
                        Share context so your network knows the introductions you need. This will be visible to anyone
                        with the link.
                      </Text>
                      <Text mt="8px">
                        Tip: If you need go-to-market help, make sure your Ideal Customer Profile is clear.
                      </Text>
                    </>
                  }
                />
              </Box>
              <Button type="submit" size="small" mt="24px" loading={isUpdatingList}>
                Update
              </Button>
            </Form>
          </Formik>
        )}
      </Box>
    </Box>
  );
};

AskForIntros.propTypes = {
  activeNetwork: PropTypes.object,
  sharedList: PropTypes.object,
};

AskForIntros.defaultProps = {
  activeNetwork: {},
  sharedList: {},
};
