import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Link } from 'react-router-dom';
import { SidebarOpen } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleMenu } from '../../../features/mainMenu';

export const MainLayoutHeader = ({ title, actions, breadcrumbs, breadcrumbsProps = {}, headerProps = {} }) => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.mainMenu.isOpen);

  return (
    <Flex
      as="header"
      height={['auto', 'auto', '48px']}
      minHeight={['48px', '48px', 'auto']}
      px="16px"
      py="8px"
      alignItems="center"
      sx={{ borderBottom: 1, borderBottomColor: 'border.subtle', gap: '16px' }}
      {...headerProps}
    >
      {!isMenuOpen && (
        <Flex
          height="24px"
          width="24px"
          justifyContent="center"
          alignItems="center"
          onClick={() => dispatch(toggleMenu())}
          sx={{ cursor: 'pointer' }}
        >
          <Box as={SidebarOpen} size="16px" aria-hidden="true" flex="0 0 auto" />
        </Flex>
      )}
      <Box
        alignItems={['flex-start', 'flex-start', 'center']}
        justifyContent="space-between"
        flexWrap="wrap"
        width="100%"
        display={['flex', 'grid', 'grid']}
        sx={{
          gap: '8px',
          gridTemplateColumns: ['auto auto', '1fr auto', '1fr auto'],
        }}
        {...breadcrumbsProps}
      >
        <Flex flexDirection="row" sx={{ height: '32px', alignItems: 'center' }}>
          {breadcrumbs
            .filter((element) => element !== null)
            .map(({ label, to, ...others }) => (
              <Link
                key={label}
                to={to}
                style={{ textDecoration: 'none', display: 'inline-block', marginRight: '4px' }}
                {...others}
              >
                <Text width="max-content" color="text.subtle" fontWeight="medium">{`${label} /`}</Text>
              </Link>
            ))}
          <Box as="h1" fontSize="16px" fontWeight="semibold" color="text.dark" display="inline">
            {title}
          </Box>
        </Flex>
        {!!actions.length && (
          <Flex alignItems="center" sx={{ gap: '8px' }}>
            {actions.map((action) => action)}
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

MainLayoutHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  actions: PropTypes.array,
  breadcrumbs: PropTypes.array,
  breadcrumbsProps: PropTypes.object,
  headerProps: PropTypes.object,
};

MainLayoutHeader.defaultProps = {
  actions: [],
  breadcrumbs: [],
  breadcrumbsProps: {},
  headerProps: {},
};
