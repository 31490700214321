/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { get } from 'api';

import { setUser } from '../../../actions/userActions';
import { getItem, storeItem } from '../../../helpers/store';
import { userSelector } from '../../../redux/selectors';
import { sharedCompanyListV2Api } from '../../../services/sharedCompanyListV2';

export const useImportBanner = () => {
  const user = useSelector(userSelector);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const savedProgress = getItem(`import-progress-${user?.id}`);

    if (savedProgress) {
      setProgress(parseFloat(savedProgress));

      if (parseFloat(savedProgress) >= 97) {
        return;
      }
    }

    const duration = 20 * 60 * 1000; // 20 minutes in milliseconds
    const interval = 1000; // Update every second
    const incrementPerInterval = (100 / duration) * interval;

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + incrementPerInterval;
        const finalProgress = newProgress >= 97 ? 97 : newProgress;

        storeItem(`import-progress-${user?.id}`, finalProgress.toString());

        return finalProgress;
      });
    }, interval);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(timer);
    };
  }, []);

  const refreshUser = async () => {
    const response = await get('/users/me');
    const userData = response.data;

    setUser(userData);
  };

  useEffect(() => {
    const fiveSecondInterval = setInterval(() => {
      dispatch(sharedCompanyListV2Api.util.invalidateTags(['shared-company-list-contacts']));

      refreshUser();
    }, 10000);

    return () => {
      clearInterval(fiveSecondInterval);
    };
  }, []);

  return { progress };
};
