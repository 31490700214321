import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCookie } from 'nookies';

import { setUser } from '../../../actions/userActions';
import { setNetworks } from '../../../actions/networksActions';
import { identify as analyticsIdentify, trackEvent } from '../../../helpers/analytics';
import { storeItem } from '../../../helpers/store';
import { identifyUser } from '../../../helpers/supportChat';
import { getDomainName } from '../../../helpers/urlHelpers';
import { useSignInMutation } from '../../../services/user';

export const useSignIn = () => {
  const [userSignIn, { isLoading }] = useSignInMutation();
  const [apiError, setApiError] = useState(null);
  const dispatch = useDispatch();

  const signIn = ({ email, password }) => {
    setApiError(null);
    userSignIn({
      email,
      password,
    })
      .unwrap()
      .then((response) => {
        const { token, user } = response;

        storeItem('authToken', token);
        setCookie(null, 'authToken', token, { path: '/', domain: getDomainName(), maxAge: 365 * 24 * 60 * 60 });

        analyticsIdentify(user);
        identifyUser(user);
        trackEvent('user_logged_in');

        return user;
      })
      .then((user) => {
        dispatch(setUser(user));
        dispatch(setNetworks(user));
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          trackEvent('log_in_invalid');
        }
      });
  };

  return { signIn, isLoading, apiError };
};
