export const getCompanyColumns = (columnsToHide, showContactOf) =>
  [
    {
      Header: 'Name',
      accessor: 'name',
      width: '200px',
      disableSortBy: true,
    },
    {
      Header: 'Contact name',
      accessor: 'contactName',
      width: '320px',
      disableSortBy: true,
      disablePadding: true,
    },
    {
      Header: 'Contact job title',
      accessor: 'jobTitle',
      width: '400px',
      disableSortBy: true,
      disablePadding: true,
    },
    ...(showContactOf
      ? [
          {
            Header: 'Contact of',
            accessor: 'contactOf',
            width: '280px',
            disableSortBy: true,
            disablePadding: true,
          },
        ]
      : []),
    {
      Header: 'Can you help?',
      accessor: 'offerIntro',
      width: '160px',
      disableSortBy: true,
      disablePadding: true,
    },
  ].filter((column) => !columnsToHide.has(column.accessor));
