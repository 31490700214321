import React, { useState } from 'react';
import { Button } from '@getro/rombo';
import { Plus } from 'lucide-react';
import { Box, Flex } from 'rebass/styled-components';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AdminTable } from 'components/organisms/adminTable';
import { useNetworkAdmins } from 'hooks/useNetworkAdmins';
import { SettingsLayoutHeader } from '../../../components/atoms/settingsLayoutHeader';
import usePageHook from '../../../hooks/usePageHook';
import { activeNetworkSelector, userSelector } from '../../../redux/selectors';
import { InviteMemberModal } from './inviteMemberModal';

const pageTitle = 'Team members';

const Admins = () => {
  const {
    isLoadingNetworkAdmins,
    isLoadingResendInvite,
    isLoadingRemoveAdmin,
    isLoadingAddAdmin,
    isLoadingLinkedinSync,
    admins,
    resendInvite,
    removeAdmin,
    hasLoadAdminsListError,
    addAdmin,
    updateLinkedinSync,
    hasAddAdminError,
    updateRole,
    isLoadingUpdateRole,
  } = useNetworkAdmins();
  const activeNetwork = useSelector(activeNetworkSelector);
  const currentUser = useSelector(userSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);

  usePageHook(pageTitle);

  if (!activeNetwork.isManager) {
    return (
      <Redirect
        to={{
          pathname: `/networks/${activeNetwork.slug}/settings`,
        }}
      />
    );
  }

  return (
    <Box pr="48px">
      <SettingsLayoutHeader
        title={pageTitle}
        description="Manage who has access to your network. For a list that also includes company admins, go to the ‘Data export’ page."
        actions={[
          <Flex sx={{ justifyContent: 'flex-end', pb: '16px', pt: '8px' }}>
            <Button
              onClick={() => setIsModalOpen(true)}
              variant="secondary"
              icon={Plus}
              size="small"
              width="max-content"
            >
              Invite member
            </Button>
          </Flex>,
        ]}
        sx={{
          '& > div': {
            flexWrap: 'nowrap',
            '& >div:last-child': {
              minWidth: 'max-content',
            },
          },
        }}
      />
      <Box pt="40px">
        <AdminTable
          title="Network admins"
          loadingAdminsError={hasLoadAdminsListError}
          isLoadingAdmins={isLoadingNetworkAdmins}
          isResendingInvitation={isLoadingResendInvite}
          isDeletingAdmin={isLoadingRemoveAdmin}
          activeNetwork={activeNetwork}
          currentUser={currentUser}
          admins={admins}
          onRemoveAdmin={removeAdmin}
          onResendInvitation={resendInvite}
          canAddCompanyAdmins
          canRemoveCompanyAdmins
          showEmailAddress
          companyAdmins={false}
          updateLinkedinSync={updateLinkedinSync}
          isLoadingLinkedinSync={isLoadingLinkedinSync}
          updateRole={updateRole}
          isLoadingUpdateRole={isLoadingUpdateRole}
        />
      </Box>
      {isModalOpen && (
        <InviteMemberModal
          adminsEmails={admins.map((admin) => admin.email)}
          isSubmitting={isLoadingAddAdmin}
          onAdminInvite={async (data) => {
            const { error } = await addAdmin(data);
            if (error) return;
            toast('Invitation sent', {
              icon: 'success',
              id: 'invitation-sent',
            });
            setIsModalOpen(false);
          }}
          companyName={activeNetwork.name}
          setModalOpen={setIsModalOpen}
          error={Boolean(hasAddAdminError)}
        />
      )}
    </Box>
  );
};
export default Admins;
