import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Inbox, SidebarClose } from 'lucide-react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { NavLink } from 'react-router-dom';
import { toggleMenu } from '../../../features/mainMenu';
import { activeNetworkSelector } from '../../../redux/selectors';
import useIntroductionRequests from '../../../hooks/useIntroductionRequests';
import { useMainMenu } from '../../../hooks/useMainMenu';
import { NetworkSelector } from '../NetworkSelector';
import { UserAndHelpMenu } from '../userAndHelpMenu';
import { MenuNav } from './menuNav';
import JobLinks from './jobLinks';

export const MainLayoutMenu = () => {
  const activeNetwork = useSelector(activeNetworkSelector);
  const isMenuOpen = useSelector((state) => state.mainMenu.isOpen);
  const { isManager, features } = activeNetwork;
  const showInbox = useMemo(() => isManager && features && features.includes('talent_network'), [isManager, features]);
  const [introductionRequests] = useIntroductionRequests(activeNetwork);
  const { menuItems, jobItems } = useMainMenu(activeNetwork);
  const introductionRequestsCount = useMemo(
    () => (introductionRequests ? introductionRequests.length : 0),
    [introductionRequests],
  );
  const dispatch = useDispatch();
  const hasJobBoard = features && features.includes('job_board');

  return (
    <>
      <Box
        display={[isMenuOpen ? 'block' : 'none', isMenuOpen ? 'block' : 'none', 'none']}
        bg="neutral.20"
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 98,
          opacity: 0.8,
        }}
        onClick={() => dispatch(toggleMenu())}
      />
      <Flex
        className="main-menu"
        height="100vh"
        width="240px"
        flexDirection="column"
        bg="neutral.0"
        pb="8px"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: [99, 99, 1],
          borderRight: 1,
          borderRightColor: 'neutral.100',
          boxShadow: [isMenuOpen ? 'medium' : 'none', 'none', 'none'],
          overflow: ['visible', 'visible', 'hidden'],
          transition: 'transform 0.15s ease-in-out',
          transform: isMenuOpen ? 'translateX(0)' : 'translateX(-100%)',
        }}
      >
        <Flex
          px="8px"
          py="12px"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            gap: '4px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <NetworkSelector />
          <Flex
            className="main-menu-close-icon"
            alignItems="center"
            justifyContent="center"
            width="24px"
            height="24px"
            sx={{ cursor: 'pointer' }}
            onClick={() => dispatch(toggleMenu())}
          >
            <Box
              as={SidebarClose}
              className="main-menu-item"
              width="16px"
              height="16px"
              aria-hidden="true"
              flex="0 0 auto"
            />
          </Flex>
        </Flex>

        <Flex mt="16px" flexDirection="column" justifyContent="space-between" flexGrow="1">
          <Box>
            {!!menuItems.length && (
              <Box as="nav" mx="4px">
                <Box as="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
                  {menuItems.map(({ icon, label, path, href }) => (
                    <MenuNav
                      key={`${path}-${href}`}
                      path={path}
                      href={href}
                      label={label}
                      icon={icon}
                      slug={activeNetwork.slug}
                    />
                  ))}
                  {!!jobItems?.length && isManager && hasJobBoard && (
                    <JobLinks items={jobItems} slug={activeNetwork.slug} />
                  )}
                </Box>
              </Box>
            )}
          </Box>
          <Box>
            {showInbox && (
              <Flex
                as={NavLink}
                to={`/networks/${activeNetwork.slug}/inbox`}
                exact
                sx={{
                  alignItems: 'center',
                  position: 'relative',
                  width: '100%',
                  height: '36px',
                  flex: '0 0 auto',
                  py: '8px',
                  pl: '12px',
                  textDecoration: 'none',
                  gap: '12px',
                }}
              >
                <Box as={Inbox} width="16px" height="16px" aria-hidden="true" />
                <Text
                  textAlign="left"
                  fontSize="14px"
                  fontWeight="medium"
                  flexGrow="1"
                  className="main-menu-item"
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Inbox
                </Text>
                {introductionRequestsCount > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '10px',
                      left: '22px',
                      width: '8px',
                      height: '8px',
                      borderRadius: 'circle',
                      bg: 'primary',
                    }}
                  />
                )}
              </Flex>
            )}
            <UserAndHelpMenu />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
