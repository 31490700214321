/* eslint-disable no-param-reassign */
import React, { useCallback, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Pencil } from 'lucide-react';
import useOnClickOutside from 'hooks/useClickOutside';
import { Input, Button, Tag } from '@getro/rombo';
import Range from '../Range';
import { FilterTitle } from '../filterTitle';

const RangeFilter = ({ title, min, max, value, onApply }) => {
  const [rangeValues, setRangeValues] = useState({ min, max });
  const [showDropDown, setShowDropDown] = useState();
  const [showSelected, setShowSelected] = useState(true);
  const handleClickOutside = useCallback(() => setShowDropDown(false), []);
  const ref = useOnClickOutside(handleClickOutside, { useEsc: true });

  const onChange = (event, reverse = false) => {
    if (!event.target.value || Number.isNaN(Number(event.target.value))) {
      event.target.value = reverse ? min : max;
    }

    setRangeValues({
      min: reverse ? +event.target.value : rangeValues.min,
      max: !reverse ? +event.target.value : rangeValues.max,
    });
  };

  const onKeyDown = (event, reverse = false) => {
    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') return;
    let currentValue = 0;

    if (event.key === 'ArrowUp') {
      currentValue += 1;
    }

    if (event.key === 'ArrowDown') {
      currentValue -= 1;
    }

    const newValue = reverse
      ? Math.min(rangeValues.min + currentValue, rangeValues.max - 1)
      : Math.max(rangeValues.max + currentValue, rangeValues.min + 1);

    setRangeValues({
      min: reverse ? Math.max(newValue, 0) : rangeValues.min,
      max: !reverse ? Math.min(newValue, max) : rangeValues.max,
    });
  };

  const onClickApply = () => {
    if (rangeValues.min === min && rangeValues.max === max) {
      return;
    }

    if (rangeValues.min < min || rangeValues.max < min || rangeValues.max < rangeValues.min || rangeValues.max > max) {
      onApply({ min, max });
    } else {
      onApply(rangeValues);
    }

    setRangeValues({ min, max });
    setShowDropDown(false);
    setShowSelected(true);
  };

  return (
    <Box
      ref={ref}
      sx={{
        gap: '8px',
        alignItems: 'flex-start',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        position: 'relative',
      }}
      onKeyDown={(even) => {
        if (even.key === 'Enter') {
          onClickApply();
        }
      }}
    >
      <FilterTitle
        title={title}
        showClear={Boolean(value)}
        onToggle={() => {
          if (!value) {
            setShowDropDown(!showDropDown);
          }
        }}
        showToggle={!showDropDown && !value}
        clearFilter={() => {
          setShowDropDown(false);
          onApply(null);
        }}
      />
      {showSelected && value && !showDropDown && (
        <Flex sx={{ gap: '4px', flexWrap: 'wrap', pt: '8px' }}>
          <Flex sx={{ alignItems: 'center', gap: '4px', flexWrap: 'wrap' }}>
            <Tag
              onRemove={() => {
                onApply();
                setRangeValues({ min, max });
              }}
            >
              {`${value.min <= min && !(value.max >= max) ? 'Up' : value.min} ${value.max >= max ? 'or' : 'to'} ${
                value.max >= max ? 'more' : value.max
              }`}
            </Tag>
          </Flex>
          <Flex
            id="range-filter-select-trigger"
            sx={{
              alignItems: 'center',
              gap: '4px',
            }}
            onClick={() => {
              setRangeValues({ min: value?.min || min, max: value?.max || max });
              setShowDropDown(true);
            }}
          >
            <Box sx={{ color: 'neutral.300' }} size="16px" as={Pencil} />
          </Flex>
        </Flex>
      )}
      {showDropDown && (
        <Box
          sx={{
            gap: '16px',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1,
            mt: '8px',
            pt: '8px',
          }}
        >
          <Flex
            sx={{
              gap: '8px',
              p: {
                color: 'text-subtle',
                fontSize: '14px',
                lineHeight: '1.4',
              },
              '& > div': {
                gap: '8px',
                alignItems: 'center',
              },
            }}
          >
            <Flex sx={{ gap: '8px' }}>
              <Text as="p">From</Text>
              <Input
                data-testid="min-range-number"
                autoFocus
                height="32px"
                width="64px"
                placeholder={`${min}`}
                onKeyDown={(event) => onKeyDown(event, true)}
                onChange={(event) => onChange(event, true)}
                value={rangeValues.min === min ? '' : rangeValues.min}
                onBlur={() => {
                  if (rangeValues.min < min || rangeValues.min > rangeValues.max) {
                    setRangeValues({ min, max: rangeValues.max });
                  }
                }}
              />
            </Flex>
            <Flex sx={{ gap: '8px' }}>
              <Text as="p">to</Text>
              <Input
                height="32px"
                width="64px"
                placeholder={`${max - 1}+`}
                onKeyDown={(event) => onKeyDown(event, false)}
                onChange={(event) => onChange(event, false)}
                value={rangeValues.max === max ? '' : rangeValues.max}
                onBlur={() => {
                  if (rangeValues.max < min || rangeValues.max < rangeValues.min || rangeValues.max > max) {
                    setRangeValues({ min: rangeValues.min, max });
                  }
                }}
              />
            </Flex>
          </Flex>
          <Flex sx={{ gap: '8px', flexDirection: 'column', justifyItems: 'flex-start' }}>
            <Range
              max={max}
              min={min}
              rangeValues={{
                max: Math.min(rangeValues.max, max),
                min: Math.min(rangeValues.min, max),
              }}
              onRangeChange={setRangeValues}
            />
            <Box>
              <Button size="small" onClick={onClickApply}>
                Apply
              </Button>
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

RangeFilter.propTypes = {
  title: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  onApply: PropTypes.func.isRequired,
};

RangeFilter.defaultProps = {
  title: 'Years of experience',
  min: 0,
  max: 30,
  value: null,
};

export default RangeFilter;
