import React from 'react';
import { Button, FormInput } from '@getro/rombo';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Formik, Form, Field } from 'formik';
import { ChevronRight, MessagesSquare, Plus } from 'lucide-react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { useEditCompanyList } from '../hooks/useEditCompanyList';

export const EditCompanyList = ({ routePath, sharedList }) => {
  const { initialValues, updateSharedList, isUpdatingList } = useEditCompanyList(sharedList);
  const { search } = useLocation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const onSubmit = async (values) => {
    updateSharedList({
      ...values,
    });
  };

  return (
    <Box>
      <Text fontSize="20px" fontWeight="semibold" color="text.dark" lineHeight="28px">
        List settings
      </Text>
      <Box mt="32px">
        <Formik initialValues={{ name: initialValues.name }} onSubmit={onSubmit} validationSchema={validationSchema}>
          <Form>
            <Field name="name" component={FormInput} label="Name" labelExtra="Required" />
            <Button type="submit" size="small" mt="24px" loading={isUpdatingList}>
              Update
            </Button>
          </Form>
        </Formik>
        <Box width="100%" height="1px" bg="border.subtle" my="40px" />
        <Box>
          <Text fontWeight="medium" color="text.dark">
            Sharing
          </Text>
          <Flex
            mt="32px"
            as={Link}
            to={`${routePath}/ask-for-intros${search}`}
            sx={{ gap: '16px', textDecoration: 'none' }}
          >
            <Box flex="0 0 auto" sx={{ color: 'text.subtle' }}>
              <Box as={MessagesSquare} width="24px" height="24px" aria-hidden="true" />
            </Box>
            <Box flexGrow="1">
              <Text fontSize="14px" fontWeight="medium" color="text.dark" lineHeight="20px">
                Ask for intros
              </Text>
              <Text mt="4px" fontSize="14px" lineHeight="20px">
                Share a link so others can view your target companies, connect their LinkedIn, and quickly spot
                connections to offer introductions.
              </Text>
            </Box>
            <Flex flex="0 0 auto" alignItems="center" sx={{ color: 'neutral.400' }}>
              <Box as={ChevronRight} width="24px" height="24px" aria-hidden="true" sx={{ strokeWidth: '1.5' }} />
            </Flex>
          </Flex>
        </Box>
        <Box width="100%" height="1px" bg="border.subtle" my="40px" />
        <Box>
          <Text fontWeight="medium" color="text.dark">
            Companies in this list
          </Text>
          <Flex
            mt="32px"
            as={Link}
            to={`${routePath}/add-companies${search}`}
            sx={{ gap: '16px', textDecoration: 'none' }}
          >
            <Box flex="0 0 auto" sx={{ color: 'text.subtle' }}>
              <Box as={Plus} width="24px" height="24px" aria-hidden="true" />
            </Box>
            <Box flexGrow="1">
              <Text fontSize="14px" fontWeight="medium" color="text.dark" lineHeight="20px">
                Add companies
              </Text>
              <Text mt="4px" fontSize="14px" lineHeight="20px">
                Enter the websites of companies you&apos;d like in this list to request introductions
              </Text>
            </Box>
            <Flex flex="0 0 auto" alignItems="center" sx={{ color: 'neutral.400' }}>
              <Box as={ChevronRight} width="24px" height="24px" aria-hidden="true" sx={{ strokeWidth: '1.5' }} />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

EditCompanyList.propTypes = {
  routePath: PropTypes.string.isRequired,
  sharedList: PropTypes.object,
};

EditCompanyList.defaultProps = {
  sharedList: {},
};
