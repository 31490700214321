import { createApi } from '@reduxjs/toolkit/query/react';
import merge from 'lodash.merge';
import deserialize from 'helpers/getroPropertyMapper';
import { get } from 'api';
import { networkBaseQueryV2 } from './queries';

const transformResponse = (response) => {
  const sortedData = deserialize(response)
    .map((admin) => {
      if (!admin.name) {
        return merge(admin, { name: admin.email });
      }

      return merge(admin);
    })
    .sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

  return sortedData;
};

export const networkAdminsApiV2 = createApi({
  reducerPath: 'networkAdminsApiV2',
  baseQuery: networkBaseQueryV2,
  tagTypes: ['network-admins'],
  endpoints: (builder) => ({
    getNetworkAdmins: builder.query({
      async queryFn({ networkId: collectionId, includeAllies = false }) {
        try {
          const networkAdminsPromise = get(`collections/${collectionId}/managers`, {
            baseURL: process.env.REACT_APP_API_V2_BASE_URL,
            params: {
              roles: ['network_admin'],
            },
          }).then((response) => response.data);

          const alliesPromise = includeAllies
            ? get(`collections/${collectionId}/allies`, {
                baseURL: process.env.REACT_APP_API_V2_BASE_URL,
              }).then((response) => response.data)
            : Promise.resolve({ data: [] });

          const [networkAdminsResponse, alliesResponse] = await Promise.all([networkAdminsPromise, alliesPromise]);

          return {
            data: transformResponse({
              data: [
                ...networkAdminsResponse.data.map((admin) => merge(admin, { attributes: { role: 'network_admin' } })),
                ...alliesResponse.data.map((admin) => merge(admin, { attributes: { role: 'ally' } })),
              ],
            }),
          };
        } catch (error) {
          return {
            error,
          };
        }
      },
      providesTags: ['network-admins'],
    }),
  }),
});

export const { useGetNetworkAdminsQuery } = networkAdminsApiV2;
