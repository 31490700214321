import React from 'react';
import { Button } from '@getro/rombo';
import { Check, ThumbsUp } from 'lucide-react';
import PropTypes from 'prop-types';
import { Box, Flex, Link, Text } from 'rebass/styled-components';

import { useOfferIntro } from '../../hooks/useOfferIntro';

export const OfferIntro = ({ contact, company, sharedList }) => {
  const { isIntroOffered, offerIntro, isLoading, mailto, onClickMakeIntro } = useOfferIntro({
    contact,
    company,
    sharedList,
  });

  return isIntroOffered ? (
    <Box>
      <Flex alignItems="center" sx={{ gap: '8px', color: 'green.600' }}>
        <Box as={Check} width="16px" height="16px" aria-hidden="true" />
        <Text fontSize="14px" fontWeight="medium" color="text.dark">
          Intro offered
        </Text>
      </Flex>
      <Text as="p" fontSize="14px">
        Wait for a forwardable or{' '}
        <Link href={mailto} sx={{ color: 'text.maim' }} onClick={onClickMakeIntro}>
          make the intro now.
        </Link>
      </Text>
    </Box>
  ) : (
    <Button icon={ThumbsUp} size="small" loading={isLoading} onClick={offerIntro}>
      Offer intro
    </Button>
  );
};

OfferIntro.propTypes = {
  contact: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  sharedList: PropTypes.object.isRequired,
};
