import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import { Box, Flex, Text } from 'rebass/styled-components';
import { dateToMonthAndYear } from 'services/dateFormatter';

const mapStageToFund = (stage) => {
  switch (stage) {
    case 'pre_seed':
      return 'Pre-seed';
    case 'seed':
      return 'Seed';
    case 'series_a':
      return 'Series A';
    case 'series_b':
      return 'Series B';
    case 'series_c':
    case 'series_c_plus':
      return 'Series C+';
    case 'private_equity':
      return 'Private equity';
    case 'ipo':
      return 'IPO';
    case 'ico':
      return 'ICO';
    default:
      return '';
  }
};

export const ContactInfoWorkDate = ({ position, ...props }) => {
  const timeSpent = (dateTo, dateFrom) => {
    const years = moment(dateTo ? new Date(dateTo) : Date.now()).diff(new Date(dateFrom), 'years');
    const months = moment(dateTo ? new Date(dateTo) : Date.now()).diff(new Date(dateFrom), 'months') - years * 12;
    const yearsString = `${years > 0 ? `${years} ${pluralize('year', years)}` : ''}`;
    const monthsString = `${months > 0 ? `${months} ${pluralize('month', years)}` : ''}`;

    return `${yearsString} ${monthsString}`;
  };

  const stages = new Set([
    'pre_seed',
    'seed',
    'series_a',
    'series_b',
    'series_c',
    'series_c_plus',
    'private_equity',
    'ipo',
    'ico',
  ]);

  const stageFrom =
    stages.has(position.stageFrom) && position.dateFrom ? ` (${mapStageToFund(position.stageFrom)})` : '';
  const stageTo = stages.has(position.stageTo) ? ` (${mapStageToFund(position.stageTo)})` : '';

  return (
    <Flex alignItems="center" flexWrap="wrap" {...props}>
      <Text as="span" color="text.subtle" fontSize="14px" lineHeight="20px">
        {`${dateToMonthAndYear(position.dateFrom)}${stageFrom} to ${
          position.dateTo ? dateToMonthAndYear(position.dateTo) : 'present'
        }${stageTo}`}
      </Text>
      <Box bg="text.subtle" width="2px" height="2px" mx="1" sx={{ borderRadius: 'circle' }} />
      <Text fontSize="1" color="text.subtle">
        {timeSpent(position.dateTo, position.dateFrom)}
      </Text>
    </Flex>
  );
};

ContactInfoWorkDate.propTypes = {
  position: PropTypes.object.isRequired,
};
