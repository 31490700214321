import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { ChevronDown, LayoutList } from 'lucide-react';
import { setMenuOpen } from 'features/mainMenu';
import { useDispatch, useSelector } from 'react-redux';
import { MenuNav } from './menuNav';

export const JobLinks = ({ items, slug }) => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.mainMenu.menuOpen);

  return (
    <Box px="8px" pt="8px">
      <Flex
        sx={{ gap: '8px', cursor: 'pointer' }}
        alignItems="center"
        onClick={() => dispatch(setMenuOpen(!isMenuOpen))}
      >
        <Box as={LayoutList} size="16px" aria-hidden="true" flex="0 0 auto" />
        <Box sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: '500', color: 'text.main', lineHeight: '16px' }}>
          Job board
        </Box>
        <Box
          as={ChevronDown}
          size="16px"
          aria-hidden="true"
          flex="0 0 auto"
          sx={{
            color: 'neutral.400',
            transform: isMenuOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
            transition: 'transform 0.1s ease-in-out',
          }}
        />
      </Flex>
      <Flex
        pt="8px"
        pl="8px"
        sx={{
          maxHeight: isMenuOpen ? '500px' : '0',
          opacity: isMenuOpen ? 1 : 0,
          overflow: 'hidden',
          transition: 'all 0.1s ease-in-out',
        }}
      >
        <Box width="2px" bg="border.subtle" height="auto" mr="8px" />
        <Flex flexDirection="column" width="100%">
          {items.map(({ path, href, label }) => (
            <MenuNav path={path} href={href} label={label} slug={slug} />
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

JobLinks.propTypes = {
  items: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired,
};

export default JobLinks;
