import React, { useMemo, useState } from 'react';
import { SettingsLayoutHeader } from 'components/atoms/settingsLayoutHeader';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useSelector } from 'react-redux';
import { activeNetworkSelector, userSelector } from 'redux/selectors';
import { Button, DataTable, Message } from '@getro/rombo';
import { useShareContactsMutation } from 'services/userV2';
import toast from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import { trackEvent } from 'helpers/analytics';
import { mergeArraysBy } from 'helpers/arrays';
import { useMyNetworksTable } from './hooks/useMyNetworksTable';
import ShareContacts from './shareContacts';
import StopSharingContacts from './stopSharingContacts';

export const MyNetworks = () => {
  const currentUser = useSelector(userSelector);
  const activeNetwork = useSelector(activeNetworkSelector);
  const [payload, setActionPayload] = useState(null);
  const [shareContacts, { isLoading }] = useShareContactsMutation();

  const getLink = (status = 'reconnect') =>
    currentUser.isOnlyAlly ? `/linkedin/${status}` : `/networks/${activeNetwork.slug}/linkedin/${status}`;

  const handleShareAction = async (network, action) => {
    const { data } = await shareContacts({
      collectionId: network.id,
      share: action === 'share',
    });

    if (data) {
      toast(
        <Box>
          <Text
            color="text.dark"
            sx={{
              fontWeight: 'medium',
              fontSize: ['16px', '16px', '14px'],
            }}
          >
            Settings updated
          </Text>
          <Text mt="8px" maxWidth="240px" color="text.main" sx={{ fontSize: ['16px', '16px', '14px'] }}>
            It might take a few minutes until they are applied.
          </Text>
        </Box>,
        {
          icon: 'success',
          id: 'settings-updated',
        },
      );
    }

    setActionPayload(null);
  };

  const networks = useMemo(
    () =>
      mergeArraysBy(
        currentUser.allyingCollections.map((collection) => ({
          ...collection,
          role: 'ally',
        })),
        currentUser.managingCollections.map((collection) => ({
          ...collection,
          role: 'network_admin',
        })),
        'id',
      ).sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }),
    [currentUser],
  );

  const { columns, rows } = useMyNetworksTable({
    networks,
    onShareAction: (network, action) => setActionPayload({ action, data: network }),
  });

  const hasOneSharedContact = useMemo(
    () => !currentUser.linkedinSync && networks.some((network) => network.shareContacts),
    [currentUser.linkedinSync, networks],
  );

  return (
    <Box maxWidth="600px">
      <SettingsLayoutHeader title="My networks" description="See and manage the networks you’re part of." />

      <Flex sx={{ flexDirection: 'column', gap: '40px', pt: '40px' }}>
        {currentUser.linkedinSync?.syncStatus === 'reconnection_needed' && (
          <Message type="warning">
            Your LinkedIn sync has stopped working.{' '}
            <Box color="text.main" as={NavLink} to={getLink()}>
              Reconnect now
            </Box>{' '}
            to keep your new connections synced in Getro.
          </Message>
        )}
        <Flex data-testid="admin-list">
          <DataTable
            sx={{
              minWidth: '600px',
              height: ['600px', '500px', '100%'],
              width: ['100%!important', '100%!important', '1600px!important'],
              maxWidth: '1600px!important',
              flexGrow: 1,
              '[role="columnheader"]': {
                alignSelf: 'flex-end',
              },
            }}
            virtuosoProps={{
              useWindowScroll: true,
            }}
            items={rows}
            columnDefinition={columns}
            isInitialized
            rowProps={({ original }) => ({
              as: 'div',
              'data-testid': `admin-${original.id}`,

              sx: {
                '& > div > div': {
                  display: 'flex',
                  alignItems: 'center',
                },
                ':hover': {
                  backgroundColor: 'neutral.20',
                },
              },
            })}
          />
        </Flex>
        {hasOneSharedContact && (
          <Message type="warning">
            <Flex
              sx={{ flexDirection: 'column', gap: '8px', color: 'text.main', fontSize: '14px', lineHeight: '20px' }}
            >
              <Text>No contacts are being shared yet</Text>
              <Text>
                You’re not sharing any contacts yet because none have been added. Connect your LinkedIn to sync and
                share them.{' '}
                <Box color="text.main" as={NavLink} to={getLink('sync')}>
                  Connect your LinkedIn
                </Box>{' '}
                to sync and share them.
              </Text>
            </Flex>
          </Message>
        )}
        {currentUser.isOnlyAlly && (
          <Flex
            sx={{
              gap: '16px',
              p: '24px',
              bg: 'purple.100',
              width: '600px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
            }}
          >
            <Text color="text.dark" fontWeight="500" fontSize="16px">
              Ready to create a network?
            </Text>
            <Text color="text.main" textAlign="center" fontSize="16px">
              For your company or the companies you support, discover warm intros to boost sales leads and speed up
              hiring.
            </Text>
            <Box>
              <Button
                as="a"
                href="https://www.getro.com/demo?utm_source=getro&utm_campaign=g4c&utm_content=create_network_cta"
                target="_blank"
                onClick={() => trackEvent('create_network_click')}
              >
                Create new network
              </Button>
            </Box>
          </Flex>
        )}
      </Flex>

      {payload?.action === 'share' && (
        <ShareContacts
          isSubmitting={isLoading}
          onCancel={() => setActionPayload(null)}
          onShareContacts={() => handleShareAction(payload.data, 'share')}
          networkName={payload.data.name}
        />
      )}
      {payload?.action === 'stop' && (
        <StopSharingContacts
          isSubmitting={isLoading}
          onCancel={() => setActionPayload(null)}
          onStopSharing={() => handleShareAction(payload.data, 'stop')}
          networkName={payload.data.name}
        />
      )}
    </Box>
  );
};
