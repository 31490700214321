import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import MultiSelectDropDown from '../MultiSelectDropDown';
import { FilterTitle } from '../filterTitle';
import MultiSelectInput from './multiSelectInput';
import { useMultiSelect } from './hooks/useMultiselect';

const MultiSelect = ({
  title,
  options,
  selected,
  onSelect,
  onSearch,
  loading,
  onInitialOpenDropDown,
  notFoundText,
  onOpen,
  debounceTime,
  useLocalSearch,
  useSearchAsTag,
  defaultText,
  isList,
  showSearch,
  disableSearch,
  disableSearchText,
  footer,
  dropdownFooter,
  onLoseFocus,
  placeholder,
  clickOutsideDownEvent,
  titleMessage,
  errorMessage,
  dropdownSx,
}) => {
  const {
    search,
    showDropDown,
    isOpened,
    showInput,
    typing,
    items,
    ref,
    inputRef,
    setSearch,
    setShowDropDown,
    handleSearch,
    openDropDown,
    onCloseDropDown,
    onToggle,
    useInput,
    onShowSearch,
    onItemSelect,
    clearFilter,
  } = useMultiSelect({
    onOpen,
    onInitialOpenDropDown,
    showSearch,
    onSearch,
    debounceTime,
    useLocalSearch,
    options,
    clickOutsideDownEvent,
    onSelect,
  });

  return (
    <Flex ref={ref} sx={{ width: '100%', alignItems: 'flex-start', flexDirection: 'column', gap: '8px' }}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <MultiSelectDropDown
          bottomText={dropdownFooter}
          onClose={() => onCloseDropDown()}
          onOpen={() => openDropDown()}
          onToggle={onToggle}
          onShowSearch={onShowSearch}
          showSearch={showSearch && showInput}
          selected={selected}
          isMulti
          options={items}
          onSelect={onItemSelect}
          showDropDown={showDropDown}
          loading={loading}
          defaultText={search ? '' : defaultText}
          isOpened={isOpened}
          openDropDown={openDropDown}
          useSearchAsTag={useSearchAsTag}
          searchText={search}
          errorMessage={errorMessage}
          handleSearch={handleSearch}
          sx={dropdownSx}
          input={
            useInput ? (
              <MultiSelectInput
                inputRef={inputRef}
                onLoseFocus={onLoseFocus}
                selected={selected}
                handleSearch={handleSearch}
                setShowDropDown={setShowDropDown}
                setSearch={setSearch}
                useSearchAsTag={useSearchAsTag}
                onSelect={onSelect}
                onCloseDropDown={onCloseDropDown}
                search={search}
                placeholder={placeholder}
                disableSearch={disableSearch}
                disableSearchText={disableSearchText}
                hasError={Boolean(errorMessage)}
              />
            ) : null
          }
          trigger={
            <FilterTitle
              data-testid="multi-select-trigger"
              title={title}
              showClear={selected.length > 0}
              showToggle={!isOpened && !selected?.length}
              clearFilter={clearFilter}
              onToggle={onToggle}
              titleMessage={isOpened ? titleMessage : null}
            />
          }
        >
          {!items.length && search && !typing && !loading && (
            <Text
              as="p"
              sx={{
                fontSize: '12px',
                lineHeight: '1.4',
                fontStyle: 'italic',
                color: 'text.subtle',
                px: '16px',
                pt: '8px',
              }}
            >
              {notFoundText} “{search}” {isList ? 'in this list' : ''}
            </Text>
          )}
        </MultiSelectDropDown>
      </Box>
      {(isOpened || selected?.length > 0) && footer}
    </Flex>
  );
};

MultiSelect.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  onInitialOpenDropDown: PropTypes.func,
  notFoundText: PropTypes.string,
  onOpen: PropTypes.func,
  debounceTime: PropTypes.number,
  useLocalSearch: PropTypes.bool,
  useSearchAsTag: PropTypes.bool,
  defaultText: PropTypes.string,
  isList: PropTypes.bool,
  showSearch: PropTypes.bool,
  footer: PropTypes.node,
  dropdownFooter: PropTypes.node,
  onLoseFocus: PropTypes.func,
  placeholder: PropTypes.string,
  clickOutsideDownEvent: PropTypes.string,
  disableSearch: PropTypes.bool,
  disableSearchText: PropTypes.string,
  titleMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  dropdownSx: PropTypes.object,
};

MultiSelect.defaultProps = {
  onSelect: () => {},
  onSearch: () => {},
  loading: false,
  onInitialOpenDropDown: null,
  onOpen: () => {},
  notFoundText: 'No results found for',
  debounceTime: 0,
  useLocalSearch: false,
  useSearchAsTag: false,
  defaultText: '',
  isList: false,
  showSearch: true,
  footer: null,
  dropdownFooter: null,
  onLoseFocus: null,
  placeholder: 'Search',
  clickOutsideDownEvent: 'click',
  disableSearch: false,
  disableSearchText: '',
  titleMessage: '',
  errorMessage: '',
  dropdownSx: {},
};

export default MultiSelect;
