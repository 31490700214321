import React, { useEffect, useMemo } from 'react';
import { Button } from '@getro/rombo';
import { useSelector } from 'react-redux';
import { ExternalLink, Plus } from 'lucide-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { COMPANIES_BACK_URL, NAVIGATED_FROM_COMPANY_BREADCRUMB } from 'helpers/constants';
import { MainLayoutHeader } from '../../atoms/mainLayoutHeader';
import { hasMatchesSelector, pageSelector } from '../../../redux/selectors';
import NetworkSchema from '../../../schemas/network';
import { companySlugParser } from '../../../helpers/companySlugParser';
import { ButtonGetMoreMatches } from '../../organisms/buttonGetMoreMatches';

export const Title = ({ activeNetwork }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const page = useSelector(pageSelector);
  const hasMatches = useSelector(hasMatchesSelector);
  const { isManager, isOrganizationManager } = activeNetwork;
  const { title } = page;
  const slugParts = useMemo(() => companySlugParser(match.params.companySlug), [match.params.companySlug]);
  const [, companySlug] = slugParts;

  const onNavigateToJobBoard = () => {
    window.open(`https://${activeNetwork.url}/companies/${companySlug}`, '__blank', 'noopener noreferrer');
  };

  const onPostJob = () => {
    history.push(`/networks/${activeNetwork.slug}/companies/${match.params.companySlug}/post-job`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goBack = useMemo(() => {
    const backUrl = localStorage.getItem(COMPANIES_BACK_URL);

    if (backUrl) {
      return backUrl;
    }

    return `/networks/${activeNetwork.slug}/companies`;
  }, [activeNetwork.slug]);

  return (
    <MainLayoutHeader
      title={title}
      breadcrumbs={[
        !isOrganizationManager
          ? {
              label: 'Job board companies',
              to: goBack,
              onClick: () => {
                localStorage.setItem(NAVIGATED_FROM_COMPANY_BREADCRUMB, 'true');
              },
            }
          : null,
      ]}
      actions={[
        hasMatches && isManager && (
          <ButtonGetMoreMatches origin="company_profile_header" variant="secondary" page="company_profile">
            Get matches
          </ButtonGetMoreMatches>
        ),
        <Button icon={ExternalLink} size="small" variant="tertiary" onClick={onNavigateToJobBoard} flex="0 0 auto">
          View
        </Button>,
        <Button icon={Plus} size="small" variant="accent" onClick={onPostJob} flex="0 0 auto">
          Post job
        </Button>,
      ]}
    />
  );
};

Title.propTypes = {
  activeNetwork: NetworkSchema.isRequired,
};
