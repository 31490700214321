import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Text } from 'rebass/styled-components';
import { Link } from 'react-router-dom';

import UserLoggedIn from '../../../../components/molecules/UserMenu';
import { userSelector } from '../../../../redux/selectors';

export const UserMenu = () => {
  const user = useSelector(userSelector);

  return user.email ? (
    <UserLoggedIn onlyProfilePicture />
  ) : (
    <Flex alignItems="center" sx={{ gap: '16px' }}>
      <Text
        as={Link}
        fontSize="14px"
        fontWeight="medium"
        to="/login"
        sx={{ textDecoration: 'none', lineHeight: '14px' }}
      >
        Log in
      </Text>
    </Flex>
  );
};
