import React, { useState } from 'react';
import { Flex, Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Loader, MessageBlock } from '@getro/rombo';
import UserSchema from 'schemas/user';
import strings from 'strings';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { trackEvent } from 'helpers/analytics';
import NetworkSchema from '../../../schemas/network';
import { AdminDataTable } from './AdminDataTable';
import RemoveAdminModal from '../adminPanel/removeAdminModal';
import { EditRole } from './editRole';
import ConnectToLinkedin from './connectToLinkedin';
import ReconnectLinkedinSync from './reconnectLinkedinSync';

export const AdminTable = ({
  onRemoveAdmin,
  onResendInvitation,
  admins,
  activeNetwork,
  currentUser,
  canAddCompanyAdmins,
  canRemoveCompanyAdmins,
  isResendingInvitation,
  isDeletingAdmin,
  loadingAdminsError,
  isLoadingAdmins,
  updateRole,
  isLoadingUpdateRole,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const inviteModalOpen = urlParams.has('invite-admin') || urlParams.has('invite-manager');
  const [payload, setActionPayload] = useState(inviteModalOpen ? { action: 'invite' } : null);
  const { push } = useHistory();

  const handleSetPayload = (data, adminAction) => {
    if (['connect', 'reconnect'].includes(adminAction)) {
      push(`/networks/${activeNetwork.slug}/linkedin/${adminAction === 'connect' ? 'sync' : 'reconnect'}`);
      return;
    }

    if (['help-connect', 'help-reconnect'].includes(adminAction))
      trackEvent(`linkedin_sync:help_other_user_to_${adminAction === 'help-reconnect' ? 'reconnect' : 'connect'}`, {
        collectionId: activeNetwork.id,
      });

    setActionPayload({
      data,
      action: adminAction,
    });
  };

  if (loadingAdminsError) return <MessageBlock data-testid="fetch-error">{strings.genericError}</MessageBlock>;

  if (isLoadingAdmins)
    return (
      <Flex mt="40px" justifyContent="center" width="100%">
        <Loader />
      </Flex>
    );

  return (
    <>
      <Box m="0 auto">
        <AdminDataTable
          currentUserId={currentUser.id}
          admins={admins ?? []}
          canRemoveCompanyAdmins={canRemoveCompanyAdmins}
          onRemoveAdmin={canRemoveCompanyAdmins && onRemoveAdmin ? handleSetPayload : null}
          onResendInvitation={(admin) => {
            if (!canAddCompanyAdmins) return;
            onResendInvitation(admin).then(() => {
              toast('Invite Resent', {
                icon: 'success',
                id: 'resend-network-invite',
              });
            });
          }}
          isResendingInvitation={isResendingInvitation}
          onUpdateLinkedinSync={handleSetPayload}
          onUpdateRole={handleSetPayload}
        />
      </Box>
      {canRemoveCompanyAdmins && onRemoveAdmin && (
        <RemoveAdminModal
          user={payload?.data}
          currentUser={currentUser}
          network={activeNetwork}
          isSubmitting={isDeletingAdmin}
          modalOpen={payload?.action === 'remove'}
          onCancel={() => setActionPayload(null)}
          onDeleteAdmin={() => onRemoveAdmin(payload.data).then(() => setActionPayload(null))}
        />
      )}

      {payload?.action === 'edit-role' && (
        <EditRole
          isSubmitting={isLoadingUpdateRole}
          isOpen={!!payload.data}
          closeModal={() => setActionPayload(null)}
          email={payload.data?.email}
          role={payload.data?.role}
          updateRole={async ({ role }) => {
            const { error } = await updateRole({
              userId: payload.data.id,
              role: role.value,
            });
            if (error) return;
            toast('Team member role updated', {
              icon: 'success',
              id: 'role-updated',
            });
            setActionPayload(null);
          }}
        />
      )}

      {payload?.action === 'help-connect' && (
        <ConnectToLinkedin
          onCancel={() => setActionPayload(null)}
          onButtonClicked={() => push(`/networks/${activeNetwork.slug}/linkedin/sync`)}
          link={`/app/networks/${activeNetwork.slug}/linkedin/sync`}
        />
      )}

      {payload?.action === 'help-reconnect' && (
        <ReconnectLinkedinSync
          onCancel={() => setActionPayload(null)}
          onButtonClicked={() => push(`/networks/${activeNetwork.slug}/linkedin/reconnect`)}
          link={`/app/networks/${activeNetwork.slug}/linkedin/reconnect`}
          networkName={activeNetwork.name}
        />
      )}
    </>
  );
};

AdminTable.propTypes = {
  isDeletingAdmin: PropTypes.bool,
  isResendingInvitation: PropTypes.bool,
  activeNetwork: NetworkSchema.isRequired,
  currentUser: UserSchema.isRequired,
  admins: PropTypes.array,
  onRemoveAdmin: PropTypes.func,
  onResendInvitation: PropTypes.func,
  canAddCompanyAdmins: PropTypes.bool,
  canRemoveCompanyAdmins: PropTypes.bool,
  loadingAdminsError: PropTypes.bool.isRequired,
  isLoadingAdmins: PropTypes.bool.isRequired,
  updateRole: PropTypes.func,
  isLoadingUpdateRole: PropTypes.bool,
};

AdminTable.defaultProps = {
  isDeletingAdmin: false,
  isResendingInvitation: false,
  admins: [],
  onRemoveAdmin: () => {},
  onResendInvitation: () => {},
  canAddCompanyAdmins: false,
  canRemoveCompanyAdmins: false,
  updateRole: () => {},
  isLoadingUpdateRole: false,
};
