import React from 'react';
import { Button } from '@getro/rombo';
import copy from 'copy-to-clipboard';
import { ClipboardList } from 'lucide-react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import { ScrollBox } from '../../../../components/atoms/ScrollBox';
import { trackEvent } from '../../../../helpers/analytics';

export const Blurb = ({ sharedList, content }) => {
  const onCopy = () => {
    copy(content);
    toast('Copied!', { icon: 'success', id: 'blurb-copied-success' });
    trackEvent('shared_list:blurb_copied', {
      collectionId: sharedList.collection?.id,
      listSlug: sharedList.slug,
      objectType: 'companies',
    });
  };

  return (
    <Box width="100%" p="16px" height="200px" bg="neutral.20" sx={{ position: 'relative', borderRadius: 'default' }}>
      <Flex justifyContent="space-between">
        <Flex sx={{ gap: '8px' }}>
          <Text fontWeight="medium" color="black">
            Intro blurb
          </Text>
          <Text fontSize="14px" pt="2px" color="text.subtle" display={['none', 'none', 'inline-block']}>
            Copy-paste text for intros
          </Text>
        </Flex>
        <Button
          variant="tertiary"
          icon={ClipboardList}
          size="small"
          sx={{ bg: 'transparent', position: 'relative', top: '-4px' }}
          onClick={onCopy}
        >
          Copy
        </Button>
      </Flex>
      <ScrollBox height="144px">
        <Text mt="6px" fontSize="14px">
          {content}
        </Text>
      </ScrollBox>
      <Box
        width="100%"
        height="24px"
        sx={{
          position: 'absolute',
          bottom: '8px',
          left: '0',
          background: 'linear-gradient(0deg, #F7FAFC 0%, rgba(247, 250, 252, 0.00) 100%)',
        }}
      />
    </Box>
  );
};

Blurb.propTypes = {
  content: PropTypes.string,
  sharedList: PropTypes.object.isRequired,
};

Blurb.defaultProps = {
  content: '',
};
