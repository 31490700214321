import React from 'react';
import { useSelector } from 'react-redux';
import { Router, Switch, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import history from 'createHistory';

import Home from 'pages/Home';
import Introductions from 'pages/Introductions';
import Loading from 'components/molecules/LoadingFullPage';
import PageNotFound from 'pages/PageNotFound';
import SetNewPassword from 'pages/SetNewPassword';
import StayTuned from 'pages/StayTuned';
import Unsubscribe from 'pages/Unsubscribe';
import AllySignup from 'pages/allySignup';
import Vouches from 'pages/Vouches';
import { SettingsPage } from 'pages/Settings';
import { ContactsLinkedInSync } from 'pages/contactsLinkedInSync';
import EmployerOnboardingWrapper from './wrappers/EmployerOnboarding';
import OnboardingWrapper from './wrappers/Onboarding';
import NetworkWrapper from './wrappers/Network';
import SharedLists from '../pages/sharedLists';
import { SharedCompanyList } from '../pages/sharedCompanyList';
import Route from './Route';

import { networksSelector, userSelector } from '../redux/selectors';
import { onboardingEmployersRoutes } from './onboardingEmployersRoutes';
import { sessionRoutes } from './sessionRoutes';

const Routes = () => {
  const networks = useSelector(networksSelector);
  const user = useSelector(userSelector);
  const { ready: networksReady } = networks;
  const { ready: userReady } = user;

  if (!userReady || !networksReady) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <LastLocationProvider>
        <Switch>
          <Route
            exact
            path={['/onboarding/:networkSlug/ally-signup', '/networks/:networkSlug/ally-onboarding']}
            component={AllySignup}
            visibility={['all', 'onboarding']}
          />

          <Route
            path={['/onboarding/:networkSlug/linkedin/:action', '/linkedin/:action']}
            component={ContactsLinkedInSync}
            visibility={['all', 'onboarding']}
          />
          <Route path="/settings/" component={SettingsPage} visibility="user" />
          <Route path="/shared-lists/:slug" component={SharedLists} visibility="all" />
          <Route path="/shared-company-lists/:slug" component={SharedCompanyList} visibility="all" />
          <Route path="/new-password" component={SetNewPassword} visibility={['public', 'onboarding', 'user']} exact />

          {user.onboardingCompleted && user.isOnlyAlly && (
            <>
              <Redirect from="*" to="/settings/my-networks" />
            </>
          )}

          {user.isOnlyAlly && !user.onboardingCompleted && (
            <>
              <Redirect from="*" to={`/onboarding/${user.allyingCollections[0].slug}/ally-signup`} />
            </>
          )}

          <Redirect from="/networks/:networkSlug/members/share" to="/networks/:networkSlug/members/share/email" exact />
          <Redirect
            from="/claim-your-profile/:networkSlug/employer/:employerSlug"
            to={onboardingEmployersRoutes.claimYourProfile.path}
          />
          <Redirect exact from="/networks/:networkSlug/settings" to="/networks/:networkSlug/settings/general" />
          <Redirect
            from="/onboarding/:networkSlug/employer/:employerSlug"
            to={onboardingEmployersRoutes.onboardingEmployer.path}
          />

          {Object.values(sessionRoutes).map(({ component, path, visibility }) => (
            <Route key={path} path={path} component={component} visibility={visibility} exact />
          ))}

          {Object.values(onboardingEmployersRoutes).map(({ path, visibility }) => (
            <Route key={path} path={path} component={EmployerOnboardingWrapper} visibility={visibility} exact />
          ))}

          <Route path="/networks/:networkSlug" component={NetworkWrapper} visibility="user" />

          <Route path="/" component={Home} visibility="user" exact />
          <Route path="/welcome" component={OnboardingWrapper} visibility="onboarding" exact />

          {/* TODO: move these routes */}
          <Route path="/vouches" component={Vouches} visibility="user" exact />
          <Route path="/introductions" component={Introductions} visibility="user" />
          <Route path="/unsubscribe" component={Unsubscribe} visibility="user" />
          {/* TODO end */}

          <Route path="/stay-tuned" component={StayTuned} visibility={['user', 'onboarding']} />

          <Route path="*" component={PageNotFound} visibility="all" />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

export default Routes;
