/* eslint-disable max-lines */
import { createEntityAdapter } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import uniqBy from 'lodash.uniqby';

import { analyticsApi } from 'services/analytics';
import { contactsV2Api } from 'services/contactsV2';
import { getItem } from '../../helpers/store';
import { networkBaseQuery } from '../queries';
import { matchesApi } from '../matches';
import { hiresApi } from '../hires';
import { parseFiltersData } from './filters';

const itemsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const itemsSelector = itemsAdapter.getSelectors();

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: networkBaseQuery,
  tagTypes: ['contacts-shared-list', 'contacts-shared-lists', 'contacts-facets'],
  endpoints: (builder) => ({
    editContact: builder.mutation({
      query: ({ contactId, values }) => ({
        url: `/contacts/${contactId}`,
        method: 'patch',
        data: {
          ...(values.email ? { email: values.email } : undefined),
          ...(values.contactOf ? { userIds: values.contactOf.map((user) => user.id) } : undefined),
          ...(values.lists ? { sharedListIds: values.lists.map((list) => list.value) } : undefined),
          ...(values.tags ? { tagIds: values.tags.map((tag) => tag.value) } : undefined),
        },
      }),
      async onQueryStarted({ contactId, values, queryParams }, { dispatch, queryFulfilled }) {
        const changes = {
          ...(values.contactOf ? { importers: values.contactOf } : undefined),
        };

        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            itemsAdapter.updateOne(draft, {
              id: contactId,
              changes,
            });
          }),
        );

        const patchSingleResult = dispatch(
          contactsV2Api.util.updateQueryData('getContactById', contactId, (draft) => {
            Object.assign(draft, changes);
          }),
        );

        dispatch(
          contactsV2Api.util.updateQueryData('getContactById', contactId, (draft) => {
            Object.assign(draft, changes);
          }),
        );

        dispatch(matchesApi.util.invalidateTags(['matches-detail']));
        dispatch(hiresApi.util.invalidateTags(['hires']));

        try {
          await queryFulfilled;
          dispatch(contactsV2Api.util.invalidateTags([{ type: 'shared-lists-contacts', listId: queryParams.listId }]));
        } catch {
          patchResult.undo();
          patchSingleResult.undo();
        }
      },
    }),
    deleteContact: builder.mutation({
      query: ({ contactId }) => ({
        url: `/contacts/${contactId}`,
        method: 'delete',
      }),
      async onQueryStarted({ contactId, companyId, queryParams, list = {} }, { dispatch, queryFulfilled }) {
        const patchResultContactList = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', { listId: queryParams.listId.toString() }, (draft) => {
            if (list.kind === 'companies') {
              const result = itemsSelector.selectById(draft, companyId);

              if (result) {
                itemsAdapter.updateOne(draft, {
                  id: companyId,
                  changes: {
                    contacts: result.contacts.filter((item) => item.id !== contactId),
                  },
                });
              }
            }
          }),
        );

        try {
          await queryFulfilled;
          if (typeof list.kind === 'undefined') {
            dispatch(
              contactsV2Api.util.invalidateTags([{ type: 'shared-lists-contacts', listId: queryParams.listId }]),
            );
          }
        } catch {
          patchResultContactList.undo();
        }

        dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            itemsAdapter.updateOne(draft, {
              id: contactId,
              changes: {
                deleted: true,
              },
            });
            Object.assign(draft.meta, {
              contactsTotal: draft.meta.contactsTotal - 1,
              queryTotal: draft.meta.queryTotal - 1,
              sharedListContactsTotal: draft.meta.sharedListContactsTotal - 1,
            });
          }),
        );
        dispatch(contactsApi.util.invalidateTags(['contacts-shared-lists']));
        dispatch(
          analyticsApi.util.invalidateTags([
            'analytics-contacts',
            'analytics-shared-list-contacts',
            'analytics-shared-list',
          ]),
        );

        if (!queryParams?.listId) {
          dispatch(contactsApi.util.invalidateTags(['contacts-shared-list-matches', 'shared-lists-contacts']));
        }
      },
    }),
    deleteContacts: builder.mutation({
      query: ({ queryParams, excludedContactIds, contactIds }) => ({
        url: `/contacts/bulk_destroy`,
        method: 'post',
        data: {
          searchParams: {
            filters: parseFiltersData(queryParams.filters),
            ...(contactIds?.length ? { contactIds } : undefined),
            ...(excludedContactIds?.length ? { excludedContactIds } : undefined),
            ...(queryParams.listId ? { sharedListId: parseInt(queryParams.listId, 10) } : undefined),
          },
        },
      }),
      async onQueryStarted({ contactIds, queryParams }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            if (!contactIds.length) {
              itemsAdapter.removeAll(draft);
              Object.assign(draft.meta, {
                contactsTotal: 0,
                queryTotal: 0,
              });
            } else {
              itemsAdapter.removeMany(draft, contactIds);
              Object.assign(draft.meta, {
                contactsTotal: draft.meta.contactsTotal - contactIds.length,
                queryTotal: draft.meta.queryTotal - contactIds.length,
              });
            }
          }),
        );

        if (queryParams?.listId) {
          dispatch(
            contactsV2Api.util.updateQueryData('getContacts', { listId: queryParams.listId.toString() }, (draft) => {
              if (!contactIds.length) {
                itemsAdapter.removeAll(draft);
                Object.assign(draft.meta, {
                  contactsTotal: 0,
                  queryTotal: 0,
                  sharedListContactsTotal: 0,
                });
              } else {
                Object.assign(draft.meta, {
                  contactsTotal: draft.meta.contactsTotal - contactIds.length,
                  sharedListContactsTotal: draft.meta.sharedListContactsTotal - contactIds.length,
                });
                itemsAdapter.removeMany(draft, contactIds);
              }
            }),
          );
        }

        dispatch(contactsApi.util.invalidateTags(['contacts-shared-lists']));
        dispatch(matchesApi.util.invalidateTags(['matches-detail']));
      },
    }),
    getFilterOptions: builder.query({
      query: ({ facet, query, sharedList, orderBy = 'count' }) => ({
        url: '/contacts/facets',
        params: { facet, query, orderBy, ...(sharedList ? { sharedListId: sharedList.id } : undefined) },
        ...(sharedList?.collection ? { networkId: sharedList.collection.id } : undefined),
        ...(sharedList?.passwordProtected
          ? {
              options: {
                headers: {
                  'X-Shared-List-Password': getItem(`sharedListPwd-${sharedList.slug}-${sharedList?.id}`),
                  Accept: 'application/json',
                },
              },
            }
          : undefined),
      }),
      providesTags: ['contacts-facets'],
    }),
    downloadContacts: builder.mutation({
      query: ({ contactIds, excludedContactIds, filters }) => ({
        url: '/contacts/export',
        method: 'post',
        data: {
          searchParams: {
            filters: parseFiltersData(filters),
            ...(contactIds?.length ? { contactIds } : undefined),
            ...(excludedContactIds?.length ? { excludedContactIds } : undefined),
          },
        },
      }),
    }),
    importContactFromLinkedin: builder.mutation({
      query: (values) => ({
        url: '/contacts',
        method: 'post',
        data: {
          linkedinUrl: values.linkedinUrl,
        },
      }),
    }),
    exportContactsToCsv: builder.mutation({
      query: ({ filters, contactIds, excludedContactIds, listId }) => ({
        url: '/contacts/export',
        method: 'post',
        data: {
          searchParams: {
            filters: parseFiltersData(filters),
            ...(contactIds?.length ? { contactIds } : undefined),
            ...(excludedContactIds?.length ? { excludedContactIds } : undefined),
            ...(listId ? { sharedListId: parseInt(listId, 10) } : undefined),
          },
        },
      }),
    }),
    getSharedListMatches: builder.query({
      query: ({ listId } = {}) => ({
        url: `/contacts/shared_lists/${listId}/matching_project`,
      }),
      providesTags: ['contacts-shared-list-matches'],
    }),
    getSharedList: builder.query({
      query: ({ listId } = {}) => ({
        url: `/contacts/shared_lists/${listId}`,
      }),
      providesTags: ['contacts-shared-list'],
    }),
    getSharedLists: builder.query({
      query: ({ keyword, userId, page, kind } = {}) => ({
        url: '/contacts/shared_lists',
        params: {
          ...(keyword ? { keyword } : keyword),
          ...(userId ? { userId } : userId),
          ...(kind ? { kind } : kind),
          page,
        },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        JSON.stringify({
          endpointName,
          keyword: queryArgs?.keyword,
          userId: queryArgs?.userId,
          kind: queryArgs?.kind,
        }),
      providesTags: ['contacts-shared-lists'],
      merge: (currentCache, newItems, { arg }) => {
        if (arg?.page > 1) {
          Object.assign(currentCache, {
            meta: newItems.meta,
            sharedLists: uniqBy([...currentCache.sharedLists, ...newItems.sharedLists], 'id'),
          });

          return currentCache;
        }

        return newItems;
      },
    }),
    createSharedList: builder.mutation({
      query: ({ name, description, domains, matchingProject, smartListOptions }) => ({
        url: 'contacts/shared_lists',
        method: 'post',
        data: {
          name,
          description,
          domains,
          ...(matchingProject ? { matchingProject } : {}),
          ...(smartListOptions ? { smartListOptions } : {}),
        },
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            contactsApi.util.updateQueryData('getSharedLists', undefined, (draft) => {
              draft.sharedLists.push(data.sharedList);
            }),
          );
          dispatch(
            analyticsApi.util.invalidateTags([
              'analytics-contacts',
              'analytics-shared-list-contacts',
              'analytics-shared-list',
            ]),
          );
        } catch {
          // Do nothing
        }
      },
    }),
    updateSharedList: builder.mutation({
      query: ({ listId, ...rest }) => ({
        url: `/contacts/shared_lists/${listId}`,
        method: 'put',
        data: rest,
      }),
      invalidatesTags: ['contacts-shared-lists', 'contacts-shared-list', 'contacts-shared-list-matches'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsApi.util.updateQueryData('getSharedLists', undefined, (draft) => {
            const list = draft.sharedLists.find((item) => item.id.toString() === args.listId);

            list.shareable = args.shareable;

            if (!args.shareable) {
              list.passwordProtected = false;
            }
          }),
        );

        try {
          const result = await queryFulfilled;
          dispatch(
            contactsApi.util.updateQueryData('getSharedList', { listId: args.listId?.toString() }, (draft) => {
              Object.assign(draft.sharedList, result.data?.sharedList);
            }),
          );
          dispatch(contactsV2Api.util.invalidateTags([{ type: 'shared-lists-contacts', listId: args.listId }]));
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteSharedList: builder.mutation({
      query: ({ listId }) => ({
        url: `/contacts/shared_lists/${listId}`,
        method: 'delete',
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        if (arg.queryParams) {
          dispatch(
            contactsApi.util.updateQueryData('getSharedLists', arg.queryParams, (draft) => {
              Object.assign(draft, {
                sharedLists: draft.sharedLists.filter((item) => item.id !== arg.listId),
              });
            }),
          );
        }

        if (arg.force) {
          dispatch(contactsApi.util.invalidateTags('contacts-shared-lists', 'contacts-shared-list'));
        }
      },
    }),
    refreshSharedList: builder.mutation({
      query: ({ sharedListId }) => ({
        url: `contacts/shared_lists/${sharedListId}/refresh`,
        method: 'POST',
      }),
      invalidatesTags: ['contacts-shared-lists', 'contacts-shared-list'],
    }),
    updateSharedListMatch: builder.mutation({
      query: ({ matchListId, data }) => ({ url: `/contacts/match_lists/${matchListId}`, method: 'put', data }),
      invalidatesTags: ['contacts-shared-list-matches'],
    }),
    addContactsToSharedList: builder.mutation({
      query: ({ queryParams = {}, contactIds, excludedContactIds, list, listId }) => {
        const parsedFilters = parseFiltersData(queryParams.filters);
        if (listId) {
          parsedFilters.sharedListIds.push(listId);
        }
        return {
          url: `/contacts/shared_lists/${list.id}/bulk_add`,
          method: 'post',
          data: {
            searchParams: {
              filters: parsedFilters,
              ...(contactIds?.length ? { contactIds } : undefined),
              ...(excludedContactIds?.length ? { excludedContactIds } : undefined),
            },
          },
        };
      },
      invalidatesTags: ['contacts-shared-lists'],
      async onQueryStarted(
        { contactIds, excludedContactIds, list, queryParams, matchId },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            const contacts = itemsSelector.selectAll(draft);
            const updates = [];

            if (!contactIds.length && !excludedContactIds.length) {
              contacts.forEach((contact) => {
                updates.push({ id: contact.id, changes: { sharedList: uniqBy([...contact.sharedList, list], 'id') } });
              });
            } else if (contactIds.length) {
              contactIds.forEach((id) => {
                const contact = contacts.find((item) => item.id === id);

                updates.push({ id: contact.id, changes: { sharedList: uniqBy([...contact.sharedList, list], 'id') } });
              });
            } else if (excludedContactIds.length) {
              contacts.forEach((contact) => {
                if (!excludedContactIds.includes(contact.id)) {
                  updates.push({
                    id: contact.id,
                    changes: { sharedList: uniqBy([...contact.sharedList, list], 'id') },
                  });
                }
              });
            }

            itemsAdapter.updateMany(draft, updates);

            contacts?.forEach((contact) => {
              dispatch(
                contactsV2Api.util.updateQueryData('getContactById', contact.id, (contactDraft) => {
                  Object.assign(contactDraft, { sharedList: uniqBy([...contact.sharedList, list], 'id') });
                }),
              );
            });
          }),
        );

        dispatch(
          contactsApi.util.updateQueryData('getSharedListMatches', { listId: JSON.stringify(list.id) }, (draft) => {
            const sharedList = draft.matchingProject.matches.find((item) => item.id === matchId);
            if (sharedList) {
              sharedList.addedToSharedList = true;
            }
          }),
        );

        dispatch(
          contactsV2Api.util.updateQueryData('getContacts', { listId: list.id.toString() }, (draft) => {
            Object.assign(draft, {
              meta: {
                ...draft.meta,
                queryTotal: draft.meta.queryTotal + contactIds.length,
              },
            });
          }),
        );

        dispatch(matchesApi.util.invalidateTags(['matches-detail']));
        dispatch(hiresApi.util.invalidateTags(['hires']));
        dispatch(analyticsApi.util.invalidateTags(['analytics-shared-list-contacts', 'analytics-shared-list']));

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    addContactToSharedList: builder.mutation({
      query: ({ contactId, list }) => ({
        url: `/contacts/shared_lists/${list.id}/contacts`,
        method: 'post',
        data: {
          contactId,
        },
      }),
      invalidatesTags: ['contacts-shared-lists', 'contacts-shared-list-matches'],
      async onQueryStarted({ list, matchId, queryParams, contactId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', { listId: list.id.toString() }, (draft) => {
            Object.assign(draft, {
              meta: {
                ...draft.meta,
                ...(Object.hasOwn(draft.meta, 'sharedListContactsTotal')
                  ? { sharedListContactsTotal: draft.meta.sharedListContactsTotal + 1 }
                  : undefined),
              },
            });
          }),
        );

        dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            if (draft.entities[contactId]?.sharedList) {
              draft.entities[contactId].sharedList.push(list);
            }
          }),
        );

        dispatch(
          contactsV2Api.util.updateQueryData('getContactById', contactId, (contactDraft) => {
            contactDraft.sharedList.push(list);
          }),
        );

        dispatch(
          contactsApi.util.updateQueryData('getSharedList', { listId: list.id.toString() }, (draft) => {
            Object.assign(draft.sharedList, { contactsCount: draft.sharedList.contactsCount + 1 });
          }),
        );

        let patchResultSharedListMatches;
        if (matchId) {
          patchResultSharedListMatches = dispatch(
            contactsApi.util.updateQueryData('getSharedListMatches', { listId: JSON.stringify(list.id) }, (draft) => {
              const sharedList = draft.matchingProject.matches.find((item) => item.id === matchId);
              if (sharedList) {
                sharedList.addedToSharedList = true;
              }
            }),
          );
        }

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          if (patchResultSharedListMatches) {
            patchResultSharedListMatches.undo();
          }
        }
      },
    }),
    removeContactsFromSharedList: builder.mutation({
      query: ({ queryParams = {}, contactIds, excludedContactIds, list }) => ({
        url: `/contacts/shared_lists/${list.id}/bulk_remove`,
        method: 'delete',
        data: {
          searchParams: {
            filters: parseFiltersData(queryParams.filters),
            ...(contactIds?.length ? { contactIds } : undefined),
            ...(excludedContactIds?.length ? { excludedContactIds } : undefined),
          },
        },
      }),
      invalidatesTags: ['contacts-shared-lists', 'contacts-shared-list-matches'],
      async onQueryStarted({ contactIds, excludedContactIds, list, queryParams }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            const contacts = itemsSelector.selectAll(draft);
            const updates = [];

            if (!contactIds.length && !excludedContactIds.length) {
              contacts.forEach((contact) => {
                updates.push({
                  id: contact.id,
                  changes: { sharedList: contact.sharedList?.filter((item) => item.id !== list.id) },
                });
              });

              if (list?.id === queryParams?.listId) {
                Object.assign(draft.meta, { queryTotal: 0, sharedListContactsTotal: 0 });
              }
            } else if (contactIds.length) {
              contactIds.forEach((id) => {
                const contact = contacts.find((item) => item.id === id);

                updates.push({
                  id: contact.id,
                  changes: { sharedList: contact.sharedList.filter((item) => item.id !== list.id) },
                });
              });

              if (list?.id === queryParams?.listId) {
                Object.assign(draft.meta, {
                  queryTotal: draft.meta.queryTotal - contactIds.length,
                  sharedListContactsTotal: draft.meta.sharedListContactsTotal - contactIds.length,
                });
              }
            } else if (excludedContactIds.length) {
              contacts.forEach((contact) => {
                if (!excludedContactIds.includes(contact.id)) {
                  updates.push({
                    id: contact.id,
                    changes: { sharedList: contact.sharedList.filter((item) => item.id !== list.id) },
                  });
                }
              });

              if (list?.id === queryParams?.listId) {
                Object.assign(draft.meta, {
                  queryTotal: excludedContactIds.length,
                  sharedListContactsTotal: excludedContactIds.length,
                });
              }
            }

            itemsAdapter.updateMany(draft, updates);

            if (list?.id === queryParams?.listId) {
              itemsAdapter.removeMany(
                draft,
                updates.map((item) => item.id),
              );
            }

            contacts?.forEach((contact) => {
              dispatch(
                contactsV2Api.util.updateQueryData('getContactById', contact.id, (contactDraft) => {
                  Object.assign(contactDraft, {
                    sharedList: contact.sharedList.filter((item) => item.id !== list.id),
                  });
                }),
              );
            });
          }),
        );

        dispatch(matchesApi.util.invalidateTags(['matches-detail']));
        dispatch(hiresApi.util.invalidateTags(['hires']));
        dispatch(analyticsApi.util.invalidateTags(['analytics-shared-list-contacts', 'analytics-shared-list']));

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    removeContactFromSharedList: builder.mutation({
      query: ({ contactId, list }) => ({
        url: `/contacts/shared_lists/${list.id}/contacts/${contactId}`,
        method: 'delete',
      }),
      invalidatesTags: ['contacts-shared-lists', 'contacts-shared-list-matches'],
      async onQueryStarted({ queryParams, matchId, contactId, companyId, list }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            const contact = itemsSelector.selectById(draft, contactId);

            itemsAdapter.updateOne(draft, {
              id: contactId,
              changes: {
                sharedList: contact?.sharedList?.filter((item) => item.id !== list.id),
              },
            });

            if (list?.id === queryParams?.listId) {
              itemsAdapter.removeOne(draft, contactId);

              Object.assign(draft.meta, {
                queryTotal: draft.meta.queryTotal - 1,
              });
            }
          }),
        );

        dispatch(
          contactsV2Api.util.updateQueryData('getContactById', contactId, (contactDraft) => {
            Object.assign(contactDraft, {
              sharedList: contactDraft.sharedList.filter((item) => item.id !== list.id),
            });
          }),
        );

        const patchResultContactList = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', { listId: list.id.toString() }, (draft) => {
            if (list.kind === 'people') {
              Object.assign(draft.meta, {
                sharedListContactsTotal: draft.meta.sharedListContactsTotal - 1,
                queryTotal: draft.meta.queryTotal - 1,
              });
              itemsAdapter.removeOne(draft, contactId);
            } else {
              const result = itemsSelector.selectById(draft, companyId);

              if (result) {
                itemsAdapter.updateOne(draft, {
                  id: companyId,
                  changes: {
                    contacts: result.contacts.filter((item) => item.id !== contactId),
                  },
                });
              }
            }
          }),
        );

        dispatch(
          contactsApi.util.updateQueryData('getSharedList', { listId: list.id.toString() }, (draft) => {
            Object.assign(draft.sharedList, { contactsCount: draft.sharedList.contactsCount - 1 });
          }),
        );

        let patchResultSharedListMatches;
        if (matchId) {
          patchResultSharedListMatches = dispatch(
            contactsApi.util.updateQueryData('getSharedListMatches', { listId: JSON.stringify(list.id) }, (draft) => {
              const sharedList = draft.matchingProject.matches.find((item) => item.id === matchId);
              if (sharedList) {
                sharedList.addedToSharedList = false;
              }
            }),
          );
        }

        dispatch(matchesApi.util.invalidateTags(['matches-detail']));
        dispatch(hiresApi.util.invalidateTags(['hires']));
        dispatch(analyticsApi.util.invalidateTags(['analytics-shared-list-contacts', 'analytics-shared-list']));

        try {
          await queryFulfilled;

          if (typeof list.kind === 'undefined') {
            dispatch(contactsV2Api.util.invalidateTags([{ type: 'shared-lists-contacts', listId: list.id }]));
          }
        } catch {
          patchResult.undo();
          patchResultContactList.undo();

          if (patchResultSharedListMatches) {
            patchResultSharedListMatches.undo();
          }
        }
      },
    }),
    addTagToContacts: builder.mutation({
      query: ({ tag, contactIds, excludedContactIds, queryParams = {} }) => ({
        url: `/contacts/bulk_tag`,
        method: 'post',
        data: {
          tagId: tag.id,
          searchParams: {
            filters: parseFiltersData(queryParams.filters),
            ...(contactIds?.length ? { contactIds } : undefined),
            ...(excludedContactIds?.length ? { excludedContactIds } : undefined),
            ...(queryParams.listId ? { sharedListId: parseInt(queryParams.listId, 10) } : undefined),
          },
        },
      }),
      invalidatesTags: ['contacts-facets'],
      async onQueryStarted({ tag, contactIds, excludedContactIds, queryParams }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            const contacts = itemsSelector.selectAll(draft);
            const updates = [];

            if (!contactIds.length && !excludedContactIds.length) {
              contacts.forEach((contact) => {
                updates.push({ id: contact.id, changes: { tags: uniqBy([...contact.tags, tag], 'id') } });
              });
            } else if (contactIds.length) {
              contactIds.forEach((id) => {
                const contact = contacts.find((item) => item.id === id);

                updates.push({ id: contact.id, changes: { tags: uniqBy([...contact.tags, tag], 'id') } });
              });
            } else if (excludedContactIds.length) {
              contacts.forEach((contact) => {
                if (!excludedContactIds.includes(contact.id)) {
                  updates.push({ id: contact.id, changes: { tags: uniqBy([...contact.tags, tag], 'id') } });
                }
              });
            }

            itemsAdapter.updateMany(draft, updates);
          }),
        );

        contactIds?.forEach((contactId) => {
          dispatch(
            contactsV2Api.util.updateQueryData('getContactById', contactId, (draft) => {
              Object.assign(draft, {
                tags: draft.tags.filter((item) => item.id !== tag.id),
              });
            }),
          );
        });

        dispatch(matchesApi.util.invalidateTags(['matches-detail']));
        dispatch(hiresApi.util.invalidateTags(['hires']));

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    addTagToSingleContact: builder.mutation({
      query: ({ contactId, tag }) => ({
        url: `/contacts/${contactId}/tags`,
        method: 'post',
        data: {
          tagId: tag.id,
        },
      }),
      invalidatesTags: ['contacts-facets'],
      async onQueryStarted({ contactId, tag, queryParams }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            const contact = itemsSelector.selectById(draft, contactId);
            if (contact) {
              const { tags = [] } = contact;
              const currentTag = tags.find((item) => item.id === tag.id);

              if (!currentTag) {
                itemsAdapter.updateOne(draft, {
                  id: contactId,
                  changes: {
                    tags: [...tags, tag],
                  },
                });
              }
            }
          }),
        );

        dispatch(
          contactsV2Api.util.updateQueryData('getContactById', contactId, (draft) => {
            draft.tags.push(tag);
          }),
        );
        dispatch(matchesApi.util.invalidateTags(['matches-detail', 'hires']));

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    removeTagFromContacts: builder.mutation({
      query: ({ tag, contactIds, excludedContactIds, queryParams = {} }) => ({
        url: `/contacts/bulk_tag`,
        method: 'delete',
        data: {
          tagId: tag.id,
          searchParams: {
            filters: parseFiltersData(queryParams.filters),
            ...(contactIds?.length ? { contactIds } : undefined),
            ...(excludedContactIds?.length ? { excludedContactIds } : undefined),
            ...(queryParams.listId ? { sharedListId: parseInt(queryParams.listId, 10) } : undefined),
          },
        },
      }),
      invalidatesTags: ['contacts-facets'],
      async onQueryStarted({ tag, contactIds, excludedContactIds, queryParams }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            const contacts = itemsSelector.selectAll(draft);
            const updates = [];

            if (!contactIds.length && !excludedContactIds.length) {
              contacts.forEach((contact) => {
                updates.push({ id: contact.id, changes: { tags: contact.tags.filter((item) => item.id !== tag.id) } });
              });
            } else if (contactIds.length) {
              contactIds.forEach((id) => {
                const contact = contacts.find((item) => item.id === id);

                updates.push({ id: contact.id, changes: { tags: contact.tags.filter((item) => item.id !== tag.id) } });
              });
            } else if (excludedContactIds.length) {
              contacts.forEach((contact) => {
                if (!excludedContactIds.includes(contact.id)) {
                  updates.push({
                    id: contact.id,
                    changes: { tags: contact.tags.filter((item) => item.id !== tag.id) },
                  });
                }
              });
            }

            itemsAdapter.updateMany(draft, updates);
          }),
        );

        contactIds?.forEach((contactId) => {
          dispatch(
            contactsV2Api.util.updateQueryData('getContactById', contactId, (draft) => {
              Object.assign(draft, {
                tags: draft.tags.filter((item) => item.id !== tag.id),
              });
            }),
          );
        });

        dispatch(matchesApi.util.invalidateTags(['matches-detail']));
        dispatch(hiresApi.util.invalidateTags(['hires']));

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    removeTagFromContact: builder.mutation({
      query: ({ contactId, tag }) => ({
        url: `/contacts/${contactId}/tags`,
        method: 'delete',
        data: {
          tagId: tag.id,
        },
      }),
      invalidatesTags: ['contacts-facets'],
      async onQueryStarted({ contactId, tag, queryParams }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            const contact = itemsSelector.selectById(draft, contactId);

            if (contact?.tags?.length) {
              itemsAdapter.updateOne(draft, {
                id: contactId,
                changes: {
                  tags: contact.tags.filter((item) => item.id !== tag.id),
                },
              });
            }
          }),
        );

        dispatch(
          contactsV2Api.util.updateQueryData('getContactById', contactId, (draft) => {
            Object.assign(draft, {
              tags: draft.tags.filter((item) => item.id !== tag.id),
            });
          }),
        );

        dispatch(matchesApi.util.invalidateTags(['matches-detail']));
        dispatch(hiresApi.util.invalidateTags(['hires']));

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    createNote: builder.mutation({
      query: ({ contactId, values }) => ({
        url: `/contacts/${contactId}/notes`,
        method: 'post',
        data: { ...values, external: values.external.value },
      }),
      async onQueryStarted({ contactId, queryParams }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
              const contact = itemsSelector.selectById(draft, contactId);

              if (contact) {
                itemsAdapter.updateOne(draft, {
                  id: contactId,
                  changes: {
                    notes: [...contact.notes, data.note],
                  },
                });
              }

              dispatch(matchesApi.util.invalidateTags(['matches-detail']));
              dispatch(hiresApi.util.invalidateTags(['hires']));
            }),
          );

          dispatch(
            contactsV2Api.util.updateQueryData('getContactById', contactId, (draft) => {
              draft.notes.push(data.note);
            }),
          );
        } catch {
          // Do nothing
        }
      },
    }),
    updateNote: builder.mutation({
      query: ({ contactId, note, values }) => ({
        url: `/contacts/${contactId}/notes/${note.id}`,
        method: 'patch',
        data: { ...values, external: values.external.value },
      }),
      async onQueryStarted({ contactId, note, values, queryParams }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            const contact = itemsSelector.selectById(draft, contactId);
            if (contact) {
              itemsAdapter.updateOne(draft, {
                id: contactId,
                changes: {
                  notes: contact.notes.map((item) =>
                    item.id === note.id ? { ...values, external: values.external.value } : item,
                  ), // eslint-disable-line function-paren-newline
                },
              });
            }
          }),
        );

        dispatch(
          contactsV2Api.util.updateQueryData('getContactById', contactId, (draft) => {
            const updatedNote = draft.notes.find((item) => item.id === note.id);
            if (updatedNote) {
              Object.assign(updatedNote, {
                external: values.external.value,
                body: values.body,
                title: values.title,
              });
            }
          }),
        );

        dispatch(matchesApi.util.invalidateTags(['matches-detail']));
        dispatch(hiresApi.util.invalidateTags(['hires']));
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteNote: builder.mutation({
      query: ({ contactId, note }) => ({
        url: `/contacts/${contactId}/notes/${note.id}`,
        method: 'delete',
      }),
      async onQueryStarted({ contactId, note, queryParams }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsV2Api.util.updateQueryData('getContacts', queryParams, (draft) => {
            const contact = itemsSelector.selectById(draft, contactId);

            if (contact?.notes?.length) {
              itemsAdapter.updateOne(draft, {
                id: contactId,
                changes: {
                  notes: contact.notes.filter((item) => item.id !== note.id),
                },
              });
            }
          }),
        );

        dispatch(
          contactsV2Api.util.updateQueryData('getContactById', contactId, (draft) => {
            Object.assign(draft, {
              notes: draft.notes.filter((item) => item.id !== note.id),
            });
          }),
        );

        dispatch(matchesApi.util.invalidateTags(['matches-detail']));
        dispatch(hiresApi.util.invalidateTags(['hires']));

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getBulkContactOperationById: builder.query({
      query: ({ id }) => ({
        url: `/contacts/bulk_contacts_operations/${id}`,
      }),
    }),
    exportContacts: builder.mutation({
      query: (data) => ({
        url: `/contacts/export`,
        method: 'post',
        data,
      }),
    }),
  }),
});

export const {
  useExportContactsToCsvMutation,
  useEditContactMutation,
  useGetFilterOptionsQuery,
  useLazyGetFilterOptionsQuery,
  useDownloadContactsMutation,
  useImportContactFromLinkedinMutation,
  useGetSharedListsQuery,
  useCreateSharedListMutation,
  useLazyGetSharedListsQuery,
  useUpdateSharedListMutation,
  useDeleteSharedListMutation,
  useRefreshSharedListMutation,
  useAddContactsToSharedListMutation,
  useRemoveContactsFromSharedListMutation,
  useAddTagToContactsMutation,
  useAddTagToSingleContactMutation,
  useRemoveTagFromContactsMutation,
  useRemoveTagFromContactMutation,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useGetSharedListQuery,
  useLazyGetSharedListQuery,
  useGetSharedListMatchesQuery,
  useDeleteContactMutation,
  useDeleteContactsMutation,
  useUpdateSharedListMatchMutation,
  useAddContactToSharedListMutation,
  useGetBulkContactOperationByIdQuery,
  useRemoveContactFromSharedListMutation,
  useExportContactsMutation,
} = contactsApi;
