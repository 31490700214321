import { useState } from 'react';
import { useSelector } from 'react-redux';

import { networksSelector } from '../../../redux/selectors';
import { getCompanyColumns } from '../components/table/getCompanyColumns';
import { useTableRows } from './useCompanyTableRows';
import { useFetchContacts } from './useFetchContacts';

export const useCompanyTable = ({ sharedList, user }) => {
  const networks = useSelector(networksSelector);
  const networkOptions =
    networks?.networks && !user.isOnlyAlly
      ? Object.entries(networks.networks).map(([id, network]) => ({
          label: network.name,
          value: id,
        }))
      : [];
  networkOptions.unshift({
    label: 'My contacts',
    value: 'my-contacts',
  });
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);

  const companiesColumns = getCompanyColumns(new Set([]), selectedNetwork.value !== 'my-contacts');
  const { data, isLoading, isFetching, queryParams, setQueryParams } = useFetchContacts({
    contactsSource: selectedNetwork.value,
    user,
    sharedList,
  });
  const tableRows = useTableRows({
    companies: data?.ids?.map((id) => data?.entities[id]) || [],
    sharedList,
    userId: user?.id,
    showContactOf: selectedNetwork.value !== 'my-contacts',
  });
  const totalItems = data?.meta?.queryTotal;
  const currentPage = Math.ceil(data?.ids?.length / 20);
  const hasMore = currentPage + 1 <= Math.ceil(data?.meta?.queryTotal / 20);
  const initialSortBy = {
    id: 'name',
    desc: true,
  };

  const onChangeContactsSelector = (value) => {
    setSelectedNetwork(value);
  };

  const onLoadMore = () => {
    setQueryParams({
      ...queryParams,
      page: currentPage + 1,
    });
  };

  return {
    companiesColumns,
    isLoading,
    isFetching,
    queryParams,
    tableRows,
    totalItems,
    hasMore,
    initialSortBy,
    networkOptions,
    selectedNetwork,
    onLoadMore,
    onChangeContactsSelector,
  };
};
