import React from 'react';
import { Loader } from '@getro/rombo';
import { Box, Flex } from 'rebass/styled-components';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { useSharedCompanyList } from './hooks/useSharedCompanyList';
import { Header } from './components/header';
import { Filters } from './components/filters';
import { Blurb } from './components/blurb';
import { Leaderboard } from './components/leaderboard';
import { Table } from './components/table';

import BackgroundCompaniesList from '../../assets/background-companies-list.jpg';
import { SignUp } from './components/signUp';
import { ImportBanner } from './components/importBanner';
import { InstallExtension } from './components/installExtension';
import { ShareYourContacts } from './components/shareYourContacts';

export const SharedCompanyList = () => {
  const { sharedList, sharedListError, user, isFetchingPreview, isSyncActive, isUserOnboarded, setIsUserOnboarded } =
    useSharedCompanyList();
  const { path, url } = useRouteMatch();

  if (sharedListError?.status === 404) {
    return <Redirect to="/404" />;
  }

  if (isFetchingPreview) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Loader />
      </Flex>
    );
  }

  return (
    <Box maxWidth="1440px" mx="auto" mt={['16px', '16px', '40px']} px={['0', '0', '40px']}>
      <Header sharedList={sharedList} />
      {isUserOnboarded && (
        <>
          <Flex mt="24px" px={['16px', '16px', '0']} flexDirection={['column', 'column', 'row']} sx={{ gap: '16px' }}>
            <Box flex="3" pt={[0, 0, '37px']}>
              <Filters filters={sharedList?.smartSharedList?.filters} />
            </Box>
            {sharedList?.blurb && (
              <Box flex="3" pt={[0, 0, '37px']}>
                <Blurb sharedList={sharedList} content={sharedList?.blurb} />
              </Box>
            )}
            {sharedList?.leaderboardEnabled && (
              <Box flex="2">
                <Leaderboard sharedList={sharedList} />
              </Box>
            )}
            <Box display={['block', 'block', 'none']}>
              <ShareYourContacts sharedList={sharedList} user={user} />
            </Box>
          </Flex>
          {isSyncActive && <ImportBanner />}
          <Box mt="56px">
            <Table sharedList={sharedList} user={user} />
          </Box>
        </>
      )}
      {!isUserOnboarded && (
        <Box mt="40px">
          <Flex
            height="100vh"
            maxHeight="800px"
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundImage: `url(${BackgroundCompaniesList})` }}
          >
            <Switch>
              <Route exact path={path}>
                <SignUp routePath={url} sharedList={sharedList} />
              </Route>
              <Route exact path={`${path}/install-extension`}>
                <InstallExtension sharedList={sharedList} setIsUserOnboarded={setIsUserOnboarded} />
              </Route>
            </Switch>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
