import { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { trackEvent } from '../../../helpers/analytics';
import usePageHook from '../../../hooks/usePageHook';
import { userSelector } from '../../../redux/selectors';
import { useGetSharedListBySlugQuery } from '../../../services/sharedLists';

export const useSharedCompanyList = () => {
  const { slug } = useParams();
  const user = useSelector(userSelector);
  const [isUserOnboarded, setIsUserOnboarded] = useState(!!user.email && user.linkedinSync != null);
  const { data, isLoading: isFetchingPreview, error } = useGetSharedListBySlugQuery({ slug });
  const history = useHistory();
  const { pathname } = useLocation();
  const isSyncActive = user?.linkedinSync?.syncStatus === 'active' && !!user?.linkedinSync?.contactsImportInProgress;

  usePageHook(data?.sharedList?.name, { networkName: data?.sharedList?.collection?.name });

  useEffect(() => {
    if (!!user.email && user.linkedinSync == null && !pathname.includes('install-extension')) {
      history.push(`${pathname}${pathname.endsWith('/') ? '' : '/'}install-extension`);
    } else if (!!user.email && !!user.linkedinSync && !isUserOnboarded) {
      setIsUserOnboarded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (data?.sharedList) {
      trackEvent('shared_list:view_shared_list', {
        collectionId: data?.sharedList?.collection?.id,
        listSlug: data?.sharedList?.slug,
        objectType: 'companies',
      });
    }
  }, [data?.sharedList]);

  return {
    sharedList: data?.sharedList,
    sharedListError: error,
    user,
    isFetchingPreview,
    isUserSignedIn: !!user.email,
    isSyncActive,
    isUserOnboarded,
    setIsUserOnboarded,
  };
};
