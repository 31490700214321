import { Toggle, ProfilePicture } from '@getro/rombo';
import React, { useMemo } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';

export const useMyNetworksTable = ({ networks = [], onShareAction }) => {
  const columns = [
    {
      Header: 'Member',
      accessor: 'name',
      width: '30%',
      disableSortBy: true,
      show: true,
    },
    {
      Header: 'Role',
      accessor: 'role',
      width: '20%',
      disableSortBy: true,
      show: true,
    },
    {
      Header: 'Shared contacts',
      accessor: 'sharedContacts',
      width: '10%',
      disableSortBy: true,
      show: true,
      sx: {
        pl: '8px',
      },
    },
  ];

  const rows = useMemo(
    () =>
      networks.map((network) => ({
        id: network.id,
        name: (
          <Flex
            sx={{
              gap: '8px',
              text: {
                fontSize: '28px',
              },
            }}
            alignItems="center"
            justifyContent="flex-start"
            pl="8px"
          >
            <Box width="32px" height="32px" minWidth="32px">
              <ProfilePicture
                variant="square"
                width="32px"
                height="32px"
                imageUrl={network.logoUrl}
                name={network.name}
              />
            </Box>
            <Flex sx={{ gap: '8px' }}>
              <Text
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                fontSize="14px"
                fontHeight="1.4"
                fontWeight="500"
              >
                {network.name}
              </Text>
            </Flex>
          </Flex>
        ),
        role: (
          <Flex
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              justifyContent: 'flex-start',
              width: '100%',
            }}
            fontSize="14px"
            color="text.main"
          >
            {network.role === 'ally' ? 'Ally' : 'Network admin'}
          </Flex>
        ),
        sharedContacts: (
          <Flex>
            <Toggle
              checked={network.shareContacts}
              label="Sync contacts"
              onChange={() => {
                if (network.shareContacts) {
                  onShareAction(network, 'stop');
                } else {
                  onShareAction(network, 'share');
                }
              }}
            />
          </Flex>
        ),
      })),
    [networks, onShareAction],
  );

  return {
    columns: columns.filter((column) => column.show),
    rows,
  };
};
